import { GET_GEO_LOCATION } from 'constants/index';

export const getLocation = coOrds => ({
  type: GET_GEO_LOCATION,
  payload: coOrds
});

export const getGeoLocation = () => dispatch => {
  try {
    if (window.navigator.geolocation) {
      window.navigator.geolocation.getCurrentPosition(
        position => { 
          const latitude = position.coords.latitude;
          const longitude = position.coords.longitude;
          dispatch(
            getLocation([
              {
                latitude,
                longitude
              }
            ])
          );
        },
        error => {
          if (error.code === error.TIMEOUT) {
            console.log("Geolocation request timed out. Retrying...");
            retryGeoLocation(dispatch);
          } else if (error.code === error.PERMISSION_DENIED) {
            console.log("User denied location access.");
          } else {
            console.log(`Geolocation error: ${error.message}`);
          }
        },
        { 
          maximumAge: 0, // Ensure fresh location, not cached
          timeout: 15000, // Increased timeout (15 seconds)
          enableHighAccuracy: true // Get more accurate location
        }
      );
    } else {
      console.log('Geolocation is not supported by this browser.');
    }
  } catch (error) {
    console.log(error.message);
  }
};

// Function to retry geolocation after a delay
const retryGeoLocation = (dispatch) => {
  setTimeout(() => {
    window.navigator.geolocation.getCurrentPosition(
      position => {
        const latitude = position.coords.latitude;
        const longitude = position.coords.longitude;
        dispatch(getLocation([{ latitude, longitude }]));
      },
      error => console.log(`Retry failed: ${error.message}`),
      { timeout: 15000, enableHighAccuracy: true }
    );
  }, 3000); // Retry after 3 seconds
};

