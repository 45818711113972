export const MANURE_STORAGE_TYPE = 'MANURE_STORAGE_TYPE';
export const MANURE_STORAGE_TYPE_ERROR = 'MANURE_STORAGE_TYPE_ERROR';
export const MANURE_STORAGE_LOADING = 'MANURE_STORAGE_LOADING';

export const MANURE_STORAGE_LIST = 'MANURE_STORAGE_LIST';
export const MANURE_STORAGE_LIST_ERROR = 'MANURE_STORAGE_LIST_ERROR';
export const MANURE_STORAGE_SUBMIT_ERROR = 'MANURE_STORAGE_SUBMIT_ERROR';
export const MANURE_STORAGE_TYPE_SUBMIT_ERROR = 'MANURE_STORAGE_TYPE_SUBMIT_ERROR';
export const MANURE_STORAGE_SUBMIT = 'MANURE_STORAGE_SUBMIT';
export const MANURE_STORAGE_CALCULATE = 'MANURE_STORAGE_CALCULATE';
export const MANURE_STORAGE_CALCULATE_ERROR = 'MANURE_STORAGE_CALCULATE_ERROR';
export const MANURE_STORAGE_LIST_LOADING = 'MANURE_STORAGE_LIST_LOADING';
export const MANURE_STORAGE_CLEAR = 'MANURE_STORAGE_CLEAR';

export const MANURE_NAMES_LOADING = "MANURE_NAMES_LOADING";
export const MANURE_NAMES_SUCCESS = "MANURE_NAMES_SUCCESS";
export const MANURE_NAMES_FAILURE = "MANURE_NAMES_FAILURE";

export const FERTILIZATION_PLAN_CHECK_LOADING = "FERTILIZATION_PLAN_CHECK_LOADING";
export const FERTILIZATION_PLAN_CHECK_SUCCESS = "FERTILIZATION_PLAN_CHECK_SUCCESS";
export const FERTILIZATION_PLAN_CHECK_FAILURE = "FERTILIZATION_PLAN_CHECK_FAILURE";
export const FERTILIZATION_PLAN_LIST_LOADING = "FERTILIZATION_PLAN_LIST_LOADING";
export const FERTILIZATION_PLAN_LIST_SUCCESS = "FERTILIZATION_PLAN_LIST_SUCCESS";
export const FERTILIZATION_PLAN_LIST_FAILURE = "FERTILIZATION_PLAN_LIST_FAILURE";
export const FERTILIZATION_PLAN_CREATE_LOADING = "FERTILIZATION_PLAN_CREATE_LOADING";
export const FERTILIZATION_PLAN_CREATE_SUCCESS = "FERTILIZATION_PLAN_CREATE_SUCCESS";
export const FERTILIZATION_PLAN_CREATE_FAILURE = "FERTILIZATION_PLAN_CREATE_FAILURE";
export const FERTILIZATION_PLAN_UPDATE_LOADING = "FERTILIZATION_PLAN_UPDATE_LOADING";
export const FERTILIZATION_PLAN_UPDATE_SUCCESS = "FERTILIZATION_PLAN_UPDATE_SUCCESS";
export const FERTILIZATION_PLAN_UPDATE_FAILURE = "FERTILIZATION_PLAN_UPDATE_FAILURE";
export const FERTILIZATION_PLAN_SAVE_LOADING = "FERTILIZATION_PLAN_SAVE_LOADING";
export const FERTILIZATION_PLAN_SAVE_SUCCESS = "FERTILIZATION_PLAN_SAVE_SUCCESS";
export const FERTILIZATION_PLAN_SAVE_FAILURE = "FERTILIZATION_PLAN_SAVE_FAILURE";
export const FERTILIZATION_PLAN_EDIT_LOADING = "FERTILIZATION_PLAN_EDIT_LOADING";
export const FERTILIZATION_PLAN_EDIT_SUCCESS = "FERTILIZATION_PLAN_EDIT_SUCCESS";
export const FERTILIZATION_PLAN_EDIT_FAILURE = "FERTILIZATION_PLAN_EDIT_FAILURE";
export const FERTILIZATION_PLAN_REPORT_LOADING = "FERTILIZATION_PLAN_REPORT_LOADING";
export const FERTILIZATION_PLAN_REPORT_SUCCESS = "FERTILIZATION_PLAN_REPORT_SUCCESS";
export const FERTILIZATION_PLAN_REPORT_FAILURE = "FERTILIZATION_PLAN_REPORT_FAILURE";
export const FERTILIZATION_PLAN_GET_CREATED_LOADING = "FERTILIZATION_PLAN_GET_CREATED_LOADING";
export const FERTILIZATION_PLAN_GET_CREATED_SUCCESS = "FERTILIZATION_PLAN_GET_CREATED_SUCCESS";
export const FERTILIZATION_PLAN_GET_CREATED_FAILURE = "FERTILIZATION_PLAN_GET_CREATED_FAILURE";

export const FERTILIZATION_RECALCULATE_TRANSACTION_LOADING = "FERTILIZATION_RECALCULATE_TRANSACTION_LOADING";
export const FERTILIZATION_RECALCULATE_TRANSACTION_SUCCESS = "FERTILIZATION_RECALCULATE_TRANSACTION_SUCCESS";
export const FERTILIZATION_RECALCULATE_TRANSACTION_FAILURE = "FERTILIZATION_RECALCULATE_TRANSACTION_FAILURE";

export const CREATE_TASK_FERTILIZATION_PLAN_LOADING = "CREATE_TASK_FERTILIZATION_PLAN_LOADING";
export const CREATE_TASK_FERTILIZATION_PLAN_SUCCESS = "CREATE_TASK_FERTILIZATION_PLAN_SUCCESS";
export const CREATE_TASK_FERTILIZATION_PLAN_FAILURE = "CREATE_TASK_FERTILIZATION_PLAN_FAILURE";

export const MINERAL_FERTILIZER_LIST_LOADING = "MINERAL_FERTILIZER_LIST_LOADING";
export const MINERAL_FERTILIZER_LIST_SUCCESS = "MINERAL_FERTILIZER_LIST_SUCCESS";
export const MINERAL_FERTILIZER_LIST_FAILURE = "MINERAL_FERTILIZER_LIST_FAILURE";
export const GET_MINERAL_FERTILIZER_LIST_LOADING = "GET_MINERAL_FERTILIZER_LIST_LOADING";
export const GET_MINERAL_FERTILIZER_LIST_SUCCESS = "GET_MINERAL_FERTILIZER_LIST_SUCCESS";
export const GET_MINERAL_FERTILIZER_LIST_FAILURE = "GET_MINERAL_FERTILIZER_LIST_FAILURE";
export const MINERAL_FERTILIZER_UPDATE_LOADING = "MINERAL_FERTILIZER_UPDATE_LOADING";
export const MINERAL_FERTILIZER_UPDATE_SUCCESS = "MINERAL_FERTILIZER_UPDATE_SUCCESS";
export const MINERAL_FERTILIZER_UPDATE_FAILURE = "MINERAL_FERTILIZER_UPDATE_FAILURE";
export const MINERAL_FERTILIZER_DELETE_LOADING = "MINERAL_FERTILIZER_DELETE_LOADING";
export const MINERAL_FERTILIZER_DELETE_SUCCESS = "MINERAL_FERTILIZER_DELETE_SUCCESS";
export const MINERAL_FERTILIZER_DELETE_FAILURE = "MINERAL_FERTILIZER_DELETE_FAILURE";
export const MINERAL_FERTILIZER_ADD_LOADING = "MINERAL_FERTILIZER_ADD_LOADING";
export const MINERAL_FERTILIZER_ADD_SUCCESS = "MINERAL_FERTILIZER_ADD_SUCCESS";
export const MINERAL_FERTILIZER_ADD_FAILURE = "MINERAL_FERTILIZER_ADD_FAILURE";
export const MINERAL_FERTILIZER_UPDATE_MENU_LOADING = "MINERAL_FERTILIZER_UPDATE_MENU_LOADING";
export const MINERAL_FERTILIZER_UPDATE_MENU_SUCCESS = "MINERAL_FERTILIZER_UPDATE_MENU_SUCCESS";
export const MINERAL_FERTILIZER_UPDATE_MENU_FAILURE = "MINERAL_FERTILIZER_UPDATE_MENU_FAILURE";
export const MANURE_STORAGE_UPDATE_MENU_LOADING = "MANURE_STORAGE_UPDATE_MENU_LOADING";
export const MANURE_STORAGE_UPDATE_MENU_SUCCESS = "MANURE_STORAGE_UPDATE_MENU_SUCCESS";
export const MANURE_STORAGE_UPDATE_MENU_FAILURE = "MANURE_STORAGE_UPDATE_MENU_FAILURE";
export const UPDATE_VERSION_NAME_LOADING = "UPDATE_VERSION_NAME_LOADING";
export const UPDATE_VERSION_NAME_SUCCESS = "UPDATE_VERSION_NAME_SUCCESS";
export const UPDATE_VERSION_NAME_FAILURE = "UPDATE_VERSION_NAME_FAILURE";

export const UPLOAD_SOIL_SAMPLE_PROGRESS = 'UPLOAD_SOIL_SAMPLE_PROGRESS';
export const UPLOAD_SOIL_SAMPLE_ERROR = 'UPLOAD_SOIL_SAMPLE_ERROR';
export const UPLOAD_SOIL_SAMPLE_LOADING = 'UPLOAD_SOIL_SAMPLE_LOADING';
export const UPLOAD_SOIL_SAMPLE_SUCESS = 'UPLOAD_SOIL_SAMPLE_SUCESS';
export const SOIL_SAMPLE_LIST_ERROR = 'SOIL_SAMPLE_LIST_ERROR';
export const SOIL_SAMPLE_LIST = 'SOIL_SAMPLE_LIST';
export const SOIL_SAMPLE_LOADING = 'SOIL_SAMPLE_LOADING';
export const SOIL_SAMPLE_CLEAR = 'SOIL_SAMPLE_CLEAR';
export const CLEAR_SOIL_UPLOAD = 'CLEAR_SOIL_UPLOAD';
export const SOIL_SAMPLE_DETAILS = 'SOIL_SAMPLE_DETAILS';
export const SOIL_SAMPLE_DETAILS_ERROR = 'SOIL_SAMPLE_DETAILS_ERROR';
export const SOIL_SAMPLE_UPDATE = 'SOIL_SAMPLE_UPDATE';
export const SOIL_SAMPLE_UPDATE_ERROR = 'SOIL_SAMPLE_UPDATE_ERROR';
export const FORCE_UPDATE = 'FORCE_UPDATE';

export const FERTILIZATION_COMPLETED_LOADING = 'FERTILIZATION_COMPLETED_LOADING';
export const FERTILIZATION_COMPLETED_SUCCESS = 'FERTILIZATION_COMPLETED_SUCCESS';
export const FERTILIZATION_COMPLETED_FAILURE = 'FERTILIZATION_COMPLETED_FAILURE';

export const DELETE_VERSION_PLAN_LOADING = "DELETE_VERSION_PLAN_LOADING";
export const DELETE_VERSION_PLAN_SUCCESS = "DELETE_VERSION_PLAN_SUCCESS";
export const DELETE_VERSION_PLAN_FAILURE = "DELETE_VERSION_PLAN_FAILURE";

export const FETCH_FERTILIZATION_PLAN_LIST_LOADING = "FETCH_FERTILIZATION_PLAN_LIST_LOADING";
export const FETCH_FERTILIZATION_PLAN_LIST_SUCCESS = "FETCH_FERTILIZATION_PLAN_LIST_SUCCESS";
export const FETCH_FERTILIZATION_PLAN_LIST_FAILURE = "FETCH_FERTILIZATION_PLAN_LIST_FAILURE";