import {
  ENSILATION_TYPE_LOADING,
  ENSILATION_TYPE_ERROR,
  ENSILATION_TYPE_SUCCESS
} from 'constants/store/EnsilationType';

const initialState = {
  isEnsilationTypeHasError: false,
  isEnsilationTypeIsLoading: false,
  isEnsilationTypeErrorMessage: null,
  isEnsilationTypeSuccess: false,
  ensilationTypeData: null
};

export default function ensilationTypeReducer(state = initialState, action) {
  const { type } = action;
  switch (type) {
    case ENSILATION_TYPE_LOADING:
      return {
        ...state,
        isEnsilationTypeHasError: false,
        isEnsilationTypeIsLoading: true,
        isEnsilationTypeErrorMessage: null,
        isEnsilationTypeSuccess: false
      };
    case ENSILATION_TYPE_ERROR:
      return {
        ...state,
        isEnsilationTypeHasError: true,
        isEnsilationTypeIsLoading: false,
        isEnsilationTypeErrorMessage: action.payload,
        isEnsilationTypeSuccess: false
      };
    case ENSILATION_TYPE_SUCCESS:
      return {
        ...state,
        isEnsilationTypeHasError: false,
        isEnsilationTypeIsLoading: false,
        isEnsilationTypeErrorMessage: null,
        isEnsilationTypeSuccess: true,
        ensilationTypeData: action.payload
      };
    default:
      return state;
  }
}
