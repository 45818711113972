import React, { useState, useRef, useEffect } from "react";
import { Typography, Box, Chip, Dialog } from '@mui/material';
import MuiSelectMobileDropdown from 'components/mui3-forms/MuiSelectMobileDropdown';
import * as taskTemplateStyle from 'mui-mobile-components/kart/fieldmap/tasktemplates/TaskTemplateStyle';
import AddVehicleModal from 'mui-mobile-components/kart/fieldmap/vehicleModals/AddVehicleModal';
import AddEquipmentModal from 'mui-mobile-components/kart/fieldmap/vehicleModals/AddEquipmentModal';
import AddIcon from '@mui/icons-material/Add';
import { Close as CloseIcon } from '@mui/icons-material';
import _ from 'lodash';
import {
  ACT7,
  PESTICIDE_MINERAL_FERTILIZER_EN,
  PESTICIDE_MINERAL_FERTILIZER_NB,
  PESTICIDE_FOILAR_FERTILIZER_EN,
  PESTICIDE_FOILAR_FERTILIZER_NB,
  PESTICIDE_LIMING_EN,
  PESTICIDE_LIMING_NB,
  PESTICIDE_MANURE_EN,
  PESTICIDE_MANURE_NB
} from 'constants/index';

const AddTaskTemplate7 = (props) => {
  const {intl, activeFarm, selectedActivity, taskDetails, closeNewTask, saveNewTask,
    createdVehicleId, createdEquipmentId, activitiesCommon, taskKartToggle, seasons
  } = props;
  let unitValue = "";
  if(selectedActivity.id === 'act7') { unitValue = "kg/daa"; }
  if(selectedActivity.id === 'act30') { unitValue = "m^3"; }
  if(selectedActivity.id === 'act31') { unitValue = "kg/daa"; }
  if(selectedActivity.id === 'act32') { unitValue = "ml/daa"; }
  const initialFormState = {
    farmId: activeFarm.farm.id,
    activityId: selectedActivity?.id,
    activityName: selectedActivity?.activityName,
    //planAmount: "",
    unit: unitValue,
    vehicleAssetId: "",
    equipmentAssetId: "",
    waterJournal: null,
    waterJournalAdded: false,
    season: selectedActivity?.season ? selectedActivity?.season : 1
  };
  const [formState, setFormState] = useState(initialFormState);
  const initialStateRef = useRef(initialFormState);
  const [isModalOpen, setModalOpen] = useState(false);
  const [showAddVehicleDialog, setShowAddVehicleDialog] = useState(false);
  const [showAddEquipmentDialog, setShowAddEquipmentDialog] = useState(false);
  const [unitOptions, setUnitOptions] = useState([]);

  useEffect(() => {
    if(selectedActivity.id === 'act7') {
      setUnitOptions(mineralFertilizerUnitOptions());
    }
    if(selectedActivity.id === 'act30') {
      setUnitOptions(manourUnitOptions());
    }
    if(selectedActivity.id === 'act31') {
      setUnitOptions(limingUnitOptions());
    }
    if(selectedActivity.id === 'act32') {
      setUnitOptions(foilerFertilizerUnitOptions());
    }
    if (taskKartToggle?.getTaskList?.taskActivity) {
      let changedUnitValue = unitValue;
      if(taskKartToggle.getTaskList.taskActivity.unit !== null && taskKartToggle.getTaskList.taskActivity.unit !==  "") {
        changedUnitValue = taskKartToggle.getTaskList.taskActivity.unit;
      }
      setFormState((prevState) => {
        const updatedFormState = {
          ...prevState,
          unit: changedUnitValue,
          vehicleAssetId: taskKartToggle.getTaskList.vehicleAssetId,
          equipmentAssetId: taskKartToggle.getTaskList.equipmentAssetId,
          season: taskKartToggle.getTaskList.taskActivity.season ? taskKartToggle.getTaskList.taskActivity.season : prevState.season
        };
        
        initialStateRef.current = updatedFormState;
        return updatedFormState;
      });
    }
    // eslint-disable-next-line
  }, [taskKartToggle]);

  const manourUnitOptions = () => {
    let manourUnitOptionsArray = [];
    _.filter(activitiesCommon, ['activityId', ACT7])[0].dropdowns[0].elements.map(elements => (
      (elements.element === PESTICIDE_MANURE_EN || elements.element === PESTICIDE_MANURE_NB) &&
      elements.units.plannedAmountUnit.map(options => (
        manourUnitOptionsArray.push({ label: options, value: options })
      ))
    ))
    return manourUnitOptionsArray;
  }
  
  const foilerFertilizerUnitOptions = () => {
    let foilerFertilizerUnitOptionsArray = [];
    _.filter(activitiesCommon, ['activityId', ACT7])[0].dropdowns[0].elements.map(elements => (
      (elements.element === PESTICIDE_FOILAR_FERTILIZER_EN || elements.element === PESTICIDE_FOILAR_FERTILIZER_NB) &&
      elements.units.plannedAmountUnit.map(options => (
        foilerFertilizerUnitOptionsArray.push({ label: options, value: options })
      ))
    ))
    return foilerFertilizerUnitOptionsArray;
  }

  const limingUnitOptions = () => {
    let limingUnitOptionsArray = [];
    _.filter(activitiesCommon, ['activityId', ACT7])[0].dropdowns[0].elements.map(elements => (
      (elements.element === PESTICIDE_LIMING_EN || elements.element === PESTICIDE_LIMING_NB) &&
      elements.units.plannedAmountUnit.map(options => (
        limingUnitOptionsArray.push({ label: options, value: options })
      ))
    ))
    return limingUnitOptionsArray;
  }
  
  const mineralFertilizerUnitOptions = () => {
    let mineralFertilizerUnitOptionsArray = [];
    _.filter(activitiesCommon, ['activityId', ACT7])[0].dropdowns[0].elements.map(elements => (
      (elements.element === PESTICIDE_MINERAL_FERTILIZER_EN || elements.element === PESTICIDE_MINERAL_FERTILIZER_NB) &&
      elements.units.plannedAmountUnit.map(options => (
        mineralFertilizerUnitOptionsArray.push({ label: options, value: options })
      ))
    ))
    return mineralFertilizerUnitOptionsArray;
  }

  const AddVehicle = () => (
    <Box style={taskTemplateStyle.assetAdd} onClick={() => setShowAddVehicleDialog(true)}>
      <Box sx={{ padding: '5px 15px 0px 0px' }}>
        <AddIcon />
      </Box>
      <Typography style={taskTemplateStyle.assetAddHeader}>{intl.formatMessage({ id: 'CT_ANV' })}</Typography>
    </Box>
  );
  const vehicleOptions = () => {
    let vehicleList = [];
    vehicleList.push({value: null, label: <AddVehicle />});
    if (taskDetails.allVehicles && (taskDetails.allVehicles).length > 0) {
      (taskDetails.allVehicles).map(data => (
        vehicleList.push({
          value: data.asset.assetId,
          label: `${data.asset.brand} ${data.asset.model}`
        })
      ));
      return vehicleList;
    }
    return vehicleList;
  };
  const AddEquipment = () => (
    <Box style={taskTemplateStyle.assetAdd} onClick={() => setShowAddEquipmentDialog(true)}>
      <Box sx={{ padding: '5px 15px 0px 0px' }}>
        <AddIcon />
      </Box>
      <Typography style={taskTemplateStyle.assetAddHeader}>{intl.formatMessage({ id: 'CT_ANE' })}</Typography>
    </Box>
  );
  const equipmentOptions = () => {
    let equipmentList = [];
    equipmentList.push({value: null, label: <AddEquipment />});
    if (taskDetails.allEquipments && (taskDetails.allEquipments).length > 0) {
      (taskDetails.allEquipments).map(data => (
        equipmentList.push({
          value: data.asset.assetId,
          label: `${data.asset.brand} ${data.asset.model}`
        })
      ));
      return equipmentList;
    }
    return equipmentList;
  };

  const seasonOptions = () => {
    let seasonOption = [];
    if (seasons && seasons.seasonsList) {
      (seasons.seasonsList).map(data => (
        seasonOption.push({
          value: data.id,
          label: data.season
        })
      ));
    }
    return seasonOption;
  };
  const handleFieldChange = (fieldName, value) => {
    setFormState((prevState) => ({
      ...prevState,
      [fieldName]: value
    }));
  };

  const handleSave = () => {
    setModalOpen(false);
    saveNewTask(formState);
  };

  const handleContinueSave = () => {
    setModalOpen(false);
  };

  const handleCancel = () => {
    let isChanged = JSON.stringify(initialStateRef.current) !== JSON.stringify(formState);
    if (isChanged) {
      setModalOpen(true);
    } else {
      handleCancelButton();
    }
  };

  const handleCancelButton = () => {
    setModalOpen(false);
    closeNewTask();
  };

  const onCloseVehicleDialog = () => {
    setShowAddVehicleDialog(false);
  }

  const onCloseEquipmentDialog = () => {
    setShowAddEquipmentDialog(false);
  }
  return (
    <Box sx={{
      padding: '0px 16px 0px 16px',
      overflowY: 'auto',
      '&::-webkit-scrollbar': {
        width: '5px',
        backgroundColor: 'rgba(0, 0, 0, 0.1)',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#A8A8A8',
        borderRadius: '4px',
        transition: 'background-color 0.3s ease',
      },
      '&::-webkit-scrollbar-thumb:hover': {
        backgroundColor: '#B8B8B8',
      }}}
    >
      <Box style={taskTemplateStyle.headerSticky}>
        <Box style={taskTemplateStyle.modalDialogHeader}>
          <Box style={taskTemplateStyle.modalDialogHeader1}>
            <Typography sx={{ paddingTop: '5px', paddingRight: '10px' }}>
              <CloseIcon
                width="24"
                height="24"
                onClick={handleCancel}
                style={{ cursor: 'pointer' }}
              />
            </Typography>
            <Typography style={taskTemplateStyle.modalDialogTitle}>{selectedActivity?.activityName}</Typography>
          </Box>
        </Box>
        <Box style={taskTemplateStyle.modalDialogHeader} sx={{paddingTop: '16px'}}>
          <Typography style={taskTemplateStyle.modalDialogTitleContent}>{intl.formatMessage({ id: 'DEFAULT_FARM_VALUES_INFO' })}</Typography>
        </Box>
      </Box>
      <Box>
        <Box sx={{ paddingTop: '48px', width: '100%' }}>
          {/*<Box sx={{ display: "flex", alignItems: "center" }}>
            <MuiTextField
              defaultLabel={intl.formatMessage({ id: 'PLANNED' })}
              defaultValue={formState.planAmount}
              fieldName='planAmount'
              onChange={handleNumericValidation}
              autoFocus={false}
              errorHelperText={formErrors['planAmount']}
              inputAdornment={`${formState.planUnit === "" || formState.planUnit === null || formState.planUnit === undefined ? '' : formState.planUnit}`}
            />
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", paddingTop: '24px' }}>
            <MuiSelectMobileDropdown
              defaultLabel={intl.formatMessage({ id: 'CT_UNIT' })}
              defaultValue={formState.planUnit}
              options={unitOptions}
              fieldName="planUnit"
              onChange={handleFieldChange}
              textLabel={intl.formatMessage({ id: 'CT_UNIT' })}
            />
          </Box>*/}
          <Box sx={{ paddingTop: '16px', marginBottom: '24px' }}>
            <MuiSelectMobileDropdown
              defaultLabel={intl.formatMessage({ id: 'CT_SEASON' })}
              options={seasonOptions()}
              defaultValue={formState.season}
              fieldName='season'
              onChange={handleFieldChange}
            />
          </Box>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <MuiSelectMobileDropdown
              defaultLabel={intl.formatMessage({ id: 'ACTUAL_UNIT' })}
              defaultValue={formState.unit}
              options={unitOptions}
              fieldName="unit"
              onChange={handleFieldChange}
              textLabel={intl.formatMessage({ id: 'ACTUAL_UNIT' })}
            />
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", paddingTop: '24px' }}>
            <MuiSelectMobileDropdown
              key={createdVehicleId}
              defaultLabel={intl.formatMessage({ id: 'VEHICLE' })}
              defaultValue={createdVehicleId}
              options={vehicleOptions()}
              fieldName="vehicleAssetId"
              onChange={handleFieldChange}
              textLabel={intl.formatMessage({ id: 'VEHICLE' })}
            />
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", paddingTop: '24px' }}>
            <MuiSelectMobileDropdown
              key={createdEquipmentId}
              defaultLabel={intl.formatMessage({ id: 'EQUIPMENT' })}
              defaultValue={createdEquipmentId}
              options={equipmentOptions()}
              fieldName="equipmentAssetId"
              onChange={handleFieldChange}
              textLabel={intl.formatMessage({ id: 'EQUIPMENT' })}
            />
          </Box>
        </Box>
      </Box>
      <Box style={taskTemplateStyle.buttonBox}>
        <Box style={taskTemplateStyle.buttonBoxBottom}>
          <Chip
            label={intl.formatMessage({ id: 'SAVE' })}
            clickable
            style={taskTemplateStyle.okButtonStyle}
            sx={{ backgroundColor: 'transparent',
              '&:hover, &:focus': {
                backgroundColor: 'transparent',
              }
            }}
            disableRipple
            onClick={handleSave}
          />
        </Box>
      </Box>
      {/* Modal */}
      <Box>    
        <Dialog onClose={() => {}} open={isModalOpen} PaperProps={{
          style: {
            backgroundColor: '#E6E9E8',
            borderRadius: "28px",
            width: "312px",
            minWidth: "280px",
            maxWidth: "312px",
            maxHeight: '30vh'
          }}}>
          <Box style={taskTemplateStyle.cancelModalBox}>
            <Typography style={taskTemplateStyle.cancelModalTitle}>{intl.formatMessage({ id: 'TASK_CANCEL_MODAL_TITLE' })}</Typography>
            <Typography style={taskTemplateStyle.cancelModalSubTitle}>{intl.formatMessage({ id: 'TASK_CANCEL_MODAL_SUB_TITLE' })}</Typography>
          </Box>
          <Box style={taskTemplateStyle.cancelModalBoxButton}>
            <Chip
              label={intl.formatMessage({ id: 'cancel' })}
              clickable
              style={taskTemplateStyle.cancelButtonStyle}
              sx={{ backgroundColor: 'transparent',
                '&:hover, &:focus': {
                  backgroundColor: 'transparent',
                }
              }}
              disableRipple
              onClick={handleCancelButton}
            />
            <Chip
              label={intl.formatMessage({ id: 'TASK_CONTINUE_REGISTRATION' })}
              clickable
              style={taskTemplateStyle.cancelButtonStyle}
              sx={{ backgroundColor: 'transparent',
                '&:hover, &:focus': {
                  backgroundColor: 'transparent',
                }
              }}
              disableRipple
              onClick={handleContinueSave}
            />
          </Box>
        </Dialog>
      </Box>
      <AddVehicleModal
        intl={intl}
        vehicleSelectedId={'new'}
        onCloseVehicleDialog={onCloseVehicleDialog}
        showAddVehicleDialog={showAddVehicleDialog}
        assetType={'vehicle'}
        taskDetails={taskDetails}
      />
      <AddEquipmentModal
        intl={intl}
        equipmentSelectedId={'new'}
        onCloseEquipmentDialog={onCloseEquipmentDialog}
        showAddEquipmentDialog={showAddEquipmentDialog}
        assetType={'equipment'}
        taskDetails={taskDetails}
      />
    </Box>
  );
};

export default AddTaskTemplate7;