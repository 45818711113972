import React, { useState, useRef, useEffect } from "react";
import { Typography, Box, Switch, Chip, Dialog, FormControlLabel } from '@mui/material';
import { styled } from '@mui/material/styles';
import MuiChipMobileDropdown from 'components/mui3-forms/MuiChipMobileDropdown';
import MuiDatePicker from 'components/mui3-forms/MuiDatePicker';
import MuiTextField from 'components/mui3-forms/MuiTextField';
import MuiTextFieldArea from 'components/mui3-forms/MuiTextFieldArea';
import MuiSelectMobileDropdown from 'components/mui3-forms/MuiSelectMobileDropdown';
import MuiCollapseComponent from 'components/mui3-forms/MuiCollapseComponent';
import * as taskTemplateStyle from 'mui-mobile-components/kart/fieldmap/tasktemplates/TaskTemplateStyle';
import AddVehicleModal from 'mui-mobile-components/kart/fieldmap/vehicleModals/AddVehicleModal';
import AddEquipmentModal from 'mui-mobile-components/kart/fieldmap/vehicleModals/AddEquipmentModal';
import AddIcon from '@mui/icons-material/Add';
import { Close as CloseIcon } from '@mui/icons-material';
import _ from 'lodash';
import {
  ACT7,
  PESTICIDE_MINERAL_FERTILIZER_EN,
  PESTICIDE_MINERAL_FERTILIZER_NB,
  PESTICIDE_FOILAR_FERTILIZER_EN,
  PESTICIDE_FOILAR_FERTILIZER_NB,
  PESTICIDE_LIMING_EN,
  PESTICIDE_LIMING_NB,
  PESTICIDE_MANURE_EN,
  PESTICIDE_MANURE_NB
} from 'constants/index';

const ToggleMF = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 52,
  height: 32,
  padding: 0,
  position: 'relative',
  '&:hover .MuiSwitch-thumb': {
    backgroundColor: '#3F4949', // Thumb color change on hover
  },
  '& .MuiSwitch-switchBase': {
    padding: 0,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#ffffff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#005A5C' : '#00696D',
        opacity: 1,
        border: 0,
      },
      '& .MuiSwitch-thumb': {
        backgroundColor: '#FFFFFF',
        width: 24,
        height: 24,
        marginTop: '4px',
        marginRight: '3px',
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.3)',
      },
      '&:hover .MuiSwitch-thumb': {
        backgroundColor: '#6FF6FC', // Thumb color change on hover
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #00696D',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color: '#BDBDBD',
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 16,
    height: 16,
    marginTop: '8px',
    marginLeft: '8px',
    backgroundColor: '#6F7979',
    position: 'relative', // Ensure pseudo-element is positioned correctly
    '&::before': {
      content: '""',
      position: 'absolute',
      top: '50%',
      left: '50%',
      width: '32px', // Default size for the grey circle
      height: '32px', // Default size for the grey circle
      backgroundColor: 'rgba(128, 128, 128, 0.3)', // Grey transparent background
      borderRadius: '50%', // Ensure the grey circle is fully rounded
      transform: 'translate(-50%, -50%)',
      opacity: 0,
      transition: 'opacity 0.2s ease-in-out, width 0.2s, height 0.2s', // Smooth size transition
    },
  },
  '&:hover .MuiSwitch-track': {
    backgroundColor: '#D3D3D3', // Change background when hovering anywhere on switch
    borderRadius: '100px', // Keep the track background rounded
  },
  '& .MuiSwitch-track': {
    borderRadius: '100px', // Ensure the track is rounded
    backgroundColor: '#E6E9E8',
    border: '3px solid #6F7979',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 300,
    }),
  },
}));

const AddTaskTemplate7 = (props) => {
  const { intl, activeFarm, selectedFields, selectedActivity, taskDetails, closeNewTask, saveNewTask,
    createdVehicleId, createdEquipmentId, seasons, activitiesCommon, manureStorage, taskKartToggle, handleRemoveFieldId
  } = props;
  const initialFormState = {
    farmId: activeFarm.farm.id,
    activityId: selectedActivity?.id,
    fieldIds: selectedFields[0]?.fieldId,
    activityName: selectedActivity?.activityName,
    season: taskKartToggle?.getTaskList?.taskActivity?.season || "1",
    startTime: new Date(new Date().setHours(0, 0, 0, 0)),
    amount: "",
    ...Object.fromEntries(
      selectedFields[0]?.fieldId.map(field => [`amount_${field}`, ""])
    ),
    type: "",
    subType: "",
    planAmount: "",
    totalAmount: "",
    unit: "",
    note: "",
    vehicleAssetId: "",
    equipmentAssetId: "",
    waterJournal: null,
    waterJournalAdded: false,
    manureStorageId: null,
    manureStorageName: null,
    perField: false,
    planAmounts: []
  };
  const [formState, setFormState] = useState(initialFormState);
  const [formErrors, setFormErrors] = useState({});
  const initialStateRef = useRef(initialFormState);
  const [isModalOpen, setModalOpen] = useState(false);
  const [showAddVehicleDialog, setShowAddVehicleDialog] = useState(false);
  const [showAddEquipmentDialog, setShowAddEquipmentDialog] = useState(false);
  // eslint-disable-next-line
  const [subTypeOptions, setSubTypeOptions] = useState([]);
  const [unitOptions, setUnitOptions] = useState([]);
  const [selectedPlanUnit, setSelectedPlanUnit] = useState([]);
  const [isPerField, setIsPerField] = useState(false);
  const formatNumber = (num) => {
    return num !== undefined && num !== null
      ? new Intl.NumberFormat('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 1 }).format(num)
      : "-"; // If value is missing, return "-"
  };

  useEffect(() => {
    setFormState((prevState) => {
      let preSelected = formState.perField;
      if (selectedFields[0]?.fieldId.length <= 1) {
        preSelected = false;
      }
      const updatedFormState = {
        ...prevState,
        fieldIds: selectedFields[0]?.fieldId,
        perField: preSelected
      };
      initialStateRef.current = updatedFormState;
      return updatedFormState;
    });
  }, [selectedFields, formState.perField]);

  useEffect(() => {
    if (selectedActivity.id === 'act7') {
      setSubTypeOptions(mineralFertilizerOptions());
      setUnitOptions(mineralFertilizerUnitOptions());
      setSelectedPlanUnit('kg/daa');
      setFormState((prevState) => {
        const updatedFormState = {
          ...prevState,
          unit: 'kg/daa',
          planUnit: 'kg/daa',
          type: PESTICIDE_MINERAL_FERTILIZER_EN,
          season: taskKartToggle?.getTaskList?.taskActivity?.season || "",
        };
        initialStateRef.current = updatedFormState;
        return updatedFormState;
      });
    }
    if (selectedActivity.id === 'act30') {
      setSubTypeOptions(manureOptions());
      setUnitOptions(manourUnitOptions());
      setSelectedPlanUnit('m^3');
      setFormState((prevState) => {
        const updatedFormState = {
          ...prevState,
          unit: 'm^3',
          planUnit: 'm^3/daa',
          type: PESTICIDE_MANURE_EN,
          season: taskKartToggle?.getTaskList?.taskActivity?.season || "",
        };
        initialStateRef.current = updatedFormState;
        return updatedFormState;
      });
    }
    if (selectedActivity.id === 'act31') {
      setSubTypeOptions(fertilizerSubTypeLimingOptions());
      setUnitOptions(limingUnitOptions());
      setSelectedPlanUnit('kg/daa');
      setFormState((prevState) => {
        const updatedFormState = {
          ...prevState,
          unit: 'kg/daa',
          planUnit: 'kg/daa',
          type: PESTICIDE_LIMING_EN,
          season: taskKartToggle?.getTaskList?.taskActivity?.season || "",
        };
        initialStateRef.current = updatedFormState;
        return updatedFormState;
      });
    }
    if (selectedActivity.id === 'act32') {
      setSubTypeOptions(fertilizerSubTypeFoilerDataOptions());
      setUnitOptions(foilerFertilizerUnitOptions());
      setSelectedPlanUnit('ml/daa');
      setFormState((prevState) => {
        const updatedFormState = {
          ...prevState,
          unit: 'ml/daa',
          planUnit: 'ml/daa',
          type: PESTICIDE_FOILAR_FERTILIZER_EN,
          season: taskKartToggle?.getTaskList?.taskActivity?.season || "",
        };
        initialStateRef.current = updatedFormState;
        return updatedFormState;
      });
    }
    if (taskKartToggle?.getTaskList?.taskActivity) {
      setFormState((prevState) => {
        const updatedFormState = {
          ...prevState,
          vehicleAssetId: taskKartToggle.getTaskList.vehicleAssetId,
          equipmentAssetId: taskKartToggle.getTaskList.equipmentAssetId
        };
        initialStateRef.current = updatedFormState;
        return updatedFormState;
      });
      if (selectedActivity.id === 'act7') {
        const fieldMfMap = taskKartToggle?.getTaskList?.taskActivity?.fieldMfSeasonMap;
        const newPlanAmounts = selectedFields[0].fieldId.reduce((acc, field) => {
          const filteredData = fieldMfMap?.filter(item => item.fieldId === field);
          const mfSeasonMap = filteredData?.[0]?.mfSeasonMap || [];
          let quantity = null;
          if (mfSeasonMap?.[1] && mfSeasonMap?.[1][0]?.name === taskKartToggle?.getTaskList?.taskActivity.subType) {
            quantity = mfSeasonMap?.[1][0]?.quantity || null;
          }
          acc.push(formatNumber(quantity));
          return acc;
        }, []);
        setFormState((prevState) => {
          const updatedFormState1 = {
            ...prevState,
            planAmount: taskKartToggle?.getTaskList?.taskActivity.planAmount ? (taskKartToggle?.getTaskList?.taskActivity.planAmount).toFixed(1) : '',
            planAmounts: newPlanAmounts,
            totalAmount: taskKartToggle?.getTaskList?.taskActivity.totalAmount ? (taskKartToggle?.getTaskList?.taskActivity.totalAmount).toFixed(1) : '',
            unit: taskKartToggle?.getTaskList?.taskActivity.unit || 'kg/daa',
            subType: taskKartToggle?.getTaskList?.taskActivity.subType || '',
            planUnit: taskKartToggle?.getTaskList?.taskActivity.planUnit ? taskKartToggle?.getTaskList?.taskActivity.planUnit : 'kg/daa',
            season: taskKartToggle?.getTaskList?.taskActivity?.season || "",
          };
          initialStateRef.current = updatedFormState1;
          return updatedFormState1;
        });
        setSelectedPlanUnit(taskKartToggle?.getTaskList?.taskActivity.unit || 'kg/daa');
      }
      if (selectedActivity.id === 'act30') {
        const fieldManureSeasonMap = taskKartToggle?.getTaskList?.taskActivity?.fieldManureSeasonMap;
        const newPlanAmounts = selectedFields[0].fieldId.reduce((acc, field) => {
          const filteredData1 = fieldManureSeasonMap?.filter(item => item.fieldId === field);
          const manureSeasonMap = filteredData1?.[0]?.manureSeasonMap || [];
          let quantity = null;
          if (manureSeasonMap?.[1]?.storageId === taskKartToggle?.getTaskList?.taskActivity.manureStorageId) {
            quantity = manureSeasonMap?.[1]?.quantity || null;
          }
          acc.push(formatNumber(quantity));
          return acc;
        }, []);
        setFormState((prevState) => {
          const updatedFormState2 = {
            ...prevState,
            planAmount: taskKartToggle?.getTaskList?.taskActivity.planAmount ? (taskKartToggle?.getTaskList?.taskActivity.planAmount).toFixed(1) : '',
            planAmounts: newPlanAmounts,
            totalAmount: taskKartToggle?.getTaskList?.taskActivity.totalAmount ? (taskKartToggle?.getTaskList?.taskActivity.totalAmount).toFixed(1) : '',
            unit: taskKartToggle?.getTaskList?.taskActivity.unit || 'm^3',
            subType: taskKartToggle?.getTaskList?.taskActivity.manureStorageId || null,
            manureStorageId: taskKartToggle?.getTaskList?.taskActivity.manureStorageId || null,
            manureStorageName: taskKartToggle?.getTaskList?.taskActivity.manureStorageName || null,
            planUnit: taskKartToggle?.getTaskList?.taskActivity.planUnit ? taskKartToggle?.getTaskList?.taskActivity.planUnit : 'm^3/daa',
            season: taskKartToggle?.getTaskList?.taskActivity?.season || "",
          };
          initialStateRef.current = updatedFormState2;
          return updatedFormState2;
        });
        setSelectedPlanUnit(taskKartToggle?.getTaskList?.taskActivity.unit || 'm^3');
      }
      if (selectedActivity.id === 'act31') {
        setFormState((prevState) => {
          const updatedFormState2 = {
            ...prevState,
            unit: taskKartToggle?.getTaskList?.taskActivity.unit || 'kg/daa',
            planUnit: taskKartToggle?.getTaskList?.taskActivity.planUnit ? taskKartToggle?.getTaskList?.taskActivity.planUnit : 'kg/daa',
            season: taskKartToggle?.getTaskList?.taskActivity?.season || "",
          };
          initialStateRef.current = updatedFormState2;
          return updatedFormState2;
        });
        setSelectedPlanUnit(taskKartToggle?.getTaskList?.taskActivity.unit || 'kg/daa');
      }
      if (selectedActivity.id === 'act32') {
        setFormState((prevState) => {
          const updatedFormState2 = {
            ...prevState,
            unit: taskKartToggle?.getTaskList?.taskActivity.unit || 'ml/daa',
            planUnit: taskKartToggle?.getTaskList?.taskActivity.planUnit ? taskKartToggle?.getTaskList?.taskActivity.planUnit : 'ml/daa',
            season: taskKartToggle?.getTaskList?.taskActivity?.season || "",
          };
          initialStateRef.current = updatedFormState2;
          return updatedFormState2;
        });
        setSelectedPlanUnit(taskKartToggle?.getTaskList?.taskActivity.unit || 'ml/daa');
      }
    }
    // eslint-disable-next-line
  }, [taskKartToggle]);

  const seasonOptions = () => {
    let seasonOption = [];
    if (seasons && seasons.seasonsList) {
      (seasons.seasonsList).map(data => (
        seasonOption.push({
          value: data.id,
          label: data.season
        })
      ));
    }
    return seasonOption;
  };

  const handleToggleChange = () => {
    setFormState((prevState) => ({
      ...prevState,
      'perField': !isPerField,
    }));
    setIsPerField((prev) => !prev); // Toggle the state
  };

  const fertilizerSubTypeFoilerDataOptions = () => {
    let fertilizerSubTypeFoilerDataOptionsArray = [];
    _.filter(activitiesCommon, ['activityId', ACT7])[0].dropdowns[0].elements.map(elements => (
      (elements.element === PESTICIDE_FOILAR_FERTILIZER_EN || elements.element === PESTICIDE_FOILAR_FERTILIZER_NB) &&
      elements.leaves.map(options => (
        fertilizerSubTypeFoilerDataOptionsArray.push({ label: options, value: options })
      ))
    ))
    return fertilizerSubTypeFoilerDataOptionsArray;
  }

  const fertilizerSubTypeLimingOptions = () => {
    let fertilizerSubTypeLimingOptionsArray = [];
    _.filter(activitiesCommon, ['activityId', ACT7])[0].dropdowns[0].elements.map(elements => (
      (elements.element === PESTICIDE_LIMING_EN || elements.element === PESTICIDE_LIMING_NB) &&
      elements.leaves.map(options => (
        fertilizerSubTypeLimingOptionsArray.push({ label: options, value: options })
      ))
    ))
    return fertilizerSubTypeLimingOptionsArray;
  }

  const manureOptions = () => {
    return manureStorage?.manureList?.map(option => ({
      label: option.name,
      value: option.id,
    })) || [];
  };

  const mineralFertilizerOptions = () => {
    const responseMap = taskKartToggle?.getTaskList?.taskActivity;
    if (responseMap && responseMap.mfList) {
      // Initialize the options array and a Set to track unique entries
      let mineralFertilizerOptionsArray = responseMap.mfList.map(item => ({
        label: item,
        value: item,
      }));
      const uniqueLabels = new Set(mineralFertilizerOptionsArray.map(option => option.label));
      // Add additional options from elements.leaves
      _.filter(activitiesCommon, ['activityId', ACT7])[0].dropdowns[0].elements.forEach(elements => {
        if (elements.element === PESTICIDE_MINERAL_FERTILIZER_EN || elements.element === PESTICIDE_MINERAL_FERTILIZER_NB) {
          elements.leaves.forEach(options => {
            const label = options;
            if (!uniqueLabels.has(label)) {
              mineralFertilizerOptionsArray.push({ label, value: label });
              uniqueLabels.add(label);
            }
          });
        }
      });
      return mineralFertilizerOptionsArray;
    }
  };

  const getModifiedMineralFertilizerOptions = () => {
    const responseMap = taskKartToggle?.getTaskList?.taskActivity;
    let responseMapLength = 0;
    if (responseMap && responseMap.mfList) {
      responseMapLength = responseMap.mfList.length;
    }
    return responseMapLength;
  };

  const manourUnitOptions = () => {
    let manourUnitOptionsArray = [];
    _.filter(activitiesCommon, ['activityId', ACT7])[0].dropdowns[0].elements.map(elements => (
      (elements.element === PESTICIDE_MANURE_EN || elements.element === PESTICIDE_MANURE_NB) &&
      elements.units.plannedAmountUnit.map(options => (
        manourUnitOptionsArray.push({ label: options, value: options })
      ))
    ))
    return manourUnitOptionsArray;
  }

  const foilerFertilizerUnitOptions = () => {
    let foilerFertilizerUnitOptionsArray = [];
    _.filter(activitiesCommon, ['activityId', ACT7])[0].dropdowns[0].elements.map(elements => (
      (elements.element === PESTICIDE_FOILAR_FERTILIZER_EN || elements.element === PESTICIDE_FOILAR_FERTILIZER_NB) &&
      elements.units.plannedAmountUnit.map(options => (
        foilerFertilizerUnitOptionsArray.push({ label: options, value: options })
      ))
    ))
    return foilerFertilizerUnitOptionsArray;
  }

  const limingUnitOptions = () => {
    let limingUnitOptionsArray = [];
    _.filter(activitiesCommon, ['activityId', ACT7])[0].dropdowns[0].elements.map(elements => (
      (elements.element === PESTICIDE_LIMING_EN || elements.element === PESTICIDE_LIMING_NB) &&
      elements.units.plannedAmountUnit.map(options => (
        limingUnitOptionsArray.push({ label: options, value: options })
      ))
    ))
    return limingUnitOptionsArray;
  }

  const mineralFertilizerUnitOptions = () => {
    let mineralFertilizerUnitOptionsArray = [];
    _.filter(activitiesCommon, ['activityId', ACT7])[0].dropdowns[0].elements.map(elements => (
      (elements.element === PESTICIDE_MINERAL_FERTILIZER_EN || elements.element === PESTICIDE_MINERAL_FERTILIZER_NB) &&
      elements.units.plannedAmountUnit.map(options => (
        mineralFertilizerUnitOptionsArray.push({ label: options, value: options })
      ))
    ))
    return mineralFertilizerUnitOptionsArray;
  }

  const AddVehicle = () => (
    <Box style={taskTemplateStyle.assetAdd} onClick={() => setShowAddVehicleDialog(true)}>
      <Box sx={{ padding: '5px 15px 0px 0px' }}>
        <AddIcon />
      </Box>
      <Typography style={taskTemplateStyle.assetAddHeader}>{intl.formatMessage({ id: 'CT_ANV' })}</Typography>
    </Box>
  );
  const vehicleOptions = () => {
    let vehicleList = [];
    vehicleList.push({ value: null, label: <AddVehicle /> });
    if (taskDetails.allVehicles && (taskDetails.allVehicles).length > 0) {
      (taskDetails.allVehicles).map(data => (
        vehicleList.push({
          value: data.asset.assetId,
          label: `${data.asset.brand} ${data.asset.model}`
        })
      ));
      return vehicleList;
    }
    return vehicleList;
  };
  const AddEquipment = () => (
    <Box style={taskTemplateStyle.assetAdd} onClick={() => setShowAddEquipmentDialog(true)}>
      <Box sx={{ padding: '5px 15px 0px 0px' }}>
        <AddIcon />
      </Box>
      <Typography style={taskTemplateStyle.assetAddHeader}>{intl.formatMessage({ id: 'CT_ANE' })}</Typography>
    </Box>
  );
  const equipmentOptions = () => {
    let equipmentList = [];
    equipmentList.push({ value: null, label: <AddEquipment /> });
    if (taskDetails.allEquipments && (taskDetails.allEquipments).length > 0) {
      (taskDetails.allEquipments).map(data => (
        equipmentList.push({
          value: data.asset.assetId,
          label: `${data.asset.brand} ${data.asset.model}`
        })
      ));
      return equipmentList;
    }
    return equipmentList;
  };

  const handleFieldChange = (fieldName, value) => {
    if (selectedActivity.id === 'act7') {
      const fieldMfMap = taskKartToggle?.getTaskList?.taskActivity?.fieldMfSeasonMap;
      const filteredData = fieldMfMap && fieldMfMap.filter(item => item.fieldId === selectedFields[0]?.fieldId[0])[0] && fieldMfMap.filter(item => item.fieldId === selectedFields[0]?.fieldId[0])[0].mfSeasonMap;
      if (filteredData && fieldName === 'subType') {
        let mfSeasonMap = filteredData[formState.season] && (filteredData[formState.season] || null);
        mfSeasonMap = mfSeasonMap && mfSeasonMap.filter(item => item.name === value)[0];
        const newPlanAmounts = selectedFields[0].fieldId.reduce((acc, field) => {
          const filteredData1 = fieldMfMap && fieldMfMap?.filter(item => item.fieldId === field);
          const mfSeasonMap1 = filteredData1?.[0]?.mfSeasonMap || [];
          let quantity = null;
          const filteredData2 = mfSeasonMap1?.[formState.season] && mfSeasonMap1?.[formState.season]?.filter(item => item.name === value);
          if (filteredData2 && filteredData2.length > 0) {
            quantity = filteredData2[0]?.quantity || null;
          }
          acc.push(formatNumber(quantity));
          return acc;
        }, []);
        let planAmountValue = null;
        let totalAmountValue = null;

        if (mfSeasonMap && mfSeasonMap.name === value) {
          planAmountValue = mfSeasonMap.quantity || null;
          totalAmountValue = mfSeasonMap.totalQuantity || null;
        }
        setFormState((prevState) => {
          const updatedFormState = {
            ...prevState,
            subType: value || null,
            planAmount: planAmountValue % 1 === 0 ? parseInt(planAmountValue, 10) : parseFloat(planAmountValue.toFixed(1)),
            planAmounts: newPlanAmounts,
            totalAmount: totalAmountValue % 1 === 0 ? parseInt(totalAmountValue, 10) : parseFloat(totalAmountValue.toFixed(1))
          };
          initialStateRef.current = updatedFormState;
          return updatedFormState;
        });
      } else if (filteredData && fieldName === 'season') {
        const mfSeasonMap = filteredData[value] && (filteredData[value][0] || null);
        let greaterQuantity = 0;
        let selectedMfId = null;

        selectedFields[0].fieldId.reduce((acc, field) => {
          const filteredData1 = fieldMfMap?.filter(item => item.fieldId === field);
          const mfSeasonMap = filteredData1?.[0]?.mfSeasonMap || [];
          if (mfSeasonMap?.[value]) {
            if (mfSeasonMap?.[value][0]?.quantity > greaterQuantity) {
              greaterQuantity = mfSeasonMap?.[value][0]?.quantity;
              selectedMfId = mfSeasonMap?.[value][0]?.name;
            }
          }
          return null;
        }, []);
        const newPlanAmounts = selectedFields[0].fieldId.reduce((acc, field) => {
          const filteredData1 = fieldMfMap?.filter(item => item.fieldId === field);
          const mfSeasonMap = filteredData1?.[0]?.mfSeasonMap || [];
          let quantity = null;
          if (mfSeasonMap?.[value] && mfSeasonMap?.[value][0]?.name === selectedMfId) {
            quantity = mfSeasonMap?.[value][0]?.quantity || null;
          }
          acc.push(formatNumber(quantity));
          return acc;
        }, []);

        let planAmountValue = null;
        let totalAmountValue = null;
        let selectedSubType = "";
        if (mfSeasonMap) {
          planAmountValue = mfSeasonMap.quantity || null;
          totalAmountValue = mfSeasonMap.totalQuantity || null;
          selectedSubType = mfSeasonMap.name || null;
        }
        if (selectedFields[0].fieldId.length > 1) {
          selectedSubType = selectedMfId || null;
        }
        setFormState((prevState) => {
          const updatedFormState = {
            ...prevState,
            season: value || null,
            planAmount: planAmountValue % 1 === 0 ? parseInt(planAmountValue, 10) : parseFloat(planAmountValue.toFixed(1)),
            planAmounts: newPlanAmounts,
            totalAmount: totalAmountValue % 1 === 0 ? parseInt(totalAmountValue, 10) : parseFloat(totalAmountValue.toFixed(1)),
            subType: selectedSubType
          };
          initialStateRef.current = updatedFormState;
          return updatedFormState;
        });
      } else if (fieldName === 'unit') {
        if (selectedFields[0].fieldId.length > 1 && formState.perField) {
          selectedFields[0].fieldId.reduce((acc, field) => {
            const fieldKey = `amount_${field}`;
            let totalAmountValue = formState[fieldKey];
            const filteredData = taskKartToggle?.getTaskList?.fieldAreas.filter(item => item.fieldId === field);
            if (totalAmountValue && filteredData) {
              let formStateValue = formState[fieldKey]
                ? parseFloat(formState[fieldKey]) *
                (value === "kg"
                  ? parseFloat(filteredData[0].area)
                  : 1 / parseFloat(filteredData[0].area))
                : "";
              if (formStateValue) {
                totalAmountValue = formStateValue % 1 === 0 ? parseInt(formStateValue, 10) : parseFloat(formStateValue.toFixed(1));
              }
            }
            setFormState((prevState) => {
              const updatedFormState = {
                ...prevState,
                unit: value || null,
                //planAmount: planAmountValue,
                [fieldKey]: totalAmountValue
              };
              initialStateRef.current = updatedFormState;
              return updatedFormState;
            });
            setSelectedPlanUnit(value);
            return null;
          }, []);
        } else {
          let totalAmountValue = formState.amount;
          if (totalAmountValue && taskKartToggle?.getTaskList?.fieldArea) {
            let formStateValue = formState.amount
              ? parseFloat(formState.amount) *
              (value === "kg"
                ? parseFloat(taskKartToggle?.getTaskList?.fieldArea)
                : 1 / parseFloat(taskKartToggle?.getTaskList?.fieldArea))
              : "";
            if (formStateValue) {
              totalAmountValue = formStateValue % 1 === 0 ? parseInt(formStateValue, 10) : parseFloat(formStateValue.toFixed(1));
            }
          }
          setFormState((prevState) => {
            const updatedFormState = {
              ...prevState,
              unit: value || null,
              //planAmount: planAmountValue,
              amount: totalAmountValue
            };
            initialStateRef.current = updatedFormState;
            return updatedFormState;
          });
          setSelectedPlanUnit(value);
        }
      } else {
        setFormState((prevState) => ({
          ...prevState,
          [fieldName]: value,
        }));
      }
    } else if (selectedActivity.id === 'act30') {
      const fieldManureSeasonMap = taskKartToggle?.getTaskList?.taskActivity?.fieldManureSeasonMap;
      const filteredData = fieldManureSeasonMap && fieldManureSeasonMap.filter(item => item.fieldId === selectedFields[0]?.fieldId[0])[0] && fieldManureSeasonMap.filter(item => item.fieldId === selectedFields[0]?.fieldId[0])[0].manureSeasonMap;

      const manureStorageList = manureStorage?.manureList;
      const manureName = manureStorageList && manureStorageList.find(item => item.id === value);
      if (filteredData && fieldName === 'subType') {
        const newPlanAmounts = selectedFields[0].fieldId.reduce((acc, field) => {
          const filteredData1 = fieldManureSeasonMap?.filter(item => item.fieldId === field);
          const manureSeasonMap = filteredData1?.[0]?.manureSeasonMap || [];
          let quantity = null;
          if (manureSeasonMap?.[formState.season]?.storageId === value) {
            quantity = manureSeasonMap?.[formState.season]?.quantity || null;
          }
          acc.push(formatNumber(quantity));
          return acc;
        }, []);

        const manureSeasonMap = filteredData[formState.season] || null;
        let planAmountValue = null;
        let totalAmountValue = null;
        if (manureSeasonMap && manureSeasonMap.storageId === value) {
          planAmountValue = manureSeasonMap.quantity || null;
          totalAmountValue = manureSeasonMap.totalQuantity || null;
        }
        setFormState((prevState) => {
          const updatedFormState = {
            ...prevState,
            subType: value || null,
            manureStorageId: value || null,
            manureStorageName: manureName ? manureName.name : null,
            planAmount: planAmountValue % 1 === 0 ? parseInt(planAmountValue, 10) : parseFloat(planAmountValue.toFixed(1)),
            planAmounts: newPlanAmounts,
            totalAmount: totalAmountValue % 1 === 0 ? parseInt(totalAmountValue, 10) : parseFloat(totalAmountValue.toFixed(1))
          };
          initialStateRef.current = updatedFormState;
          return updatedFormState;
        });
      } else if (filteredData && fieldName === 'season') {
        const manureSeasonMap = filteredData[value] || null;
        let greaterQuantity = 0;
        let selectedStorageId = null;

        selectedFields[0].fieldId.reduce((acc, field) => {
          const filteredData1 = fieldManureSeasonMap?.filter(item => item.fieldId === field);
          const manureSeasonMap = filteredData1?.[0]?.manureSeasonMap || [];
          if (manureSeasonMap?.[value]) {
            if (manureSeasonMap?.[value]?.quantity > greaterQuantity) {
              greaterQuantity = manureSeasonMap?.[value]?.quantity;
              selectedStorageId = manureSeasonMap?.[value]?.storageId;
            }
          }
          return null;
        }, []);

        const newPlanAmounts = selectedFields[0].fieldId.reduce((acc, field) => {
          const filteredData1 = fieldManureSeasonMap?.filter(item => item.fieldId === field);
          const manureSeasonMap = filteredData1?.[0]?.manureSeasonMap || [];
          let quantity = null;
          if (manureSeasonMap?.[value]?.storageId === selectedStorageId) {
            quantity = manureSeasonMap?.[value]?.quantity || null;
          }
          acc.push(formatNumber(quantity));
          return acc;
        }, []);

        let planAmountValue = null;
        let totalAmountValue = null;
        let selectedSubType = "";
        if (manureSeasonMap) {
          planAmountValue = manureSeasonMap.quantity || null;
          totalAmountValue = manureSeasonMap.totalQuantity || null;
          selectedSubType = manureSeasonMap.storageId || null;
        }
        if (selectedFields[0].fieldId.length > 1) {
          selectedSubType = selectedStorageId || null;
        }
        const manureName = manureStorageList && selectedSubType && manureStorageList.find(item => item.id === selectedSubType);
        setFormState((prevState) => {
          const updatedFormState = {
            ...prevState,
            season: value || null,
            planAmount: planAmountValue % 1 === 0 ? parseInt(planAmountValue, 10) : parseFloat(planAmountValue.toFixed(1)),
            planAmounts: newPlanAmounts,
            totalAmount: totalAmountValue % 1 === 0 ? parseInt(totalAmountValue, 10) : parseFloat(totalAmountValue.toFixed(1)),
            subType: selectedSubType,
            manureStorageId: selectedSubType || null,
            manureStorageName: manureName ? manureName.name : null,
          };
          initialStateRef.current = updatedFormState;
          return updatedFormState;
        });
      } else if (fieldName === 'unit') {
        if (selectedFields[0].fieldId.length > 1 && formState.perField) {
          selectedFields[0].fieldId.reduce((acc, field) => {
            const fieldKey = `amount_${field}`;
            let totalAmountValue = formState[fieldKey];
            const filteredData = taskKartToggle?.getTaskList?.fieldAreas.filter(item => item.fieldId === field);
            if (totalAmountValue && filteredData) {
              let formStateValue = formState[fieldKey]
                ? parseFloat(formState[fieldKey]) *
                (value === "m^3"
                  ? parseFloat(filteredData[0].area)
                  : 1 / parseFloat(filteredData[0].area))
                : "";
              if (formStateValue) {
                totalAmountValue = formStateValue % 1 === 0 ? parseInt(formStateValue, 10) : parseFloat(formStateValue.toFixed(1));
              }
            }
            setFormState((prevState) => {
              const updatedFormState = {
                ...prevState,
                unit: value || null,
                //planAmount: planAmountValue,
                [fieldKey]: totalAmountValue
              };
              initialStateRef.current = updatedFormState;
              return updatedFormState;
            });
            setSelectedPlanUnit(value);
            return null;
          }, []);
        } else {
          let totalAmountValue = formState.amount;
          if (totalAmountValue && taskKartToggle?.getTaskList?.fieldArea) {
            let formStateValue = formState.amount
              ? parseFloat(formState.amount) *
              (value === "m^3"
                ? parseFloat(taskKartToggle?.getTaskList?.fieldArea)
                : 1 / parseFloat(taskKartToggle?.getTaskList?.fieldArea))
              : "";
            if (formStateValue) {
              totalAmountValue = formStateValue % 1 === 0 ? parseInt(formStateValue, 10) : parseFloat(formStateValue.toFixed(1));
            }
          }
          setFormState((prevState) => {
            const updatedFormState = {
              ...prevState,
              unit: value || null,
              //planAmount: planAmountValue,
              amount: totalAmountValue
            };
            initialStateRef.current = updatedFormState;
            return updatedFormState;
          });
          setSelectedPlanUnit(value);
        }
      } else {
        setFormState((prevState) => ({
          ...prevState,
          [fieldName]: value,
        }));
      }
    } else if (selectedActivity.id === 'act31') {
      const fieldManureSeasonMap = taskKartToggle?.getTaskList?.taskActivity;
      if (fieldManureSeasonMap && fieldName === 'unit') {
        if (selectedFields[0].fieldId.length > 1 && formState.perField) {
          selectedFields[0].fieldId.reduce((acc, field) => {
            const fieldKey = `amount_${field}`;
            let totalAmountValue = formState[fieldKey];
            const filteredData = taskKartToggle?.getTaskList?.fieldAreas.filter(item => item.fieldId === field);
            if (totalAmountValue && filteredData) {
              let formStateValue = formState[fieldKey]
                ? parseFloat(formState[fieldKey]) *
                (value === "kg"
                  ? parseFloat(filteredData[0].area)
                  : 1 / parseFloat(filteredData[0].area))
                : "";
              if (formStateValue) {
                totalAmountValue = formStateValue % 1 === 0 ? parseInt(formStateValue, 10) : parseFloat(formStateValue.toFixed(1));
              }
            }
            setFormState((prevState) => {
              const updatedFormState = {
                ...prevState,
                unit: value || null,
                //planAmount: planAmountValue,
                [fieldKey]: totalAmountValue
              };
              initialStateRef.current = updatedFormState;
              return updatedFormState;
            });
            setSelectedPlanUnit(value);
            return null;
          }, []);
        } else {
          let totalAmountValue = formState.amount;
          if (totalAmountValue && taskKartToggle?.getTaskList?.fieldArea) {
            let formStateValue = formState.amount
              ? parseFloat(formState.amount) *
              (value === "kg"
                ? parseFloat(taskKartToggle?.getTaskList?.fieldArea)
                : 1 / parseFloat(taskKartToggle?.getTaskList?.fieldArea))
              : "";
            if (formStateValue) {
              totalAmountValue = formStateValue % 1 === 0 ? parseInt(formStateValue, 10) : parseFloat(formStateValue.toFixed(1));
            }
          }
          setFormState((prevState) => {
            const updatedFormState = {
              ...prevState,
              unit: value || null,
              //planAmount: planAmountValue,
              amount: totalAmountValue
            };
            initialStateRef.current = updatedFormState;
            return updatedFormState;
          });
          setSelectedPlanUnit(value);
        }
      } else {
        setFormState((prevState) => ({
          ...prevState,
          [fieldName]: value,
        }));
      }
    } else if (selectedActivity.id === 'act32') {
      const fieldManureSeasonMap = taskKartToggle?.getTaskList?.taskActivity;
      if (fieldManureSeasonMap && fieldName === 'unit') {
        if (selectedFields[0].fieldId.length > 1 && formState.perField) {
          selectedFields[0].fieldId.reduce((acc, field) => {
            const fieldKey = `amount_${field}`;
            let totalAmountValue = formState[fieldKey];
            const filteredData = taskKartToggle?.getTaskList?.fieldAreas.filter(item => item.fieldId === field);
            if (totalAmountValue && filteredData) {
              let formStateValue = formState[fieldKey]
                ? parseFloat(formState[fieldKey]) *
                (value === "ml"
                  ? parseFloat(filteredData[0].area)
                  : 1 / parseFloat(filteredData[0].area))
                : "";
              if (formStateValue) {
                totalAmountValue = formStateValue % 1 === 0 ? parseInt(formStateValue, 10) : parseFloat(formStateValue.toFixed(1));
              }
            }
            setFormState((prevState) => {
              const updatedFormState = {
                ...prevState,
                unit: value || null,
                //planAmount: planAmountValue,
                [fieldKey]: totalAmountValue
              };
              initialStateRef.current = updatedFormState;
              return updatedFormState;
            });
            setSelectedPlanUnit(value);
            return null;
          }, []);
        } else {
          let totalAmountValue = formState.amount;
          if (totalAmountValue && taskKartToggle?.getTaskList?.fieldArea) {
            let formStateValue = formState.amount
              ? parseFloat(formState.amount) *
              (value === "ml"
                ? parseFloat(taskKartToggle?.getTaskList?.fieldArea)
                : 1 / parseFloat(taskKartToggle?.getTaskList?.fieldArea))
              : "";
            if (formStateValue) {
              totalAmountValue = formStateValue % 1 === 0 ? parseInt(formStateValue, 10) : parseFloat(formStateValue.toFixed(1));
            }
          }
          setFormState((prevState) => {
            const updatedFormState = {
              ...prevState,
              unit: value || null,
              //planAmount: planAmountValue,
              amount: totalAmountValue
            };
            initialStateRef.current = updatedFormState;
            return updatedFormState;
          });
          setSelectedPlanUnit(value);
        }
      } else {
        setFormState((prevState) => ({
          ...prevState,
          [fieldName]: value,
        }));
      }
    } else {
      setFormState((prevState) => ({
        ...prevState,
        [fieldName]: value,
      }));
    }
  };

  const handleNumericValidation = (fieldName, value) => {
    if (/^\d*([.,]?\d*)?$/.test(value)) {
      setFormState((prevState) => ({
        ...prevState,
        [fieldName]: value,
      }));
      setFormErrors((prevErrors) => {
        const newErrors = { ...prevErrors };
        delete newErrors[fieldName];
        return newErrors;
      });
    } else {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        [fieldName]: intl.formatMessage({ id: 'DYNAMIC_SECTION_AMOUNT_VAL_MSG' }),
      }));
    }
  };

  const handleSave = () => {
    setModalOpen(false);
    if (formErrors && Object.values(formErrors).length === 0) {
      saveNewTask(formState);
    }
  };

  const handleContinueSave = () => {
    setModalOpen(false);
  };

  const handleCancel = () => {
    let isChanged = JSON.stringify(initialStateRef.current) !== JSON.stringify(formState);
    if (isChanged) {
      setModalOpen(true);
    } else {
      handleCancelButton();
    }
  };

  const handleCloseField = (field) => {
    handleRemoveFieldId(field);
  };

  const handleCancelButton = () => {
    setModalOpen(false);
    closeNewTask();
  };

  const onCloseVehicleDialog = () => {
    setShowAddVehicleDialog(false);
  }

  const onCloseEquipmentDialog = () => {
    setShowAddEquipmentDialog(false);
  }
  return (
    <Box sx={{
      padding: '0px 16px 0px 16px',
      overflowY: 'auto',
      '&::-webkit-scrollbar': {
        width: '5px',
        backgroundColor: 'rgba(0, 0, 0, 0.1)',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#A8A8A8',
        borderRadius: '4px',
        transition: 'background-color 0.3s ease',
      },
      '&::-webkit-scrollbar-thumb:hover': {
        backgroundColor: '#B8B8B8',
      }
    }}
    >
      <Box style={taskTemplateStyle.headerSticky}>
        <Box style={taskTemplateStyle.modalDialogHeader}>
          <Box style={taskTemplateStyle.modalDialogHeader1}>
            <Typography sx={{ paddingTop: '5px', paddingRight: '10px' }}>
              <CloseIcon
                width="24"
                height="24"
                onClick={handleCancel}
                style={{ cursor: 'pointer' }}
              />
            </Typography>
            <Typography style={taskTemplateStyle.modalDialogTitle}>{selectedActivity?.activityName}</Typography>
          </Box>
          <Box sx={{ paddingRight: '0px' }}>
            <MuiChipMobileDropdown
              defaultLabel={intl.formatMessage({ id: 'CHOOSE_FERTILIZATION' })}
              textLabel={intl.formatMessage({ id: 'TASK_SEASON' })}
              defaultValue={formState.season}
              options={seasonOptions()}
              optionStyle={taskTemplateStyle.menuOptionMobileTextBig}
              dropdownStyle={taskTemplateStyle.chipDropdownStyleBig}
              fieldName='season'
              onChange={handleFieldChange}
            />
          </Box>
        </Box>
        <Box style={taskTemplateStyle.modalDialogSubHeader}>
          <Typography style={taskTemplateStyle.modalDialogSubTitle}>{selectedFields[0].fieldName.join(", ")}</Typography>
        </Box>
      </Box>
      <Box>
        <Box sx={{ display: "flex", alignItems: "center", paddingTop: '28px' }}>
          <MuiDatePicker
            defaultLabel={intl.formatMessage({ id: 'FIELD_WEATHER_DATE' })}
            dateFormat={"dd/MM/yyyy"}
            fieldName='startTime'
            onChange={handleFieldChange}
            maxDateOption={true}
          />
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", paddingTop: '24px' }}>
          <MuiSelectMobileDropdown
            defaultLabel={selectedActivity?.id === 'act30' ? intl.formatMessage({ id: 'TASK_REGISTRATION_STORAGE' }) : intl.formatMessage({ id: 'TYPE' })}
            defaultValue={formState.subType}
            options={subTypeOptions}
            fieldName="subType"
            onChange={handleFieldChange}
            borderLine={true}
            defaultOptionsLength={getModifiedMineralFertilizerOptions()}
          />
        </Box>
        {selectedFields[0].fieldId.length > 1 &&
          <Box sx={{ display: "flex", alignItems: "center", paddingTop: '44px', paddingBottom: '8px' }}>
            <FormControlLabel
              label={
                <Box sx={{ pointerEvents: 'none' }}>
                  <Typography sx={{
                    color: 'var(--M3-sys-light-on-surface, #191C1C)',
                    fontFamily: 'Inter',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontWeight: '400',
                    lineHeight: '24px',
                    letterSpacing: '0.5px',
                    paddingRight: '16px'
                  }}>
                    {intl.formatMessage({ id: 'PER_SKIFTE' })}
                  </Typography>
                </Box>
              }
              sx={{
                display: "flex",
                width: "100%",
                flexDirection: "row-reverse", // Swap label and toggle positions
                marginRight: '6px !important'
              }}
              control={
                <ToggleMF
                  sx={{ m: 0 }}
                  checked={isPerField}
                  onChange={(e) => {
                    e.stopPropagation(); // Prevent row click
                    handleToggleChange();
                  }}
                />
              }
            />
          </Box>}
        {(selectedFields[0].fieldId.length > 1 && isPerField) ? (
          selectedFields[0].fieldId.map((field, index) => {
            const fieldKey = `amount_${field}`;
            return (
              <Box key={field} sx={{ display: "flex", alignItems: "center", paddingTop: '24px', gap: 2 }}>
                {/* X Mark */}
                <Typography sx={{ paddingTop: '12px', width: 48, height: 48 }}>
                  <CloseIcon
                    width="24"
                    height="24"
                    style={{ cursor: 'pointer' }}
                    onClick={() => handleCloseField(field)}
                  />
                </Typography>

                {/* Label */}
                <Typography
                  title={selectedFields[0].fieldName[index]}
                  sx={{
                    color: 'var(--M3-sys-light-on-surface, #191C1C)',
                    fontFamily: 'Inter',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontWeight: '400',
                    lineHeight: '24px',
                    letterSpacing: '0.5px',
                    paddingRight: '8px',
                    width: '180px',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap'
                  }}
                >
                  {selectedFields[0].fieldName[index]}
                </Typography>

                {/* TextField */}
                <MuiTextField
                  key={field}
                  defaultLabel={intl.formatMessage({ id: 'ACTUAL_AMOUNT_KART' })}
                  defaultValue={formState[fieldKey] || ''}  // Use computed key
                  fieldName={fieldKey}
                  onChange={handleNumericValidation}  // Pass value correctly
                  errorHelperText={formErrors[fieldKey]}
                  numberKeypad={true}
                  inputAdornment={formState.unit}
                  helperText={
                    `Planlagt: ${formState.planAmounts[index] && formState.planAmounts[index] !== '-' ?
                      `${formState.planAmounts[index]} ${{
                        act7: 'kg/daa',
                        act30: 'm^3/daa',
                        act31: 'kg/daa',
                        act32: 'ml/daa'
                      }[selectedActivity?.id] || ''
                      }`
                      : '-'}`
                  }
                />
              </Box>
            );
          })
        ) : (
          <Box sx={{ display: "flex", alignItems: "center", paddingTop: '24px' }}>
            <MuiTextField
              defaultLabel={intl.formatMessage({ id: 'ACTUAL_AMOUNT_KART' })}
              defaultValue={formState.amount || ''}
              fieldName="amount"
              onChange={handleNumericValidation}
              autoFocus={true}
              errorHelperText={formErrors['amount']}
              numberKeypad={true}
              inputAdornment={formState.unit}
              helperText={
                selectedFields[0].fieldId.length > 1 ?
                  `Planlagt: -`
                  :
                  (`Planlagt: ${formState.planAmount ?
                    `${formState.planAmount} ${{
                      act7: 'kg/daa',
                      act30: 'm^3/daa',
                      act31: 'kg/daa',
                      act32: 'ml/daa'
                    }[selectedActivity?.id] || ''
                    }`
                    : '-'}`)
              }
            />
          </Box>
        )}
        {/*<Box sx={{ display: "flex", alignItems: "center", paddingTop: '24px' }}>
          <MuiSelectMobileDropdown
            defaultLabel={intl.formatMessage({ id: 'CT_FERTILIZERTYPE' })}
            options={fertilizerTypeOptions()}
            fieldName="type"
            onChange={handleFieldDropdownChange}
            textLabel={intl.formatMessage({ id: 'CT_FERTILIZERTYPE' })}
            required={false}
            errorMessage={intl.formatMessage({ id: 'required' })}
          />
        </Box>*/}

        <Box sx={{ display: "flex", alignItems: "center", paddingTop: '56px' }}>
          <MuiTextFieldArea
            defaultLabel={intl.formatMessage({ id: 'NOTE' })}
            fieldName='note'
            onChange={handleFieldChange}
          />
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", paddingTop: '40px' }}>
          <MuiCollapseComponent
            title={intl.formatMessage({ id: 'TASK_MORE_INFORMATION' })}
            content={
              <Box sx={{ paddingTop: '48px', width: '100%' }}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <MuiSelectMobileDropdown
                    defaultLabel={intl.formatMessage({ id: 'ACTUAL_UNIT' })}
                    defaultValue={selectedPlanUnit}
                    options={unitOptions}
                    fieldName="unit"
                    onChange={handleFieldChange}
                    deSelect={false}
                    textLabel={intl.formatMessage({ id: 'ACTUAL_UNIT' })}
                  />
                </Box>
                <Box sx={{ display: "flex", alignItems: "center", paddingTop: '24px' }}>
                  {selectedFields[0].fieldId.length > 1 ?
                    <MuiTextField
                      defaultLabel={intl.formatMessage({ id: 'PLANNED' })}
                      defaultValue={''}
                      fieldName='planAmount'
                      onChange={handleNumericValidation}
                      autoFocus={false}
                      errorHelperText={formErrors['planAmount']}
                      inputAdornment={formState.planUnit}
                      disabled={true}
                      helperText={'Total Planlagt: -'}
                    /> :
                    <MuiTextField
                      defaultLabel={intl.formatMessage({ id: 'PLANNED' })}
                      defaultValue={formState.planAmount || ''}
                      fieldName='planAmount'
                      onChange={handleNumericValidation}
                      autoFocus={false}
                      errorHelperText={formErrors['planAmount']}
                      inputAdornment={formState.planUnit}
                      disabled={true}
                      helperText={
                        `Total Planlagt: ${formState.totalAmount ?
                          `${formState.totalAmount} ${{
                            act7: 'kg',
                            act30: 'm^3',
                            act31: 'kg',
                            act32: 'ml'
                          }[selectedActivity?.id] || ''
                          }`
                          : '-'}`
                      }
                    />}
                </Box>
                {/*<Box sx={{ display: "flex", alignItems: "center", paddingTop: '24px' }}>
                  <MuiSelectMobileDropdown
                    defaultLabel={intl.formatMessage({ id: 'CT_UNIT' })}
                    defaultValue={selectedPlanUnit}
                    options={unitOptions}
                    fieldName="planUnit"
                    onChange={handleFieldChange}
                    textLabel={intl.formatMessage({ id: 'CT_UNIT' })}
                    deSelect={false}
                  />
                </Box>*/}
                <Box sx={{ display: "flex", alignItems: "center", paddingTop: '56px' }}>
                  <MuiSelectMobileDropdown
                    key={createdVehicleId}
                    defaultLabel={intl.formatMessage({ id: 'VEHICLE' })}
                    defaultValue={createdVehicleId}
                    options={vehicleOptions()}
                    fieldName="vehicleAssetId"
                    onChange={handleFieldChange}
                    textLabel={intl.formatMessage({ id: 'VEHICLE' })}
                  />
                </Box>
                <Box sx={{ display: "flex", alignItems: "center", paddingTop: '24px' }}>
                  <MuiSelectMobileDropdown
                    key={createdEquipmentId}
                    defaultLabel={intl.formatMessage({ id: 'EQUIPMENT' })}
                    defaultValue={createdEquipmentId}
                    options={equipmentOptions()}
                    fieldName="equipmentAssetId"
                    onChange={handleFieldChange}
                    textLabel={intl.formatMessage({ id: 'EQUIPMENT' })}
                  />
                </Box>
              </Box>
            }
          />
        </Box>
      </Box>
      <Box style={taskTemplateStyle.buttonBox}>
        <Box style={taskTemplateStyle.buttonBoxBottom}>
          <Chip
            label={intl.formatMessage({ id: 'SAVE' })}
            clickable
            style={taskTemplateStyle.okButtonStyle}
            sx={{
              backgroundColor: 'transparent',
              '&:hover, &:focus': {
                backgroundColor: 'transparent',
              }
            }}
            disableRipple
            onClick={handleSave}
          />
        </Box>
      </Box>
      {/* Modal */}
      <Box>
        <Dialog onClose={() => { }} open={isModalOpen} PaperProps={{
          style: {
            backgroundColor: '#E6E9E8',
            borderRadius: "28px",
            width: "312px",
            minWidth: "280px",
            maxWidth: "312px",
            maxHeight: '30vh'
          }
        }}>
          <Box style={taskTemplateStyle.cancelModalBox}>
            <Typography style={taskTemplateStyle.cancelModalTitle}>{intl.formatMessage({ id: 'TASK_CANCEL_MODAL_TITLE' })}</Typography>
            <Typography style={taskTemplateStyle.cancelModalSubTitle}>{intl.formatMessage({ id: 'TASK_CANCEL_MODAL_SUB_TITLE' })}</Typography>
          </Box>
          <Box style={taskTemplateStyle.cancelModalBoxButton}>
            <Chip
              label={intl.formatMessage({ id: 'cancel' })}
              clickable
              style={taskTemplateStyle.cancelButtonStyle}
              sx={{
                backgroundColor: 'transparent',
                '&:hover, &:focus': {
                  backgroundColor: 'transparent',
                }
              }}
              disableRipple
              onClick={handleCancelButton}
            />
            <Chip
              label={intl.formatMessage({ id: 'TASK_CONTINUE_REGISTRATION' })}
              clickable
              style={taskTemplateStyle.cancelButtonStyle}
              sx={{
                backgroundColor: 'transparent',
                '&:hover, &:focus': {
                  backgroundColor: 'transparent',
                }
              }}
              disableRipple
              onClick={handleContinueSave}
            />
          </Box>
        </Dialog>
      </Box>
      <AddVehicleModal
        intl={intl}
        vehicleSelectedId={'new'}
        onCloseVehicleDialog={onCloseVehicleDialog}
        showAddVehicleDialog={showAddVehicleDialog}
        assetType={'vehicle'}
        taskDetails={taskDetails}
      />
      <AddEquipmentModal
        intl={intl}
        equipmentSelectedId={'new'}
        onCloseEquipmentDialog={onCloseEquipmentDialog}
        showAddEquipmentDialog={showAddEquipmentDialog}
        assetType={'equipment'}
        taskDetails={taskDetails}
      />
    </Box>
  );
};

export default AddTaskTemplate7;