import * as React from 'react';
import { Dialog, Box } from '@mui/material';
import AddTaskTemplate1 from 'mui-mobile-components/kart/fieldmap/tasktemplates/AddTaskTemplate1';
import AddTaskTemplate2 from 'mui-mobile-components/kart/fieldmap/tasktemplates/AddTaskTemplate2';
import AddTaskTemplate3 from 'mui-mobile-components/kart/fieldmap/tasktemplates/AddTaskTemplate3';
import AddTaskTemplate4 from 'mui-mobile-components/kart/fieldmap/tasktemplates/AddTaskTemplate4';
import AddTaskTemplate5 from 'mui-mobile-components/kart/fieldmap/tasktemplates/AddTaskTemplate5';
import AddTaskTemplate6 from 'mui-mobile-components/kart/fieldmap/tasktemplates/AddTaskTemplate6';
import AddTaskTemplate7 from 'mui-mobile-components/kart/fieldmap/tasktemplates/AddTaskTemplate7';
import AddTaskTemplate8 from 'mui-mobile-components/kart/fieldmap/tasktemplates/AddTaskTemplate8';
import AddTaskTemplate from 'mui-mobile-components/kart/fieldmap/tasktemplates/AddTaskTemplate';

const AddTaskModal = (props) => {
  const {intl, activeFarm, onCreateTask, selectedFields, selectedActivity, locations, taskDetails, closeNewTask, saveNewTask,
    assetsDetails, createdVehicleId, createdEquipmentId, seasons, activitiesCommon, manureStorage, taskKartToggle, handleRemoveFieldId, ensilationList
  } = props;
  return (
    <Box>    
      <Dialog
        onClose={closeNewTask}
        open={onCreateTask}
        disablePortal
        disableScrollLock
        disableEnforceFocus
        PaperProps={{
          style: {
            backgroundColor: '#E6E9E8',
            width: '100%',
            minWidth: '100%',
            maxWidth: '100%',
            maxHeight: '100vh',
            overflow: 'hidden',
            height: '100vh'
          },
        }}
      >
        {(selectedActivity.id === 'act11' || selectedActivity.id === 'act12' || selectedActivity.id === 'act16') &&
        <AddTaskTemplate1
          intl={intl}
          activeFarm={activeFarm}
          selectedFields={selectedFields}
          selectedActivity={selectedActivity}
          locations={locations}
          taskDetails={taskDetails}
          closeNewTask={closeNewTask}
          saveNewTask={saveNewTask}
          assetsDetails={assetsDetails}
          createdVehicleId={createdVehicleId}
          createdEquipmentId={createdEquipmentId}
          taskKartToggle={taskKartToggle}
          handleRemoveFieldId={handleRemoveFieldId}
          ensilationList={ensilationList}
        />}
        {(selectedActivity.id === 'act9' || selectedActivity.id === 'act10' || selectedActivity.id === 'act28') &&
        <AddTaskTemplate2
          intl={intl}
          activeFarm={activeFarm}
          selectedFields={selectedFields}
          selectedActivity={selectedActivity}
          locations={locations}
          taskDetails={taskDetails}
          closeNewTask={closeNewTask}
          saveNewTask={saveNewTask}
          assetsDetails={assetsDetails}
          createdVehicleId={createdVehicleId}
          createdEquipmentId={createdEquipmentId}
          taskKartToggle={taskKartToggle}
          handleRemoveFieldId={handleRemoveFieldId}
          ensilationList={ensilationList}
        />}
        {(selectedActivity.id === 'act5' || selectedActivity.id === 'act14' || selectedActivity.id === 'act15' || selectedActivity.id === 'act17' || 
        selectedActivity.id === 'act18') &&
        <AddTaskTemplate3
          intl={intl}
          activeFarm={activeFarm}
          selectedFields={selectedFields}
          selectedActivity={selectedActivity}
          locations={locations}
          taskDetails={taskDetails}
          closeNewTask={closeNewTask}
          saveNewTask={saveNewTask}
          assetsDetails={assetsDetails}
          createdVehicleId={createdVehicleId}
          createdEquipmentId={createdEquipmentId}
          taskKartToggle={taskKartToggle}
        />}
        {selectedActivity.id === 'act13' &&
        <AddTaskTemplate4
          intl={intl}
          activeFarm={activeFarm}
          selectedFields={selectedFields}
          selectedActivity={selectedActivity}
          locations={locations}
          taskDetails={taskDetails}
          closeNewTask={closeNewTask}
          saveNewTask={saveNewTask}
          assetsDetails={assetsDetails}
          createdVehicleId={createdVehicleId}
          createdEquipmentId={createdEquipmentId}
          taskKartToggle={taskKartToggle}
        />}
        {selectedActivity.id === 'act1' &&
        <AddTaskTemplate5
          intl={intl}
          activeFarm={activeFarm}
          selectedFields={selectedFields}
          selectedActivity={selectedActivity}
          locations={locations}
          taskDetails={taskDetails}
          closeNewTask={closeNewTask}
          saveNewTask={saveNewTask}
          assetsDetails={assetsDetails}
          createdVehicleId={createdVehicleId}
          createdEquipmentId={createdEquipmentId}
          activitiesCommon={activitiesCommon}
          taskKartToggle={taskKartToggle}
        />}
        {selectedActivity.id === 'act20' &&
        <AddTaskTemplate6
          intl={intl}
          activeFarm={activeFarm}
          selectedFields={selectedFields}
          selectedActivity={selectedActivity}
          locations={locations}
          taskDetails={taskDetails}
          closeNewTask={closeNewTask}
          saveNewTask={saveNewTask}
          assetsDetails={assetsDetails}
          createdVehicleId={createdVehicleId}
          createdEquipmentId={createdEquipmentId}
          activitiesCommon={activitiesCommon}
          taskKartToggle={taskKartToggle}
          handleRemoveFieldId={handleRemoveFieldId}
        />}
        {(selectedActivity.id === 'act7' || selectedActivity.id === 'act30' || selectedActivity.id === 'act31' || 
          selectedActivity.id === 'act32'
        ) &&
        <AddTaskTemplate7
          intl={intl}
          activeFarm={activeFarm}
          selectedFields={selectedFields}
          selectedActivity={selectedActivity}
          locations={locations}
          taskDetails={taskDetails}
          closeNewTask={closeNewTask}
          saveNewTask={saveNewTask}
          assetsDetails={assetsDetails}
          createdVehicleId={createdVehicleId}
          createdEquipmentId={createdEquipmentId}
          seasons={seasons}
          activitiesCommon={activitiesCommon}
          manureStorage={manureStorage}
          taskKartToggle={taskKartToggle}
          handleRemoveFieldId={handleRemoveFieldId}
        />}
        {(selectedActivity.id === 'act2' || selectedActivity.id === 'act4' || selectedActivity.id === 'act8' || selectedActivity.id === 'act21' || selectedActivity.id === 'act22' || 
        selectedActivity.id === 'act23' || selectedActivity.id === 'act24' || selectedActivity.id === 'act25' || selectedActivity.id === 'act26' || selectedActivity.id === 'act27' || selectedActivity.id === 'act29') &&
        <AddTaskTemplate8
          intl={intl}
          activeFarm={activeFarm}
          selectedFields={selectedFields}
          selectedActivity={selectedActivity}
          locations={locations}
          taskDetails={taskDetails}
          closeNewTask={closeNewTask}
          saveNewTask={saveNewTask}
          assetsDetails={assetsDetails}
          createdVehicleId={createdVehicleId}
          createdEquipmentId={createdEquipmentId}
          taskKartToggle={taskKartToggle}
        />}
        {selectedActivity.id === 'act19' &&
        <AddTaskTemplate
          intl={intl}
          activeFarm={activeFarm}
          selectedFields={selectedFields}
          selectedActivity={selectedActivity}
          locations={locations}
          taskDetails={taskDetails}
          closeNewTask={closeNewTask}
          saveNewTask={saveNewTask}
          assetsDetails={assetsDetails}
          createdVehicleId={createdVehicleId}
          createdEquipmentId={createdEquipmentId}
        />}
      </Dialog>
    </Box>
  );
};

export default AddTaskModal;