import * as React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { injectIntl } from 'react-intl';
import { withRouter, Redirect } from 'react-router-dom';
import _ from 'lodash';

import SectionContainer from '@tine/lib-frontend-components/components/alignment/section-container';
import IconButton from '@tine/lib-frontend-components/components/buttons/icon-button';
import { TrashcanIcon } from '@tine/lib-frontend-components/elements/icons';
import EmphasisLight from '@tine/lib-frontend-components/elements/typography/text/emphasis-light';
import MetaInformation from '@tine/lib-frontend-components/elements/typography/text/meta-information';
import ActionButton from '@tine/lib-frontend-components/components/buttons/action-button';
import ConfirmDialog from '@tine/lib-frontend-components/components/dialogs/confirm-dialog';
import ErrorText from '@tine/lib-frontend-components/components/errors/error-text';
import RadioButton from '@tine/lib-frontend-components/components/inputs/radio-button';

import Header from 'mui-mobile-components/header';
import TextBoxControl from 'components/forms/TextBoxControl';
import ConfirmDialogBox from 'components/ConfirmDialogBox';
import Loader from 'components/Loader';
import SelectControl from 'components/forms/SelectBoxControl';
import * as ManureAction from 'actions/ManureStorage';
import { CheckInteger, DecimalValidation } from 'utils/index';
import { REDIRECT, OWNER, ADMIN, ADVISOR } from 'constants/index';
import { Box, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import ManureStorageModalWindow from '../../../mui-components/storages/manurestorage/ManureStorageModalWindow';

class ManureStorageForm extends React.Component {
  constructor(props) {
    super(props);
    this.closeDialogWindowManureStorage = this.closeDialogWindowManureStorage.bind(this);
  }
  state = {
    inEditMode: false,
    showDeleteWarning: false,
    showChangeWarning: false,
    acceptChanges: false,
    isTypeChange: false,
    dialogValue: null,
    showDeleteErrorMessage: false,
    showQuestion1: false,
    showQuestion2: false,
    selectionQuestion1: null,
    selectionQuestion2: null,
    openDialogWindowManureStorage: false,
    disableCreateButton: true,
  };

  componentDidMount = () => {
    const { fetchManureType, manureStorage, activeFarm } = this.props;
    const { manureType } = manureStorage;
    if (!manureType?.length > 0) {
      fetchManureType(activeFarm.farm.id);
    }
    this.getInitialize();
  };

  componentDidUpdate(prevProps) {
    const { history, activeFarm, manureStorage } = this.props;
    const { manureList } = manureStorage;
    if (!activeFarm) {
      history.push('/settings');
    }
    if (activeFarm !== prevProps.activeFarm) {
      history.push('/storage/manure-storage/');
    }
    if (manureList !== prevProps.manureStorage.manureList) {
      this.getInitialize();
    }
  }

  getInitialize = () => {
    const { match, manureStorage, initialize, history } = this.props;
    const { manureList } = manureStorage;
    const manureId = (manureStorage.manureNewId && manureStorage.manureNewId !== null) ? manureStorage.manureNewId : match.params.id;

    if (manureId !== 'create') {
      const manureItem = _.filter(manureList, ['id', manureId])[0];
      if (manureItem) {
        let lagerTypeValue = null;
        if (manureItem.option === "GJØDSELKUM") {
          lagerTypeValue = { label: "Gjødselkum", value: "GJØDSELKUM" };
          this.setState({
            showQuestion1: true,
            showQuestion2: false,
            selectionQuestion1: manureItem.roofOntheManure,
            selectionQuestion2: manureItem.satelliteStorage
          });
        } else if (manureItem.option === "GJØDSELKJELLER") {
          lagerTypeValue = { label: "Gjødselkjeller", value: "GJØDSELKJELLER" };
          this.setState({
            showQuestion1: false,
            showQuestion2: true,
            selectionQuestion1: null,
            selectionQuestion2: manureItem.satelliteStorage
          });
        } else if (manureItem.option === "TALLE") {
          lagerTypeValue = { label: "Talle", value: "TALLE" };
          this.setState({
            showQuestion1: false,
            showQuestion2: true,
            selectionQuestion1: null,
            selectionQuestion2: manureItem.satelliteStorage
          });
        } else {
          lagerTypeValue = { label: "", value: "" };
          this.setState({
            showQuestion1: false,
            showQuestion2: false,
            selectionQuestion1: null,
            selectionQuestion2: null
          });
        }
        const formValues = {
          id: manureItem.id,
          name: manureItem.name,
          type: this.getType(manureItem.type),
          yearlyProduction: manureItem.yearlyProduction,
          storageCapacity: manureItem.storageCapacity,
          dryMatter: manureItem.dryMatter,
          nitrogen: manureItem.nitrogen,
          ammonium: manureItem.ammonium,
          phosphorus: manureItem.phosphorus,
          potassium: manureItem.potassium,
          sulfur: manureItem.sulfur,
          lagerType: lagerTypeValue
        };
        initialize(formValues);
      } else {
        history.push('/storage/manure-storage/');
      }
    }
  };

  getType = manureValue => {
    const {
      manureStorage: { manureType }
    } = this.props;
    if (manureValue) {
      const typeName = _.filter(manureType, ['id', manureValue])[0];
      return { value: typeName.id, label: typeName.type };
    }
    return '';
  };

  handleSubmit = values => {
    const { activeFarm, createManure, match, updateManure, manureStorage } = this.props;
    const manureId = (manureStorage.manureNewId && manureStorage.manureNewId !== null) ? manureStorage.manureNewId : match.params.id;
    const manureValues = { ...values };
    manureValues.type = values.type?.value;
    if (values.nitrogen) {
      manureValues.nitrogen = parseFloat(values.nitrogen.toString().replace(',', '.'));
    }
    if (values.ammonium) {
      manureValues.ammonium = parseFloat(values.ammonium.toString().replace(',', '.'));
    }
    if (values.phosphorus) {
      manureValues.phosphorus = parseFloat(values.phosphorus.toString().replace(',', '.'));
    }
    if (values.potassium) {
      manureValues.potassium = parseFloat(values.potassium.toString().replace(',', '.'));
    }
    if (values.sulfur) {
      manureValues.sulfur = parseFloat(values.sulfur.toString().replace(',', '.'));
    }
    if (values.lagerType) {
      if (values.lagerType.value === '') {
        manureValues.option = null;
        manureValues.roofOntheManure = null;
        manureValues.satelliteStorage = null;
      } else if (values.lagerType.value === "GJØDSELKUM") {
        manureValues.option = values.lagerType.value
        manureValues.roofOntheManure = this.state.selectionQuestion1;
        manureValues.satelliteStorage = this.state.selectionQuestion2;
      } else {
        manureValues.option = values.lagerType.value;
        manureValues.roofOntheManure = null;
        manureValues.satelliteStorage = this.state.selectionQuestion2;
      }
    }
    manureValues.id = manureId;
    if (manureId !== 'create') {
      updateManure(activeFarm.farm.id, manureValues, REDIRECT);
    } else {
      createManure(activeFarm.farm.id, manureValues);
    }
  };

  // to delete Manure
  deleteAction = action => {
    const { activeFarm, match, deleteManureList, manureStorage } = this.props;
    if (action) {
      const manureId = (manureStorage.manureNewId && manureStorage.manureNewId !== null) ? manureStorage.manureNewId : match.params.id;
      deleteManureList(activeFarm.farm.id, manureId);
      this.setState(prevState => ({
        showDeleteWarning: !prevState.showDeleteWarning
      }));
      this.setState({ showDeleteErrorMessage: true });
    } else {
      this.setState(prevState => ({
        showDeleteWarning: !prevState.showDeleteWarning
      }));
    }
  };

  getAddManureButton = () => {
    const { intl } = this.props;
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '100%',
          padding: '2px 8px',
          cursor: 'pointer',
          fontSize: '14px',
          height: 'auto',
        }}
        onClick={() => this.setShowAddManureDialog(true)}
      >
        <Box sx={{ padding: '5px 15px 0px 0px' }}>
          <AddIcon />
        </Box>
        <Typography sx={{ fontSize: '16px', fontWeight: 500, color: '#333', marginLeft: '6px' }}>
          {intl.formatMessage({ id: 'ADD_CUSTOM_MANURE' })}
        </Typography>
      </Box>
    );
  };

  setShowAddManureDialog = (value) => {
    this.setState({ openDialogWindowManureStorage: value });
  };

  closeDialogWindowManureStorage = () => {
    const { initialize, manureStorage } = this.props;
    const formValues = {
      type: null
    };
    manureStorage.hasManureTypeError = false;
    initialize(formValues);
    this.setShowAddManureDialog(false);
  };

  getManureOption = manureType => {
    const addNewManureComponent = {
      label: this.getAddManureButton(),
      value: "add-new-manure",
    };

    if (manureType?.length > 0) {
      return [addNewManureComponent, ...manureType.map(item => ({
        label: item.type,
        value: item.id
      }))];
    }

    return [addNewManureComponent];
  };


  getManureTypeOption = () => {
    return [
      { label: "", value: "" },
      { label: "Gjødselkum", value: "GJØDSELKUM" },
      { label: "Gjødselkjeller", value: "GJØDSELKJELLER" },
      { label: "Talle", value: "TALLE" }
    ]
  };

  onLagerTypeChange = e => {
    if (e.value === "") {
      this.setState({
        showQuestion1: false,
        showQuestion2: false,
        selectionQuestion1: null,
        selectionQuestion2: null
      });
    } else if (e.value === "GJØDSELKUM") {
      this.setState({
        showQuestion1: true,
        showQuestion2: false,
        selectionQuestion1: null,
        selectionQuestion2: null
      });
    } else {
      this.setState({
        showQuestion1: false,
        showQuestion2: true,
        selectionQuestion1: null,
        selectionQuestion2: null
      });
    }
  }

  onDryMatterChange = _.debounce(e => this.onTypeDryMatterChange(e), 1500);

  onTypeDryMatterChange = e => {
    const { match } = this.props;
    const manureId = match.params.id;
    if (manureId !== 'create') {
      let targetValue = null;
      if (e.value) {
        this.setState({
          isTypeChange: true,
          dialogValue: 'type'
        });
        const timeOut = setTimeout(() => this.setState({
          showChangeWarning: true
        }), 1000);
        return () => clearTimeout(timeOut);
      }
      if (e.target?.name === 'dryMatter') {
        targetValue = parseFloat(e.target.value.toString().replace(',', '.'));
      }
      if (e.target?.name === 'dryMatter' && targetValue > 0 && targetValue < 101) {
        this.setState({
          isTypeChange: false,
          dialogValue: 'drymatter'
        });
        const timeOut = setTimeout(() => this.setState({
          showChangeWarning: true
        }), 1500);
        return () => clearTimeout(timeOut);
      }
    }
  };

  updateOnChange = changedValue => {
    const { activeFarm, manureStoregeForm, updateManure } = this.props;
    const manureValues = { ...manureStoregeForm.values };
    if (changedValue?.value) {
      manureValues.type = changedValue.value;
    } else if (changedValue) {
      changedValue = changedValue.toString().replace(',', '.');
      manureValues.type = manureStoregeForm.values.type?.value;
      manureValues.dryMatter = changedValue;
    } else {
      manureValues.type = manureStoregeForm.values.type?.value;
      manureValues.dryMatter = manureStoregeForm.values.dryMatter ? parseFloat(manureStoregeForm.values.dryMatter.toString().replace(',', '.')) : '';
    }
    const timeOut = setTimeout(() => updateManure(activeFarm.farm.id, manureValues), 1500);
    return () => clearTimeout(timeOut);
  };

  //  action from confirmation
  warningAction = action => {
    const { manureStoregeForm, change } = this.props;
    const { isTypeChange } = this.state;

    if (action) {
      this.setState(prevState => ({
        showChangeWarning: !prevState.showChangeWarning,
        acceptChanges: false
      }));
      this.updateOnChange();
    } else {
      if (isTypeChange) {
        change('type', manureStoregeForm.initial.type);
      } else {
        change('dryMatter', manureStoregeForm.initial.dryMatter);
      }
      this.setState(prevState => ({
        showChangeWarning: !prevState.showChangeWarning,
        acceptChanges: false
      }));
    }
  };

  // go to manure page
  goToManurePage = () => {
    const { history } = this.props;
    history.push('/storage/manure-storage/');
  }

  type1Change = (optionValue) => {
    if (optionValue) {
      this.setState({
        selectionQuestion1: true
      });
    } else {
      this.setState({
        selectionQuestion1: false
      });
    }
  }

  type2Change = (optionValue) => {
    if (optionValue) {
      this.setState({
        selectionQuestion2: true
      });
    } else {
      this.setState({
        selectionQuestion2: false
      });
    }
  }

  render() {
    const {
      handleSubmit,
      pageContent: { labels },
      match,
      intl,
      manureStorage,
      manureStoregeForm,
      activeFarm,
      hasSupportAdminRole
    } = this.props;
    const { inEditMode, showDeleteWarning, showChangeWarning, dialogValue, showDeleteErrorMessage } = this.state;
    const manureId = match.params.id;
    const isCreate = manureId === 'create';
    const { isLoading, manureType, submitSuccess, hasError, errorMsg, errorPattern } = manureStorage;
    const formValue = manureStoregeForm !== undefined ? manureStoregeForm.values : null;
    const customizedDelString = `${intl.formatMessage({ id: 'ASSET_DELETE_TEXT_MSG' })}`;
    const hasPermission = activeFarm.role ? activeFarm.role.id === OWNER || activeFarm.role.id === ADMIN || activeFarm.role.id === ADVISOR : hasSupportAdminRole.data;
    return (
      <div className="manure-storage-form">
        <Header
          bcLink={{ to: '/storage/manure-storage', text: intl.formatMessage({ id: 'STORAGE' }) }}
          bcTitle={
            isCreate ? intl.formatMessage({ id: 'create' }) : manureStoregeForm?.values?.name
          }
        />
        <SectionContainer
          styling={{
            paddingTop: 'low',
            paddingBottom: 'lowest'
          }}
        >
          <form onSubmit={handleSubmit(this.handleSubmit)}>
            <div className={`manure-form__name ${isCreate ? 'create' : ''}`}>
              {!isCreate && (
                <IconButton
                  onClick={e => {
                    e.preventDefault();
                    this.setState({ showDeleteWarning: true });
                  }}
                >
                  <span className="btn-icon__text">{labels.delete}</span>
                  <TrashcanIcon />
                </IconButton>
              )}
              <Field
                name="name"
                label={labels.name}
                component={TextBoxControl}
                controlId="name"
                type="text"
                stlyeClass="manure-storage__name"
                placeholder="Name of the manure storage"
                disabled={isLoading}
              />
            </div>
            <SectionContainer
              styling={{
                paddingLeft: 'none',
                paddingRight: 'none',
                paddingTop: 'low',
                paddingBottom: 'lowest'
              }}
            >
              <div className="manure-storage__fields">
                <Field
                  name="type"
                  controlId="type"
                  label={labels.manureType}
                  styleName="select-box-common"
                  component={SelectControl}
                  options={this.getManureOption(manureType)}
                  disabled={isLoading || !hasPermission}
                  defaultValue={formValue?.type ? formValue.type : null}
                  onChange={e => this.onTypeDryMatterChange(e)}
                  placeholder={labels.manureType}
                />
                <div style={{ paddingTop: '4px' }}>
                  <Field
                    name="yearlyProduction"
                    label={intl.formatMessage({ id: 'MANURE_STORAGE_YEARLY_PRODUCTION' })}
                    component={TextBoxControl}
                    controlId="yearlyProduction"
                    type="text"
                    stlyeClass="manure-storage__name1"
                    units={`${labels.storageUnit}`}
                    disabled={isLoading}
                  />
                </div>
                <div style={{ paddingTop: '4px' }}>
                  <Field
                    name="storageCapacity"
                    label={`${labels.storageCapacity}:`}
                    component={TextBoxControl}
                    controlId="storageCapacity"
                    type="text"
                    stlyeClass="manure-storage__name1"
                    units={`${labels.storageUnit}`}
                    disabled={isLoading}
                  />
                </div>
                <div style={{ paddingTop: '4px' }}>
                  <Field
                    name="dryMatter"
                    label={`${labels.dryMatter}:`}
                    component={TextBoxControl}
                    controlId="dryMatter"
                    type="text"
                    stlyeClass="manure-storage__name1"
                    units={labels.dryMatterUnit}
                    disabled={isLoading || !hasPermission}
                    onChange={event => this.onDryMatterChange(event)}
                  />
                </div>
                <Field
                  name="lagerType"
                  controlId="lagerType"
                  label={intl.formatMessage({ id: 'LAGER_TYPE' })}
                  styleName="select-box-common"
                  component={SelectControl}
                  options={this.getManureTypeOption()}
                  disabled={isLoading || !hasPermission}
                  onChange={e => this.onLagerTypeChange(e)}
                  placeholder={intl.formatMessage({ id: 'LAGER_TYPE' })}
                />
                {this.state.showQuestion1 &&
                  <div className="center-align1">
                    <span>{intl.formatMessage({ id: 'LAGER_TYPE_QUESTION1' })}</span>
                    <span>
                      <RadioButton
                        id="type1Yes"
                        onChange={() => this.type1Change(true)}
                        labelText={intl.formatMessage({ id: 'YES' })}
                        disabled={false}
                        name="type1Radio"
                        size="small"
                        checked={this.state.selectionQuestion1 === true}
                      />
                    </span>
                    <span>
                      <RadioButton
                        id="type1No"
                        onChange={() => this.type1Change(false)}
                        labelText={intl.formatMessage({ id: 'NO' })}
                        disabled={false}
                        name="type1Radio"
                        size="small"
                        checked={this.state.selectionQuestion1 === false}
                      />
                    </span>
                  </div>}
                {this.state.showQuestion2 &&
                  <div className="center-align1">
                    <br />
                    <span>{intl.formatMessage({ id: 'LAGER_TYPE_QUESTION2' })}</span>
                    <span>
                      <RadioButton
                        id="type2Yes"
                        onChange={() => this.type2Change(true)}
                        labelText={intl.formatMessage({ id: 'YES' })}
                        disabled={false}
                        name="type2Radio"
                        size="small"
                        checked={this.state.selectionQuestion2 === true}
                      />
                    </span>
                    <span>
                      <RadioButton
                        id="type2No"
                        onChange={() => this.type2Change(false)}
                        labelText={intl.formatMessage({ id: 'NO' })}
                        disabled={false}
                        name="type2Radio"
                        size="small"
                        checked={this.state.selectionQuestion2 === false}
                      />
                    </span>
                  </div>}
              </div>
              {this.state.showQuestion1 &&
                <div className="center-align1" style={{ paddingTop: '40px' }}>
                  <br />
                  <span>{intl.formatMessage({ id: 'LAGER_TYPE_QUESTION2' })}</span>
                  <span>
                    <RadioButton
                      id="type2Yes"
                      onChange={() => this.type2Change(true)}
                      labelText={intl.formatMessage({ id: 'YES' })}
                      disabled={false}
                      name="type2Radio"
                      size="small"
                      checked={this.state.selectionQuestion2 === true}
                    />
                  </span>
                  <span>
                    <RadioButton
                      id="type2No"
                      onChange={() => this.type2Change(false)}
                      labelText={intl.formatMessage({ id: 'NO' })}
                      disabled={false}
                      name="type2Radio"
                      size="small"
                      checked={this.state.selectionQuestion2 === false}
                    />
                  </span>
                </div>}
            </SectionContainer>
            {!isCreate && (
              <div>
                <div className="divider" />
                <SectionContainer
                  styling={{
                    paddingLeft: 'none',
                    paddingRight: 'none',
                    paddingTop: 'low',
                    paddingBottom: 'lowest'
                  }}
                >
                  <div className="manure-storage-calc__heading">
                    <div>
                      <p>
                        <EmphasisLight size="small">{labels.statement1}</EmphasisLight>
                      </p>
                      <p>
                        <MetaInformation size="small">{labels.statement2}</MetaInformation>
                      </p>
                    </div>
                    <ActionButton
                      tertiary
                      onClick={e => {
                        e.preventDefault();
                        this.setState(prevState => ({ inEditMode: !prevState.inEditMode }));
                      }}
                    >
                      {inEditMode ? intl.formatMessage({ id: 'DONE' }) : labels.edit}

                      <i className={inEditMode ? 'icon-close' : 'icon-edit'} />
                    </ActionButton>
                  </div>
                  {!inEditMode && (
                    <div className="manure-storage-calc__data">
                      <div className="manure-storage__calculated-value">
                        <p className="manure-storage_calc-label">
                          <MetaInformation size="small">{labels.nitrogen}: </MetaInformation>
                        </p>
                        <p>
                          <EmphasisLight>
                            {formValue?.nitrogen} {labels.kgTonUnit}
                          </EmphasisLight>
                        </p>
                      </div>
                      <div className="manure-storage__calculated-value">
                        <p className="manure-storage_calc-label">
                          <MetaInformation size="small">{labels.ammmonium}</MetaInformation>
                        </p>
                        <p>
                          <EmphasisLight>
                            {formValue?.ammonium} {labels.kgTonUnit}
                          </EmphasisLight>
                        </p>
                      </div>
                      <div className="manure-storage__calculated-value">
                        <p className="manure-storage_calc-label">
                          <MetaInformation size="small">{labels.phosphorus}</MetaInformation>
                        </p>
                        <p>
                          <EmphasisLight>
                            {formValue?.phosphorus} {labels.kgTonUnit}
                          </EmphasisLight>
                        </p>
                      </div>
                      <div className="manure-storage__calculated-value">
                        <p className="manure-storage_calc-label">
                          <MetaInformation size="small">{labels.potassium}</MetaInformation>
                        </p>
                        <p>
                          <EmphasisLight>
                            {formValue?.potassium} {labels.kgTonUnit}
                          </EmphasisLight>
                        </p>
                      </div>
                      <div className="manure-storage__calculated-value">
                        <p className="manure-storage_calc-label">
                          <MetaInformation size="small">{labels.sulphur}</MetaInformation>
                        </p>
                        <p>
                          <EmphasisLight>
                            {formValue?.sulfur} {labels.kgTonUnit}
                          </EmphasisLight>
                        </p>
                      </div>
                    </div>
                  )}
                  {inEditMode && (
                    <div className="manure-storage-calc__data --edit">
                      <Field
                        name="nitrogen"
                        label={labels.nitrogen}
                        component={TextBoxControl}
                        controlId="nitrogen"
                        type="text"
                        stlyeClass="manure-storage__name"
                        units={labels.kgTonUnit}
                        disabled={isLoading}
                      />
                      <Field
                        name="ammonium"
                        label={labels.ammmonium}
                        component={TextBoxControl}
                        controlId="ammonium"
                        type="text"
                        stlyeClass="manure-storage__name"
                        units={labels.kgTonUnit}
                        disabled={isLoading}
                      />
                      <Field
                        name="phosphorus"
                        label={labels.phosphorus}
                        component={TextBoxControl}
                        controlId="phosphorus"
                        type="text"
                        stlyeClass="manure-storage__name"
                        units={labels.kgTonUnit}
                        disabled={isLoading}
                      />
                      <Field
                        name="potassium"
                        label={labels.potassium}
                        component={TextBoxControl}
                        controlId="potassium"
                        type="text"
                        stlyeClass="manure-storage__name"
                        units={labels.kgTonUnit}
                        disabled={isLoading}
                      />
                      <Field
                        name="sulfur"
                        label={labels.sulphur}
                        component={TextBoxControl}
                        controlId="sulfur"
                        type="text"
                        stlyeClass="manure-storage__name"
                        units={labels.kgTonUnit}
                        disabled={isLoading}
                      />
                    </div>
                  )}
                </SectionContainer>
              </div>
            )}
            <div className="manure-error">{hasError && errorPattern !== 'delete' && <ErrorText>{errorMsg.data}</ErrorText>}</div>
            <div className="manure-storage__submit">
              <ActionButton
                disabled={!hasPermission}
                onClick={() => handleSubmit(this.handleSubmit)}
              >
                {labels.save}
              </ActionButton>
            </div>
          </form>
          {isLoading && <div style={{ backgroundColor: '#FFFFFF', width: '100%', height: '55vh' }}><Loader styleName="fixed-loader" /></div>}
          {submitSuccess && <Redirect to="/storage/manure-storage/" />}
        </SectionContainer>
        {showDeleteWarning && (
          <ConfirmDialogBox
            heading={intl.formatMessage({ id: 'DELETE_CONFIRM_TITLE' })}
            body={customizedDelString}
            confirmText="DELETE"
            cancelText="cancel"
            callback={e => this.deleteAction(e)}
            warning
          />
        )}
        {showChangeWarning && (
          <div className="confirm-box-stye manure-change-warning">
            <ConfirmDialog
              title={dialogValue === 'type' ? labels.warningMessageHeaderMT : labels.warningMessageHeader}
              message={dialogValue === 'type' ? labels.warningMessageBodyMT : labels.warningMessageBody}
              confirmText={dialogValue === 'type' ? labels.buttonYesMT : labels.buttonYes}
              onConfirm={() => this.warningAction(true)}
              cancelText={dialogValue === 'type' ? labels.buttonNoMT : labels.buttonNo}
              onCancel={() => this.warningAction(false)}
              warning={false}
            />
          </div>
        )}
        {showDeleteErrorMessage && hasError && (
          <div className="confirm-box-stye manure-change-warning">
            <ConfirmDialog
              title=''
              message={errorMsg.data}
              confirmText={intl.formatMessage({ id: 'OK' })}
              onConfirm={() => this.goToManurePage()}
              warning={true}
            />
          </div>
        )}
        <ManureStorageModalWindow
          intl={intl}
          openDialogWindowManureStorage={this.state.openDialogWindowManureStorage}
          closeDialogWindowManureStorage={this.closeDialogWindowManureStorage}
          disableCreateButton={this.state.disableCreateButton}
          handleInputChange={this.handleInputChange}
          handleSubmitForManureStorage={this.handleSubmitForManureStorage}
          errorMsg={null}
        />
      </div>
    );
  }
}
const validate = (values, props) => {
  const errors = {};
  const { intl } = props;
  if (!values.name || !values.name.trim()) {
    errors.name = intl.formatMessage({ id: 'AFN_RQ' });
  }

  if (values.storageCapacity && !CheckInteger(values.storageCapacity)) {
    errors.storageCapacity = intl.formatMessage({ id: 'invalid' });
  }

  if (!values.dryMatter) {
    errors.dryMatter = intl.formatMessage({ id: 'required' });
  }

  if (values.dryMatter) {
    if (values.dryMatter > 100) {
      errors.dryMatter = intl.formatMessage({ id: 'invalid' });
    }
    if (!CheckInteger(values.dryMatter)) {
      errors.dryMatter = intl.formatMessage({ id: 'invalid' });
    }
  }

  if (values.nitrogen) {
    const result = DecimalValidation(values.nitrogen);
    if (!result.valid) {
      errors.nitrogen = intl.formatMessage({ id: 'invalid' });
    }
  }

  if (values.ammonium) {
    const result = DecimalValidation(values.ammonium);
    if (!result.valid) {
      errors.ammonium = intl.formatMessage({ id: 'invalid' });
    }
  }

  if (values.phosphorus) {
    const result = DecimalValidation(values.phosphorus);
    if (!result.valid) {
      errors.phosphorus = intl.formatMessage({ id: 'invalid' });
    }
  }

  if (values.potassium) {
    const result = DecimalValidation(values.potassium);
    if (!result.valid) {
      errors.potassium = intl.formatMessage({ id: 'invalid' });
    }
  }

  if (values.sulfur) {
    const result = DecimalValidation(values.sulfur);
    if (!result.valid) {
      errors.sulfur = intl.formatMessage({ id: 'invalid' });
    }
  }

  return errors;
};

const mapStateToProps = state => ({
  activeFarm: state.farmList.activeFarm,
  manureStoregeForm: state.form.ManureStorageForm,
  manureStorage: state.manureStorage,
  lblCommon: state.masterData.data[0]?.commonLabels,
  pageContent: _.filter(state.masterData.data[0]?.pages, ['pageId', 'manureStoragePage'])[0],
  hasSupportAdminRole: state.SupportAdminRole
});

const mapDispatchToProps = dispatch => ({
  fetchManureType: (farmId) => dispatch(ManureAction.fetchManureType(farmId)),
  createManure: (farmId, values) => dispatch(ManureAction.createManure(farmId, values)),
  deleteManureList: (farmId, id) => dispatch(ManureAction.deleteManureList(farmId, id)),
  updateManure: (farmId, values, redirect) =>
    dispatch(ManureAction.UpdateManure(farmId, values, redirect))
});

export default injectIntl(
  reduxForm({
    form: 'ManureStorageForm',
    validate
  })(withRouter(connect(mapStateToProps, mapDispatchToProps)(ManureStorageForm)))
);
