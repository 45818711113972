import React, { useState, useEffect } from "react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Dialog,
  DialogContent,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  Typography,
  Box,
  FormHelperText,
  Divider,
} from "@mui/material";
import * as mapboxStyle from "mui-components/kart/mapcomponent/MapboxStyle";
import { FormattedMessage } from "react-intl";
import CheckIcon from "@mui/icons-material/Check";

const MuiSelectMobileDropdown = (props) => {
  const {
    defaultLabel,
    defaultValue,
    options,
    fieldName,
    onChange,
    textLabel,
    required,
    errorMessage,
    deSelect = true,
    borderLine = false,
    defaultOptionsLength = 0,
  } = props;

  const [value, setValue] = useState(defaultValue || "");
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  const handleChange = (selectedValue) => {
    let newValue = "";
    if (deSelect) {
      newValue = selectedValue === value ? "" : selectedValue;
      setValue(newValue);
      if (required && newValue) {
        setError(false);
      }
      if (onChange) {
        onChange(fieldName, newValue);
      }
    } else {
      newValue = selectedValue;
    }
    if (!deSelect && newValue !== value) {
      setValue(newValue);
      if (required && newValue) {
        setError(false);
      }
      if (onChange) {
        onChange(fieldName, newValue);
      }
    }
    setOpen(false);
  };

  return (
    <div style={{ width: "100%" }}>
      <FormControl
        fullWidth
        variant="outlined"
        onClick={() => setOpen(true)}
        sx={{
          "& label": {
            color: "#3F4949",
            fontFamily: "Inter",
          },
          "& label.Mui-focused": {
            color: "#00696D",
            fontFamily: "Inter",
          },
        }}
      >
        <InputLabel>{defaultLabel}</InputLabel>
        <Select value={value} readOnly label={defaultLabel}>
          <MenuItem value={value}>
            {options && (options.find((option) => option.value === value)?.label || "")}
          </MenuItem>
        </Select>
        {error && <FormHelperText>{errorMessage || "This field is required"}</FormHelperText>}
      </FormControl>

      <Dialog
        fullWidth
        open={open}
        onClose={() => setOpen(false)}
        PaperProps={{
          sx: {
            position: "absolute",
            bottom: 0,
            margin: 0,
            width: "100%",
            borderRadius: "28px 28px 0 0",
            padding: "0px",
          },
        }}
      >
        <DialogContent sx={{ padding: "0px 0px 20px 0px" }}>
          <Box sx={{ padding: "35px 20px 16px 20px", textAlign: "left" }}>
            <Typography
              sx={{
                color: "#191C1C",
                fontFamily: "Inter",
                fontSize: "22px",
                fontStyle: "normal",
                fontWeight: "400",
                lineHeight: "28px",
              }}
            >
              {textLabel}
            </Typography>
          </Box>
          <List>
            {options &&
              options.map((option, index) =>
                borderLine ? (
                  <Box key={option.value}>
                    {index === 0 && defaultOptionsLength > 0 && (
                      <Box sx={{ padding: "10px 20px", display: "flex", justifyContent: "flex-end" }}>
                        <Typography style={mapboxStyle.modalTitleSubHeader}>
                          <FormattedMessage id="USED_IN_PLAN" />
                        </Typography>
                      </Box>
                    )}
                    <ListItem disablePadding>
                      <ListItemButton
                        onClick={() => handleChange(option.value)}
                        sx={{
                          backgroundColor: value === option.value ? "#D8DADB" : "",
                          "&:hover": {
                            backgroundColor: "#D8DADB",
                          },
                          padding: "12px 24px 12px 18px",
                        }}
                      >
                        <Typography sx={{ flexGrow: 1, textAlign: "left" }}>
                          {option.label}
                        </Typography>
                        {value !== null && value !== "" && value === option.value && (
                          <ListItemIcon sx={{ minWidth: "auto", color: "#3F4949" }}>
                            <CheckIcon />
                          </ListItemIcon>
                        )}
                      </ListItemButton>
                    </ListItem>
                    {defaultOptionsLength > 0 && defaultOptionsLength === index + 1 && (
                      <Divider sx={{ mx: 2, borderColor: "#BEC8C9", borderBottomWidth: 1 }} />
                    )}
                  </Box>
                ) : (
                  <ListItem disablePadding key={option.value}>
                    <ListItemButton
                      onClick={() => handleChange(option.value)}
                      sx={{
                        backgroundColor: value === option.value ? "#D8DADB" : "",
                        "&:hover": {
                          backgroundColor: "#D8DADB",
                        },
                        padding: "12px 24px 12px 18px",
                      }}
                    >
                      <Typography sx={{ flexGrow: 1, textAlign: "left" }}>
                        {option.label}
                      </Typography>
                      {value !== null && value !== "" && value === option.value && (
                        <ListItemIcon sx={{ minWidth: "auto", color: "#3F4949" }}>
                          <CheckIcon />
                        </ListItemIcon>
                      )}
                    </ListItemButton>
                  </ListItem>
                )
              )}
          </List>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default MuiSelectMobileDropdown;
