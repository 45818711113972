import React, { useState, useRef, useEffect } from "react";
import { Typography, Box, Chip, Dialog } from '@mui/material';
import MuiDatePicker from 'components/mui3-forms/MuiDatePicker';
import MuiChipMobileDropdown from 'components/mui3-forms/MuiChipMobileDropdown';
import MuiTextFieldArea from 'components/mui3-forms/MuiTextFieldArea';
import MuiCollapseComponent from 'components/mui3-forms/MuiCollapseComponent';
import MuiSelectMobileDropdown from 'components/mui3-forms/MuiSelectMobileDropdown';
import * as taskTemplateStyle from 'mui-mobile-components/kart/fieldmap/tasktemplates/TaskTemplateStyle';
import AddVehicleModal from 'mui-mobile-components/kart/fieldmap/vehicleModals/AddVehicleModal';
import AddEquipmentModal from 'mui-mobile-components/kart/fieldmap/vehicleModals/AddEquipmentModal';
import AddIcon from '@mui/icons-material/Add';
import { Close as CloseIcon } from '@mui/icons-material';

const AddTaskTemplate3 = (props) => {
  const {intl, activeFarm, selectedFields, selectedActivity, taskDetails, closeNewTask, saveNewTask,
    createdVehicleId, createdEquipmentId, taskKartToggle
  } = props;
  const initialFormState = {
    farmId: activeFarm.farm.id,
    activityId: selectedActivity?.id,
    fieldIds: selectedFields[0]?.fieldId,
    activityName: selectedActivity?.activityName,
    cut: "1",
    startTime: new Date(new Date().setHours(0, 0, 0, 0)),
    note: "",
    vehicleAssetId: "",
    equipmentAssetId: "",
    waterJournal: null,
    waterJournalAdded: false
  };
  const [formState, setFormState] = useState(initialFormState);
  const initialStateRef = useRef(initialFormState);
  const [isModalOpen, setModalOpen] = useState(false);
  const [showAddVehicleDialog, setShowAddVehicleDialog] = useState(false);
  const [showAddEquipmentDialog, setShowAddEquipmentDialog] = useState(false);

  useEffect(() => {
    if (taskKartToggle?.getTaskList?.taskActivity) {
      setFormState((prevState) => {
        const updatedFormState = {
          ...prevState,
          vehicleAssetId: taskKartToggle.getTaskList.vehicleAssetId,
          equipmentAssetId: taskKartToggle.getTaskList.equipmentAssetId
        };
        
        initialStateRef.current = updatedFormState;
        return updatedFormState;
      });
    }
  }, [taskKartToggle]);

  const harvestOptions = [
    { label: intl.formatMessage({ id: 'FIRST_HARVEST_YIELD' }), value: 1 },
    { label: intl.formatMessage({ id: 'SECOND_HARVEST_YIELD' }), value: 2 },
    { label: intl.formatMessage({ id: 'THIRD_HARVEST_YIELD' }), value: 3 },
    { label: intl.formatMessage({ id: 'FOURTH_HARVEST_YIELD' }), value: 4 }
  ];

  const AddVehicle = () => (
    <Box style={taskTemplateStyle.assetAdd} onClick={() => setShowAddVehicleDialog(true)}>
      <Box sx={{ padding: '5px 15px 0px 0px' }}>
        <AddIcon />
      </Box>
      <Typography style={taskTemplateStyle.assetAddHeader}>{intl.formatMessage({ id: 'CT_ANV' })}</Typography>
    </Box>
  );
  const vehicleOptions = () => {
    let vehicleList = [];
    vehicleList.push({value: null, label: <AddVehicle />});
    if (taskDetails.allVehicles && (taskDetails.allVehicles).length > 0) {
      (taskDetails.allVehicles).map(data => (
        vehicleList.push({
          value: data.asset.assetId,
          label: `${data.asset.brand} ${data.asset.model}`
        })
      ));
      return vehicleList;
    }
    return vehicleList;
  };
  const AddEquipment = () => (
    <Box style={taskTemplateStyle.assetAdd} onClick={() => setShowAddEquipmentDialog(true)}>
      <Box sx={{ padding: '5px 15px 0px 0px' }}>
        <AddIcon />
      </Box>
      <Typography style={taskTemplateStyle.assetAddHeader}>{intl.formatMessage({ id: 'CT_ANE' })}</Typography>
    </Box>
  );
  const equipmentOptions = () => {
    let equipmentList = [];
    equipmentList.push({value: null, label: <AddEquipment />});
    if (taskDetails.allEquipments && (taskDetails.allEquipments).length > 0) {
      (taskDetails.allEquipments).map(data => (
        equipmentList.push({
          value: data.asset.assetId,
          label: `${data.asset.brand} ${data.asset.model}`
        })
      ));
      return equipmentList;
    }
    return equipmentList;
  };

  const handleFieldChange = (fieldName, value) => {
    setFormState((prevState) => ({
      ...prevState,
      [fieldName]: value
    }));
  };

  const handleSave = () => {
    setModalOpen(false);
    saveNewTask(formState);
  };

  const handleContinueSave = () => {
    setModalOpen(false);
  };

  const handleCancel = () => {
    let isChanged = JSON.stringify(initialStateRef.current) !== JSON.stringify(formState);
    if (isChanged) {
      setModalOpen(true);
    } else {
      handleCancelButton();
    }
  };

  const handleCancelButton = () => {
    setModalOpen(false);
    closeNewTask();
  };

  const onCloseVehicleDialog = () => {
    setShowAddVehicleDialog(false);
  }

  const onCloseEquipmentDialog = () => {
    setShowAddEquipmentDialog(false);
  }

  return (
    <Box sx={{
      padding: '0px 16px 0px 16px',
      overflowY: 'auto',
      '&::-webkit-scrollbar': {
        width: '5px',
        backgroundColor: 'rgba(0, 0, 0, 0.1)',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#A8A8A8',
        borderRadius: '4px',
        transition: 'background-color 0.3s ease',
      },
      '&::-webkit-scrollbar-thumb:hover': {
        backgroundColor: '#B8B8B8',
      }}}
    >
      <Box style={taskTemplateStyle.headerSticky}>
        <Box style={taskTemplateStyle.modalDialogHeader}>
          <Box style={taskTemplateStyle.modalDialogHeader1}>
            <Typography sx={{ paddingTop: '5px', paddingRight: '10px' }}>
              <CloseIcon
                width="24"
                height="24"
                onClick={handleCancel}
                style={{ cursor: 'pointer' }}
              />
            </Typography>
            <Typography style={taskTemplateStyle.modalDialogTitle}>{selectedActivity?.activityName}</Typography>
          </Box>
          <Box sx={{ paddingRight: '0px' }}>
            <MuiChipMobileDropdown
              defaultLabel={intl.formatMessage({ id: 'FIRST_HARVEST_YIELD' })}
              textLabel={intl.formatMessage({ id: 'CT_HARVEST' })}
              defaultValue={1}
              options={harvestOptions}
              optionStyle={taskTemplateStyle.menuOptionMobileText}
              dropdownStyle={taskTemplateStyle.chipDropdownStyle}
              fieldName='cut'
              onChange={handleFieldChange}
            />
          </Box>
        </Box>
        <Box style={taskTemplateStyle.modalDialogSubHeader}>
          <Typography style={taskTemplateStyle.modalDialogSubTitle}>{selectedFields[0].fieldName.join(", ")}</Typography>
        </Box>
      </Box>
      <Box>
        <Box sx={{ display: "flex", alignItems: "center", paddingTop: '28px' }}>
          <MuiDatePicker
            defaultLabel={intl.formatMessage({ id: 'FIELD_WEATHER_DATE' })}
            dateFormat={"dd/MM/yyyy"}
            fieldName='startTime'
            onChange={handleFieldChange}
            maxDateOption={true}
          />
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", paddingTop: '56px' }}>
          <MuiTextFieldArea
            defaultLabel={intl.formatMessage({ id: 'NOTE' })}
            fieldName='note'
            onChange={handleFieldChange}
          />
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", paddingTop: '40px' }}>
          <MuiCollapseComponent
            title={intl.formatMessage({ id: 'TASK_MORE_INFORMATION' })}
            content={
              <Box sx={{ paddingTop: '48px', width: '100%' }}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <MuiSelectMobileDropdown
                    key={createdVehicleId}
                    defaultLabel={intl.formatMessage({ id: 'VEHICLE' })}
                    defaultValue={createdVehicleId}
                    options={vehicleOptions()}
                    fieldName="vehicleAssetId"
                    onChange={handleFieldChange}
                    textLabel={intl.formatMessage({ id: 'VEHICLE' })}
                  />
                </Box>
                <Box sx={{ display: "flex", alignItems: "center", paddingTop: '24px' }}>
                  <MuiSelectMobileDropdown
                    key={createdEquipmentId}
                    defaultLabel={intl.formatMessage({ id: 'EQUIPMENT' })}
                    defaultValue={createdEquipmentId}
                    options={equipmentOptions()}
                    fieldName="equipmentAssetId"
                    onChange={handleFieldChange}
                    textLabel={intl.formatMessage({ id: 'EQUIPMENT' })}
                  />
                </Box>
              </Box>
            }
          />
        </Box>
      </Box>
      <Box style={taskTemplateStyle.buttonBox}>
        <Box style={taskTemplateStyle.buttonBoxBottom}>
          <Chip
            label={intl.formatMessage({ id: 'SAVE' })}
            clickable
            style={taskTemplateStyle.okButtonStyle}
            sx={{ backgroundColor: 'transparent',
              '&:hover, &:focus': {
                backgroundColor: 'transparent',
              }
            }}
            disableRipple
            onClick={handleSave}
          />
        </Box>
      </Box>
      {/* Modal */}
      <Box>    
        <Dialog onClose={() => {}} open={isModalOpen} PaperProps={{
          style: {
            backgroundColor: '#E6E9E8',
            borderRadius: "28px",
            width: "312px",
            minWidth: "280px",
            maxWidth: "312px",
            maxHeight: '30vh'
          }}}>
          <Box style={taskTemplateStyle.cancelModalBox}>
            <Typography style={taskTemplateStyle.cancelModalTitle}>{intl.formatMessage({ id: 'TASK_CANCEL_MODAL_TITLE' })}</Typography>
            <Typography style={taskTemplateStyle.cancelModalSubTitle}>{intl.formatMessage({ id: 'TASK_CANCEL_MODAL_SUB_TITLE' })}</Typography>
          </Box>
          <Box style={taskTemplateStyle.cancelModalBoxButton}>
            <Chip
              label={intl.formatMessage({ id: 'cancel' })}
              clickable
              style={taskTemplateStyle.cancelButtonStyle}
              sx={{ backgroundColor: 'transparent',
                '&:hover, &:focus': {
                  backgroundColor: 'transparent',
                }
              }}
              disableRipple
              onClick={handleCancelButton}
            />
            <Chip
              label={intl.formatMessage({ id: 'TASK_CONTINUE_REGISTRATION' })}
              clickable
              style={taskTemplateStyle.cancelButtonStyle}
              sx={{ backgroundColor: 'transparent',
                '&:hover, &:focus': {
                  backgroundColor: 'transparent',
                }
              }}
              disableRipple
              onClick={handleContinueSave}
            />
          </Box>
        </Dialog>
      </Box>
      <AddVehicleModal
        intl={intl}
        vehicleSelectedId={'new'}
        onCloseVehicleDialog={onCloseVehicleDialog}
        showAddVehicleDialog={showAddVehicleDialog}
        assetType={'vehicle'}
        taskDetails={taskDetails}
      />
      <AddEquipmentModal
        intl={intl}
        equipmentSelectedId={'new'}
        onCloseEquipmentDialog={onCloseEquipmentDialog}
        showAddEquipmentDialog={showAddEquipmentDialog}
        assetType={'equipment'}
        taskDetails={taskDetails}
      />
    </Box>
  );
};

export default AddTaskTemplate3;