import React, { useState, useEffect } from "react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  ListItemText,
  Box,
  Divider,
  Typography
} from "@mui/material";
import { FormattedMessage } from 'react-intl';
import * as mapboxStyle from 'mui-components/kart/mapcomponent/MapboxStyle';
import CheckIcon from "@mui/icons-material/Check";

const MuiSelectDropdown = (props) => {
  const { defaultLabel, defaultValue, options, fieldName, onChange, required, errorMessage, deSelect = true, borderLine = false, defaultOptionsLength = 0 } = props;
  const [value, setValue] = useState(defaultValue || "");
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    setValue(defaultValue || "");
  }, [defaultValue]);

  const handleSelect = (selectedValue) => {
    let newValue = "";
    if (deSelect) {
      newValue = selectedValue === value ? "" : selectedValue;
      setValue(newValue);
      if (required && newValue) {
        setError(false);
      }
      if (onChange) {
        onChange(fieldName, newValue);
      }
    } else {
      newValue = selectedValue;
    }
    if (!deSelect && newValue !== value) {
      setValue(newValue);
      if (required && newValue) {
        setError(false);
      }
      if (onChange) {
        onChange(fieldName, newValue);
      }
    }
  };

  const handleValidation = () => {
    if (required && !value) {
      setError(true);
    }
  };

  return (
    <FormControl
      fullWidth
      variant="outlined"
      sx={{
        "& label": {
          color: "#3F4949",
          fontFamily: "Inter",
        },
        "& label.Mui-focused, & label.MuiFormLabel-filled": {
          color: "#00696D",
        },
      }}
      notched={Boolean(value) || open} // Ensures proper label behavior
    >
      <InputLabel shrink={value ? true : false}>{defaultLabel}</InputLabel>
      <Select
        label={defaultLabel}
        value={value || ""}
        displayEmpty
        onBlur={handleValidation}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        renderValue={(selected) =>
          selected ? options && options.find((option) => option.value === selected)?.label : ''
        }
      >
        {options?.map((option, index) => {
          const isLastOccurrence = index === options.map(o => o.value).lastIndexOf(value);
          return (
            borderLine ?
              (
                <Box key={index}>
                  {index === 0 && defaultOptionsLength > 0 &&
                    <Box sx={{ padding: '10px 20px', display: 'flex', justifyContent: 'flex-end' }}>
                      <Typography style={mapboxStyle.modalTitleSubHeader}>
                        <FormattedMessage id="USED_IN_PLAN" />
                      </Typography>
                    </Box>
                  }
                  <MenuItem
                    value={option.value}
                    onClick={() => handleSelect(option.value)}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      padding: "12px 16px"
                    }}
                  >
                    <ListItemText primary={option.label} />
                    {open && isLastOccurrence && option.value === value && <CheckIcon />}
                  </MenuItem>
                  {(defaultOptionsLength > 0 && defaultOptionsLength === index + 1) &&
                    <Divider sx={{ mx: 2, borderColor: '#BEC8C9', borderBottomWidth: 1 }} />
                  }
                </Box>
              ) :
              <MenuItem
                key={index}
                value={option.value}
                onClick={() => handleSelect(option.value)}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between"
                }}
              >
                <ListItemText primary={option.label} />
                {open && isLastOccurrence && option.value === value && <CheckIcon />}
              </MenuItem>
          );
        })}
      </Select>
      {error && (
        <FormHelperText>
          {errorMessage || "This field is required"}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default MuiSelectDropdown;