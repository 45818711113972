import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Field, getFormValues } from 'redux-form';
import { connect } from 'react-redux';
import AddIcon from '@tine/lib-frontend-components/elements/icons/add-icon/index';
import TrashcanIcon from '@tine/lib-frontend-components/elements/icons/trashcan-icon/index';
import TextBoxControl from 'components/forms/TextBoxControl';
import SelectControl from 'components/forms/SelectBoxControl';
import { ActivityTranslation } from 'constants/DropdownAndTranslationOptions';
import { getDropDownNameSeasons } from 'utils/index';
import ToggleButton from 'react-toggle-button';
import * as ManureAction from 'actions/ManureStorage';
import * as ensilationTypeAction from 'actions/EnsilationList';

import {
  ACT19,
  ACT20,
  ACT1,
  KORN,
  OLJEVEKSTER,
  ERTER,
  POTET,
  JORDBR,
  EPLER,
  IKKEANGITT,
  SPIRING,
  BLADUTVIKLING_HOVEDSKUDD,
  BUSKING_UTVIKLING_AV_SIDESKUDD,
  STREKKING_HOVEDSKUDD,
  AKS_ELLER_TOPPDANNELSE,
  SKYTING_HOVEDSKUDD,
  BLOMSTRING_HOVEDSKUDD,
  FROKJERNE_UTVIKLING,
  MODNING,
  VISNING,
  UGRAS,
  POTETSYKDOMMER,
  SOPP,
  SKADEDYR,
  MANGELSYKDOMMER,
  VEKSTAVSLUTNING,
  VEKSTREGULERING,
  BEISING,
  ACT7,
  ACT9,
  ACT10,
  ACT11,
  ACT12,
  ACT18,
  ACT28,
  ACT16
} from 'constants/index';
import {
  DynamicTemplateFields,
  mineralFertilizerOptions,
  manureOptions,
  fertilizerSubTypeFoilerDataOptions,
  fertilizerSubTypeLimingOptions,
  pesticideTypeOptions,
  pesticideUnitOptions,
  growthStageSubTypeKornOptions,
  growthStageSubTypeOljeveksterOptions,
  growthStageSubTypeErterOptions,
  growthStageSubTypePotetOptions,
  growthStageSubTypeJordbrOptions,
  growthStageSubTypeEplerOptions,
  growthStageSubSubTypeSpiringOptions,
  growthStageSubTypeIkkeAngittOptions,
  growthStageSubSubTypeBladutviklingOptions,
  growthStageSubSubTypeBuskingOptions,
  growthStageSubSubTypeStrekkingOptions,
  growthStageSubSubTypeAksOptions,
  growthStageSubSubTypeSkytingOptions,
  growthStageSubSubTypeBlomstringOptions,
  growthStageSubSubTypeFrkjerneOptions,
  growthStageSubSubTypeModningOptions,
  growthStageSubSubTypeVisningOptions,
  pestUgrasSubTypeOptions,
  pestPotetsykdommerSubTypeOptions,
  pestSoppSubTypeOptions,
  pestSkadedyrSubTypeOptions,
  pestMangelsykdommerSubTypeOptions,
  pestVekstavslutningSubTypeOptions,
  pestVekstreguleringSubTypeOptions,
  pestBeisingSubTypeOptions,
  manourUnitOptions,
  foilerFertilizerUnitOptions,
  act20MineralFertilizerLimingUnitOptions,
  mineralFertilizerUnitOptions,
  limingUnitOptions,
  sowingUnitOptions,
  act1SowingUnitOptions,
  act20SowingUnitOptions,
  balePressUnitOptions,
  act9BalePressUnitOptions,
  act10BalePressUnitOptions,
  act11BalePressUnitOptions,
  act12BalePressUnitOptions,
  act28BalePressUnitOptions,
  balePressEnsilationOptions,
  act9BalePressEnsilationOptions,
  act10BalePressEnsilationOptions,
  act11BalePressEnsilationOptions,
  act12BalePressEnsilationOptions,
  act28BalePressEnsilationOptions,
  waterUnitOptions,
} from 'utils/DynamicFields';
import SliderComponent from 'containers/tasks/tasklist/TaskSlider';
import CustomCalendar from 'containers/tasks/tasklist/CustomCalendar';
import * as taskActions from 'actions/Tasks';

class DynamicSectionForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pesticidesArr: [
        {
          pesticideType: '',
          pesticideAmount: '',
          pesticideUnit: '',
          id: 0
        }
      ],
      showDatePicker: false,
      paramId: props.match.params.id
    };
  }

  componentDidMount() {
    const { editTaskDetails, activeFarm, fetchManureList, fetchEnsilationType } = this.props;
    const { paramId } = this.state;
    fetchEnsilationType();
    if (activeFarm) {
      fetchManureList(activeFarm.farm.id);
    }
    if (editTaskDetails && paramId !== 'create') {
      const { isGetTaskData } = editTaskDetails;
      if (isGetTaskData && isGetTaskData.taskActivity) {
        const dynamicDataObj = isGetTaskData.taskActivity;
        if (dynamicDataObj.pesticideType && dynamicDataObj.pesticideType.length > 0) {
          let chosenPestAmountArr = [];
          chosenPestAmountArr = dynamicDataObj.pesticideType.map((item, index) => ({
            pesticideType: item.name,
            pesticideAmount: item.amount,
            pesticideUnit: item.unit ? item.unit : 'ml/daa',
            id: index
          }));
          this.setState({ pesticidesArr: chosenPestAmountArr });
        }
        if (dynamicDataObj.harvestDate) {
          this.setState({ chosenHarvestDate: dynamicDataObj.harvestDate });
        }
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { pesticidesArr } = this.state;
    const { savetaskInfo, fetchManureList, activeFarm } = this.props;
    let newArr = [];
    if (prevState.pesticidesArr !== pesticidesArr) {
      newArr = pesticidesArr.map(item => ({
        amount: item.pesticideAmount,
        name: item.pesticideType,
        unit: item.pesticideUnit
      }));
      savetaskInfo({ pestAmountArr: newArr });
    }
    if (activeFarm !== prevProps.activeFarm) {
      fetchManureList(activeFarm.farm.id);
    }
  }

  /**
   * To get translation message for dynamic fields section
   * based on the activity chosen/populated
   */
  getActivityHeading = actId => {
    const { activityList, intl } = this.props;
    if (activityList.data) {
      return intl.formatMessage({
        id: ActivityTranslation(activityList.data.filter(item => item.id === actId)[0].activityName)
      });
    }
    return '';
  };

  /**
   * To compute dropdown options for different fields which are
   * dependent on other dropdown values
   */
  computeOptions = (field, chosenActId) => {
    const { formValues, activitiesCommon, intl } = this.props;
    if (
      field.options.length === 0 &&
      formValues.fertilizertype &&
      field.name === 'fertilizersubtype'
    ) {
      if (formValues.fertilizertype.value === intl.formatMessage({ id: 'PESTICIDE_MANURE' })) {
        return manureOptions(activitiesCommon);
      }
      if (formValues.fertilizertype.value === intl.formatMessage({ id: 'PESTICIDE_MINERAL_FERTILIZER' })) {
        return mineralFertilizerOptions(activitiesCommon);
      }
      if (formValues.fertilizertype.value === intl.formatMessage({ id: 'PESTICIDE_FOILAR_FERTILIZER' })) {
        return fertilizerSubTypeFoilerDataOptions(activitiesCommon);
      }
      if (formValues.fertilizertype.value === intl.formatMessage({ id: 'PESTICIDE_LIMING' })) {
        return fertilizerSubTypeLimingOptions(activitiesCommon);
      }
    }
    if (field.options.length === 0 && formValues.growthstage && field.name === 'growthstagesub') {
      if (formValues.growthstage.value === KORN) {
        return growthStageSubTypeKornOptions(activitiesCommon);
      }
      if (formValues.growthstage.value === OLJEVEKSTER) {
        return growthStageSubTypeOljeveksterOptions(activitiesCommon);
      }
      if (formValues.growthstage.value === ERTER) {
        return growthStageSubTypeErterOptions(activitiesCommon);
      }
      if (formValues.growthstage.value === POTET) {
        return growthStageSubTypePotetOptions(activitiesCommon);
      }
      if (formValues.growthstage.value === JORDBR) {
        return growthStageSubTypeJordbrOptions(activitiesCommon);
      }
      if (formValues.growthstage.value === EPLER) {
        return growthStageSubTypeEplerOptions(activitiesCommon);
      }
      if (formValues.growthstage.value === IKKEANGITT) {
        return growthStageSubTypeIkkeAngittOptions(activitiesCommon);
      }
    }
    if (
      field.options.length === 0 &&
      formValues.growthstagesub &&
      field.name === 'growthstagesubsubtype'
    ) {
      if (formValues.growthstagesub.value === SPIRING) {
        return growthStageSubSubTypeSpiringOptions(activitiesCommon);
      }
      if (formValues.growthstagesub.value === BLADUTVIKLING_HOVEDSKUDD) {
        return growthStageSubSubTypeBladutviklingOptions(activitiesCommon);
      }
      if (formValues.growthstagesub.value === BUSKING_UTVIKLING_AV_SIDESKUDD) {
        return growthStageSubSubTypeBuskingOptions(activitiesCommon);
      }
      if (formValues.growthstagesub.value === STREKKING_HOVEDSKUDD) {
        return growthStageSubSubTypeStrekkingOptions(activitiesCommon);
      }
      if (formValues.growthstagesub.value === AKS_ELLER_TOPPDANNELSE) {
        return growthStageSubSubTypeAksOptions(activitiesCommon);
      }
      if (formValues.growthstagesub.value === SKYTING_HOVEDSKUDD) {
        return growthStageSubSubTypeSkytingOptions(activitiesCommon);
      }
      if (formValues.growthstagesub.value === BLOMSTRING_HOVEDSKUDD) {
        return growthStageSubSubTypeBlomstringOptions(activitiesCommon);
      }
      if (formValues.growthstagesub.value === FROKJERNE_UTVIKLING) {
        return growthStageSubSubTypeFrkjerneOptions(activitiesCommon);
      }
      if (formValues.growthstagesub.value === MODNING) {
        return growthStageSubSubTypeModningOptions(activitiesCommon);
      }
      if (formValues.growthstagesub.value === VISNING) {
        return growthStageSubSubTypeVisningOptions(activitiesCommon);
      }
    }
    if (field.options.length === 0 && formValues.pestdata && field.name === 'pestsubdata') {
      if (formValues.pestdata.value === UGRAS) {
        return pestUgrasSubTypeOptions(activitiesCommon);
      }
      if (formValues.pestdata.value === POTETSYKDOMMER) {
        return pestPotetsykdommerSubTypeOptions(activitiesCommon);
      }
      if (formValues.pestdata.value === SOPP) {
        return pestSoppSubTypeOptions(activitiesCommon);
      }
      if (formValues.pestdata.value === SKADEDYR) {
        return pestSkadedyrSubTypeOptions(activitiesCommon);
      }
      if (formValues.pestdata.value === MANGELSYKDOMMER) {
        return pestMangelsykdommerSubTypeOptions(activitiesCommon);
      }
      if (formValues.pestdata.value === VEKSTAVSLUTNING) {
        return pestVekstavslutningSubTypeOptions(activitiesCommon);
      }
      if (formValues.pestdata.value === VEKSTREGULERING) {
        return pestVekstreguleringSubTypeOptions(activitiesCommon);
      }
      if (formValues.pestdata.value === BEISING) {
        return pestBeisingSubTypeOptions(activitiesCommon);
      }
    }
    if (
      formValues.fertilizertype &&
      (field.name === 'fertilizerunit' || field.name === 'fertilizerplanunit')
    ) {
      if (
        formValues.fertilizertype.value === intl.formatMessage({ id: 'LIMING' }) ||
        formValues.fertilizertype.value === intl.formatMessage({ id: 'PESTICIDE_MINERAL_FERTILIZER' })
      ) {
        if (chosenActId === ACT20) {
          return act20MineralFertilizerLimingUnitOptions(activitiesCommon);
        }
      }
      if (formValues.fertilizertype.value === intl.formatMessage({ id: 'PESTICIDE_MINERAL_FERTILIZER' })) {
        return mineralFertilizerUnitOptions(activitiesCommon);
      }
      if (formValues.fertilizertype.value === intl.formatMessage({ id: 'PESTICIDE_LIMING' })) {
        return limingUnitOptions(activitiesCommon);
      }
      if (formValues.fertilizertype.value === intl.formatMessage({ id: 'PESTICIDE_MANURE' })) {
        return manourUnitOptions(activitiesCommon);
      }
      if (formValues.fertilizertype.value === intl.formatMessage({ id: 'PESTICIDE_FOILAR_FERTILIZER' })) {
        return foilerFertilizerUnitOptions(activitiesCommon);
      }
    }
    // Getting traslated option for fertilization
    if (
      formValues.activity &&
      formValues.activity.value === ACT7 &&
      field.name === 'fertilizertype'
    ) {
      return field.options.map(option => ({
        value: option.value,
        label: option.label
      }));
    }
    return field.options;
  };

  /**
   * change handler for dropdown
   */
  onDropdownChange = (option, activitiesCommon) => {
    const { change, formValues, intl } = this.props;
    if (
      option.value === intl.formatMessage({ id: 'PESTICIDE_MINERAL_FERTILIZER' }) ||
      option.value === intl.formatMessage({ id: 'PESTICIDE_FOILAR_FERTILIZER' }) ||
      option.value === intl.formatMessage({ id: 'PESTICIDE_LIMING' }) ||
      option.value === intl.formatMessage({ id: 'PESTICIDE_MANURE' })
    ) {
      change('fertilizersubtype', []);
      change('fertilizerunit', []);
      change('fertilizerplanunit', []);
    }
    if (
      formValues.pestsubdata &&
      (option.value === UGRAS ||
        option.value === POTETSYKDOMMER ||
        option.value === SOPP ||
        option.value === SKADEDYR ||
        option.value === MANGELSYKDOMMER ||
        option.value === VEKSTAVSLUTNING ||
        option.value === VEKSTREGULERING ||
        option.value === BEISING)
    ) {
      change('pestsubdata', []);
    }
    if (
      formValues.growthstagesub &&
      (option.value === KORN ||
        option.value === OLJEVEKSTER ||
        option.value === ERTER ||
        option.value === POTET ||
        option.value === JORDBR ||
        option.value === IKKEANGITT ||
        option.value === EPLER)
    ) {
      change('growthstagesub', []);
      change('growthstagesubsubtype', []);
    }
    if (
      formValues.growthstagesubsubtype &&
      (option.value === SPIRING ||
        option.value === BLADUTVIKLING_HOVEDSKUDD ||
        option.value === BUSKING_UTVIKLING_AV_SIDESKUDD ||
        option.value === STREKKING_HOVEDSKUDD ||
        option.value === AKS_ELLER_TOPPDANNELSE ||
        option.value === SKYTING_HOVEDSKUDD ||
        option.value === BLOMSTRING_HOVEDSKUDD ||
        option.value === FROKJERNE_UTVIKLING ||
        option.value === MODNING ||
        option.value === VISNING)
    ) {
      change('growthstagesubsubtype', []);
    }
    if (option.value === intl.formatMessage({ id: 'PESTICIDE_MINERAL_FERTILIZER' })) {
      change('fertilizerunit', mineralFertilizerUnitOptions(activitiesCommon)[0]);
      change('fertilizerplanunit', mineralFertilizerUnitOptions(activitiesCommon)[0]);
    }
    if (option.value === intl.formatMessage({ id: 'PESTICIDE_LIMING' })) {
      change('fertilizerunit', limingUnitOptions(activitiesCommon)[0]);
      change('fertilizerplanunit', limingUnitOptions(activitiesCommon)[0]);
    }
    if (option.value === intl.formatMessage({ id: 'PESTICIDE_MANURE' })) {
      change('fertilizerunit', manourUnitOptions(activitiesCommon)[0]);
      change('fertilizerplanunit', manourUnitOptions(activitiesCommon)[0]);
    }
    if (option.value === intl.formatMessage({ id: 'PESTICIDE_FOILAR_FERTILIZER' })) {
      change('fertilizerunit', foilerFertilizerUnitOptions(activitiesCommon)[0]);
      change('fertilizerplanunit', foilerFertilizerUnitOptions(activitiesCommon)[0]);
    }
  };

  /**
   * To add another pesticide and pesticide amount
   */
  addPesticideSection = () => {
    const { pesticidesArr } = this.state;
    const newObj = {
      ...pesticidesArr[0],
      id: pesticidesArr[pesticidesArr.length - 1].id + 1,
      pesticideType: '',
      pesticideAmount: '',
      pesticideUnit: ''
    };
    this.setState({
      pesticidesArr: pesticidesArr.concat([newObj])
    });
  };

  /**
   * To remove selected pesticide and pesticide amount
   */
  removePesiticideSection = (e, pestItem) => {
    const { pesticidesArr } = this.state;
    this.setState({
      pesticidesArr: pesticidesArr.filter(pestObj => pestItem.id !== pestObj.id)
    });
  };

  /**
   * change hanlder to edit pesticide
   */
  onPestTypeChange = (option, pestItem) => {
    const { pesticidesArr } = this.state;
    const resultArr = pesticidesArr.map(item => {
      if (item.id === pestItem.id) {
        const targetObj = item;
        targetObj.pesticideType = option.value;
        return targetObj;
      }
      return item;
    });
    this.setState({ pesticidesArr: resultArr });
  };

  onPestUnitChange = (option, pestItem) => {
    const { pesticidesArr } = this.state;
    const resultArr = pesticidesArr.map(item => {
      if (item.id === pestItem.id) {
        const targetObj = item;
        targetObj.pesticideUnit = option.value;
        return targetObj;
      }
      return item;
    });
    this.setState({ pesticidesArr: resultArr });
  };

  /**
   * change handler to edit pesticide amount
   */
  onPestAmountChange = (event, pestItem) => {
    const { pesticidesArr } = this.state;
    const resultArr = pesticidesArr.map(item => {
      if (item.id === pestItem.id) {
        const targetObj = item;
        targetObj.pesticideAmount = event.target.value;
        return targetObj;
      }
      return item;
    });
    this.setState({ pesticidesArr: resultArr });
  };

  /**
   * To show date picker on focussing harvest date field
   */
  onFocusShowDatePicker = () => {
    this.setState({ showDatePicker: true });
  };

  /**
   * To hide date picker
   */
  toggleCalendar = () => {
    this.setState({ showDatePicker: false });
  };

  /**
   * call back from custom calendar
   */
  chosenDetails = date => {
    const { savetaskInfo } = this.props;
    if (date) {
      this.setState({ chosenHarvestDate: date });
      savetaskInfo({ harvestDate: date });
    }
  };

  /**
   * populate option for all fields(units)
   */
  populateOptionForUnits = (field, chosenActId) => {
    const { formValues, activitiesCommon, intl } = this.props;
    if (field.name === 'sowingunit') {
      if (chosenActId === ACT1) {
        return act1SowingUnitOptions(activitiesCommon)[0];
      } else if (chosenActId === ACT20) {
        return act20SowingUnitOptions(activitiesCommon)[0];
      } else {
        return sowingUnitOptions(activitiesCommon)[0];
      }
    }
    if (field.name === 'cutunit') {
      if (chosenActId === ACT9) {
        return act9BalePressUnitOptions(activitiesCommon)[0];
      } else if (chosenActId === ACT10) {
        return act10BalePressUnitOptions(activitiesCommon)[0];
      } else if (chosenActId === ACT11) {
        return act11BalePressUnitOptions(activitiesCommon)[0];
      } else if (chosenActId === ACT12) {
        return act12BalePressUnitOptions(activitiesCommon)[0];
      } else if (chosenActId === ACT28) {
        return act28BalePressUnitOptions(activitiesCommon)[0];
      } else {
        return balePressUnitOptions(activitiesCommon)[0];
      }
    }
    if (field.name === 'ensilationunit') {
      if (chosenActId === ACT9) {
        return act9BalePressEnsilationOptions(activitiesCommon)[0];
      } else if (chosenActId === ACT10) {
        return act10BalePressEnsilationOptions(activitiesCommon)[0];
      } else if (chosenActId === ACT11) {
        return act11BalePressEnsilationOptions(activitiesCommon)[0];
      } else if (chosenActId === ACT12) {
        return act12BalePressEnsilationOptions(activitiesCommon)[0];
      } else if (chosenActId === ACT28) {
        return act28BalePressEnsilationOptions(activitiesCommon)[0];
      } else {
        return balePressEnsilationOptions(activitiesCommon)[0];
      }
    }
    if (field.name === 'waterunit') {
      return waterUnitOptions(activitiesCommon)[0];
    }
    if (
      (field.name === 'fertilizerunit' || field.name === 'fertilizerplanunit') &&
      formValues.fertilizertype &&
      (formValues.fertilizertype.value === intl.formatMessage({ id: 'PESTICIDE_MINERAL_FERTILIZER' }) ||
        formValues.fertilizertype.value === intl.formatMessage({ id: 'PESTICIDE_LIMING' }))
    ) {
      if (chosenActId === ACT20) {
        return act20MineralFertilizerLimingUnitOptions(activitiesCommon)[0];
      }
    }
    if (
      (field.name === 'fertilizerunit' || field.name === 'fertilizerplanunit') &&
      formValues.fertilizertype &&
      formValues.fertilizertype.value === intl.formatMessage({ id: 'PESTICIDE_MINERAL_FERTILIZER' })
    ) {
      return mineralFertilizerUnitOptions(activitiesCommon)[0];
    }
    if (
      (field.name === 'fertilizerunit' || field.name === 'fertilizerplanunit') &&
      formValues.fertilizertype &&
      formValues.fertilizertype.value === intl.formatMessage({ id: 'PESTICIDE_LIMING' })
    ) {
      return limingUnitOptions(activitiesCommon)[0];
    }
    if (
      (field.name === 'fertilizerunit' || field.name === 'fertilizerplanunit') &&
      formValues.fertilizertype &&
      formValues.fertilizertype.value === intl.formatMessage({ id: 'PESTICIDE_MANURE' })
    ) {
      return manourUnitOptions(activitiesCommon)[0];
    }
    if (
      (field.name === 'fertilizerunit' || field.name === 'fertilizerplanunit') &&
      formValues.fertilizertype &&
      formValues.fertilizertype.value === intl.formatMessage({ id: 'PESTICIDE_FOILAR_FERTILIZER' })
    ) {
      return foilerFertilizerUnitOptions(activitiesCommon)[0];
    }
    return null;
  };

  /**
   * adding pesticide sections dynamically
   */
  renderPesticideAmountSection = () => {
    const { pesticidesArr } = this.state;
    const { intl, activitiesCommon } = this.props;
    return (
      <div className="multiple-pesticide-section">
        {pesticidesArr.map((item, index) => (
          <div className="multiple-pesticide-sub-section multiple-pesticide-sub-section-bottom" key={item.id}>
            <Field
              name={`pesticidetype_${item.id}`}
              label={intl.formatMessage({ id: 'CT_PESTICIDE' })}
              component={SelectControl}
              styleName="select-box-common"
              placeholder={<FormattedMessage id="CT_PESTICIDE" />}
              options={pesticideTypeOptions(activitiesCommon)}
              onChange={option => this.onPestTypeChange(option, item)}
              controlledValue={
                item.pesticideType ? { label: item.pesticideType, value: item.pesticideType } : null
              }
            />
            <Field
              name={`pesticideamount_${item.id}`}
              label={intl.formatMessage({ id: 'CT_AMOUNT' })}
              component={TextBoxControl}
              stlyeClass="pesticideamount"
              styleName="pesticideamount"
              type="text"
              placeholdertext={intl.formatMessage({ id: 'ENTER_AMOUNT' })}
              onChange={option => this.onPestAmountChange(option, item)}
              controlledValue={item.pesticideAmount}
            />
            <Field
              name={`pesticideunit_${item.id}`}
              label={intl.formatMessage({ id: 'CT_UNIT' })}
              component={SelectControl}
              styleName="select-box-common"
              placeholder={<FormattedMessage id="CT_UNIT" />}
              options={pesticideUnitOptions()}
              onChange={option => this.onPestUnitChange(option, item)}
              controlledValue={
                item.pesticideUnit ? { label: item.pesticideUnit, value: item.pesticideUnit } : null
              }
            />
            <div className="pesticide-section-controls">
              {pesticidesArr.length !== 1 && (
                <div
                  className="delete-icon"
                  onClick={e => this.removePesiticideSection(e, item)}
                  onKeyPress={e => this.removePesiticideSection(e, item)}
                  role="button"
                  tabIndex={-1}
                >
                  <TrashcanIcon scale={1} />
                </div>
              )}
              {(pesticidesArr.length === 1 || index === pesticidesArr.length - 1) && (
                <div
                  className="add-icon"
                  onClick={this.addPesticideSection}
                  onKeyPress={this.addPesticideSection}
                  role="button"
                  tabIndex={-1}
                >
                  <AddIcon scale={1} />
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    );
  };

  render() {
    const {
      chosenActId,
      userInfo,
      intl,
      seasons,
      activitiesCommon,
      locations,
      waterJournalOn,
      changeWaterJournalToggle,
      manureStorage,
      ensilationList
    } = this.props;
    const { showDatePicker, chosenHarvestDate } = this.state;
    const fields = locations !== null && DynamicTemplateFields(chosenActId, activitiesCommon, locations, manureStorage, ensilationList);
    return (
      (fields &&
        <div className="create-task-pesticide-journal">
          <div className="pesticide-journal-heading full-width">
            <span className="black-dot" />
            <span>{this.getActivityHeading(chosenActId)}</span>
          </div>
          <div
            className={`${(chosenActId === ACT9 || chosenActId === ACT10 || chosenActId === ACT11 || chosenActId === ACT18 || chosenActId === ACT12 || chosenActId === ACT28 || chosenActId === ACT16) ? 'dynamic-wrapper1 full-width' : 'dynamic-wrapper full-width'}`}>
            {chosenActId === ACT19 && this.renderPesticideAmountSection()}
            {fields && fields.map(field => {
              if (field.input_type === 'text') {
                return (
                  <Field
                    name={field.name}
                    label={intl.formatMessage({ id: field.label })}
                    component={TextBoxControl}
                    type="text"
                    styleName={`task-margin-top ${field.stlyeClass}`}
                    placeholdertext={intl.formatMessage({ id: field.placeholder })}
                    units={field.units ? intl.formatMessage({ id: field.units }) ? intl.formatMessage({ id: field.units }) : field.units : null}
                    controlledValue=""
                    key={field.name}
                  />
                );
              }
              if (field.input_type === 'dropdown') {
                return (
                  <Field
                    name={field.name}
                    label={intl.formatMessage({ id: field.label })}
                    component={SelectControl}
                    styleName={`select-box-common ${field.stlyeClass}`}
                    placeholder={intl.formatMessage({ id: field.label })}
                    options={this.computeOptions(field, chosenActId)}
                    onChange={v => this.onDropdownChange(v, activitiesCommon)}
                    isMulti={field.isMulti}
                    key={field.name}
                  />
                );
              }
              if (field.input_type === 'seasonDropdown') {
                return (
                  <Field
                    name={field.name}
                    label={intl.formatMessage({ id: field.label })}
                    component={SelectControl}
                    styleName={`select-box-common ${field.stlyeClass}`}
                    placeholder={intl.formatMessage({ id: field.label })}
                    options={getDropDownNameSeasons(seasons.seasonsList)}
                    key={field.name}
                    defaultValue={this.populateOptionForUnits(field, chosenActId)}
                  />
                );
              }
              if (field.input_type === 'slider') {
                return (
                  <SliderComponent
                    heading={field.label}
                    customStyle="effect-slider"
                    name="PJEA"
                    key={field.label}
                    {...this.props}
                  />
                );
              }
              if (field.input_type === 'date') {
                return (
                  <div className="calendar-harvest-date-wrapper" key={field.name}>
                    <Field
                      name={field.name}
                      label={intl.formatMessage({ id: field.label })}
                      component={TextBoxControl}
                      type="text"
                      stlyeClass={field.stlyeClass}
                      placeholdertext={intl.formatMessage({ id: field.placeholder })}
                      onFocus={this.onFocusShowDatePicker}
                      controlledValue={chosenHarvestDate || null}
                    />
                    {showDatePicker && (
                      <CustomCalendar
                        toggleCalendar={this.toggleCalendar}
                        sendDetails={this.chosenDetails}
                        harvestDate
                        harvestDateSelected={chosenHarvestDate}
                        lang={userInfo.data.preferredLanguage}
                      />
                    )}
                  </div>
                );
              }
              if (field.input_type === 'toggle') {
                return (
                  <div className="calendar-harvest-date-wrapper margin-top-water-journal"
                    key={field.name}>
                    <div className="mineralization-calculation-div-less-bottom">
                      {intl.formatMessage({ id: field.label })}
                    </div>
                    <div className="mineralization-calculation-div">
                      <ToggleButton
                        inactiveLabel={intl.formatMessage({ id: 'NO' })}
                        activeLabel={intl.formatMessage({ id: 'YES' })}
                        colors={{
                          inactive: {
                            base: 'rgb(42, 60, 99)',
                            hover: 'rgb(42, 60, 99)'
                          }
                        }}
                        value={waterJournalOn}
                        onToggle={changeWaterJournalToggle}
                      />
                    </div>
                  </div>
                );
              }
              return null;
            })}
          </div>
        </div>)
    );
  }
}

const mapStateToProps = state => ({
  activeFarm: state.farmList.activeFarm,
  activityList: state.ActivityList,
  formValues: getFormValues('CreateTaskForm')(state),
  userInfo: state.userDetails,
  activitiesCommon: state.masterData.data[0]?.activities,
  manureStorage: state.manureStorage,
  ensilationList: state.ensilationList.ensilationTypeData
});

const mapDispatchToProps = dispatch => ({
  savetaskInfo: obj => dispatch(taskActions.savetaskInfo(obj)),
  fetchManureList: farmId => dispatch(ManureAction.fetchManureList(farmId)),
  fetchEnsilationType: () => dispatch(ensilationTypeAction.fetchEnsilationType())
});

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(DynamicSectionForm));
