import * as React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import * as getUserActivitiesAction from 'actions/UserActivities';
import * as updateKartToggleAction from 'actions/KartToggle';
import * as manageKartStyle from 'mui-mobile-components/settings/kart/ManageKartStyle';
import * as taskTemplateStyle from 'mui-mobile-components/kart/fieldmap/tasktemplates/TaskTemplateStyle';
import { Close as CloseIcon } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { Box, Typography, Switch, FormControlLabel, Dialog } from '@mui/material'
import * as Seasons from 'actions/Seasons';
import AddTaskModal from 'mui-mobile-components/settings/kart/AddTaskModal';
import * as baleFeedEventActions from 'actions/BaleFeedEvent';
import * as taskActions from 'actions/Tasks';
import * as taskKartToggleAction from 'actions/TaskKartToggle';
import { template1, template2, template3, template4, template5, template6, template7, template8 } from 'mui-mobile-components/settings/kart/dynamicFields';
import mixpanel from 'mixpanel-browser';
import { CHANGE_STATE_STD_SETTINGS, CLICK_STD_REGISTRATION_TYPE, SAVED_STD_TEMPLATE } from 'constants/MixPanel';
import _ from 'lodash';
import * as ensilationTypeAction from 'actions/EnsilationList';

const ToggleMF = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 52,
  height: 32,
  padding: 0,
  position: 'relative',
  '&:hover .MuiSwitch-thumb': {
    backgroundColor: '#3F4949', // Thumb color change on hover
  },
  '& .MuiSwitch-switchBase': {
    padding: 0,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#ffffff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#005A5C' : '#00696D',
        opacity: 1,
        border: 0,
      },
      '& .MuiSwitch-thumb': {
        backgroundColor: '#FFFFFF',
        width: 24,
        height: 24,
        marginTop: '4px',
        marginRight: '3px',
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.3)',
      },
      '&:hover .MuiSwitch-thumb': {
        backgroundColor: '#6FF6FC', // Thumb color change on hover
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #00696D',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color: '#BDBDBD',
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 16,
    height: 16,
    marginTop: '8px',
    marginLeft: '8px',
    backgroundColor: '#6F7979',
    position: 'relative', // Ensure pseudo-element is positioned correctly
    '&::before': {
      content: '""',
      position: 'absolute',
      top: '50%',
      left: '50%',
      width: '32px', // Default size for the grey circle
      height: '32px', // Default size for the grey circle
      backgroundColor: 'rgba(128, 128, 128, 0.3)', // Grey transparent background
      borderRadius: '50%', // Ensure the grey circle is fully rounded
      transform: 'translate(-50%, -50%)',
      opacity: 0,
      transition: 'opacity 0.2s ease-in-out, width 0.2s, height 0.2s', // Smooth size transition
    },
  },
  '&:hover .MuiSwitch-track': {
    backgroundColor: '#D3D3D3', // Change background when hovering anywhere on switch
    borderRadius: '100px', // Keep the track background rounded
  },
  '& .MuiSwitch-track': {
    borderRadius: '100px', // Ensure the track is rounded
    backgroundColor: '#E6E9E8',
    border: '3px solid #6F7979',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 300,
    }),
  },
}));

class ManageKart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activitiesStatus: {},
      coordinatesState: null,
      onCreateTask: false,
      selectedActivity: [],
      createdVehicleId: "",
      createdEquipmentId: "",
      showSnackBar: false
    };
    this.openNewTask = this.openNewTask.bind(this);
    this.closeNewTask = this.closeNewTask.bind(this);
    this.saveNewTask = this.saveNewTask.bind(this);
  }

  componentDidMount() {
    const { activeFarm, getUserActivities, seasons, seasonsList, userInfo, getLocations, getAllAssets, fetchEnsilationType } = this.props;
    if (activeFarm) {
      getUserActivities(activeFarm.farm.id);
      getLocations(activeFarm.farm.id);
      getAllAssets(userInfo.data.idToken, 'vehicle', activeFarm.farm.id);
      getAllAssets(userInfo.data.idToken, 'equipment', activeFarm.farm.id);
      fetchEnsilationType();
    }
    seasons.seasonsList = null;
    seasonsList();
  }

  componentDidUpdate(prevProps) {
    const { activeFarm, getUserActivities, userActivities, userInfo, taskDetails, getLocations, getAllAssets, nullifyCreateAssetEntitySuccess, taskKartToggle } = this.props;
    if (activeFarm !== prevProps.activeFarm) {
      getUserActivities(activeFarm.farm.id);
      getLocations(activeFarm.farm.id);
      getAllAssets(userInfo.data.idToken, 'vehicle', activeFarm.farm.id);
      getAllAssets(userInfo.data.idToken, 'equipment', activeFarm.farm.id);
    }
    if (userActivities !== prevProps.userActivities) {
      this.initializeActivitiesStatus();
    }
    if (taskDetails.createAssetEntityIsSuccess) {
      getAllAssets(userInfo.data.idToken, 'vehicle', activeFarm.farm.id);
      getAllAssets(userInfo.data.idToken, 'equipment', activeFarm.farm.id);
      if (taskDetails?.createAssetEntityData?.data?.asset?.type.typeId === 'type1') {
        this.setState({
          createdVehicleId: taskDetails?.createAssetEntityData?.data?.asset?.assetId,
        });
      } else {
        this.setState({
          createdEquipmentId: taskDetails?.createAssetEntityData?.data?.asset?.assetId
        });
      }
      nullifyCreateAssetEntitySuccess();
    }
    if (taskDetails.isSuccess) {
      getUserActivities(activeFarm.farm.id);
      this.setState({
        onCreateTask: false,
        createdVehicleId: "",
        createdEquipmentId: ""
      });
      nullifyCreateAssetEntitySuccess();
    }
    if (taskKartToggle !== prevProps.taskKartToggle) {
      const createdVehicle = taskKartToggle?.getTaskList?.vehicleAssetId;
      const createdEquipment = taskKartToggle?.getTaskList?.equipmentAssetId;

      if (createdVehicle) {
        this.setState({
          createdVehicleId: createdVehicle,
          createdEquipmentId: createdEquipment
        });
      }
    }
  }

  initializeActivitiesStatus = () => {
    const { userActivities } = this.props;
    const { userActivitiesList } = userActivities;

    if (!userActivitiesList) return;

    const allActivities = [
      ...(userActivitiesList.harvest?.activities || []),
      ...(userActivitiesList.soil?.activities || []),
      ...(userActivitiesList.fertilization?.activities || []),
      ...(userActivitiesList.others?.activities || []),
    ];

    const activitiesStatus = {};
    allActivities.forEach(activity => {
      activitiesStatus[activity.id] = activity.active;
    });

    this.setState({ activitiesStatus });
  };

  closeNewTask = () => {
    const { getUserActivities, activeFarm } = this.props;
    getUserActivities(activeFarm.farm.id);
    this.setState({
      createdVehicleId: "",
      createdEquipmentId: "",
      onCreateTask: false
    });
  }

  openNewTask = (activity) => {
    const { getKartTask, activeFarm } = this.props;
    getKartTask(activeFarm.farm.id, activity.id);
    this.setState(() => ({
      onCreateTask: true,
      selectedActivity: activity
    }));
  }

  addNewField = (obj, fieldName, fieldValue) => {
    return {
      ...obj,
      [fieldName]: fieldValue
    };
  }

  saveNewTask = (formData) => {
    const { createTask, activeFarm, taskDetails, userInfo } = this.props;
    if (formData.activityId === 'act11' || formData.activityId === 'act12' || formData.activityId === 'act16') {
      formData = template1(formData);
    }
    if (formData.activityId === 'act9' || formData.activityId === 'act10' || formData.activityId === 'act28') {
      formData = template2(formData);
    }
    if (formData.activityId === 'act5' || formData.activityId === 'act14' || formData.activityId === 'act15' ||
      formData.activityId === 'act17' || formData.activityId === 'act18') {
      formData = template3(formData);
    }
    if (formData.activityId === 'act13') {
      formData = template4(formData);
    }
    if (formData.activityId === 'act1') {
      formData = template5(formData);
    }
    if (formData.activityId === 'act20') {
      formData = template6(formData);
    }
    if (formData.activityId === 'act7' || formData.activityId === 'act30' || formData.activityId === 'act31' || formData.activityId === 'act32') {
      formData = template7(formData);
    }
    if (formData.activityId === 'act2' || formData.activityId === 'act4' || formData.activityId === 'act8' || formData.activityId === 'act21' ||
      formData.activityId === 'act22' || formData.activityId === 'act23' || formData.activityId === 'act24' || formData.activityId === 'act25' ||
      formData.activityId === 'act26' || formData.activityId === 'act27' || formData.activityId === 'act29') {
      formData = template8(formData);
    }
    let assetVehicleId = "";
    if (formData['vehicleAssetId']) {
      assetVehicleId = formData['vehicleAssetId'];
    } else {
      assetVehicleId = this.state.createdVehicleId;
    }
    if (assetVehicleId !== "" && assetVehicleId !== null) {
      const selecteItem1 = _.filter(taskDetails?.allVehicles, ['asset.assetId', assetVehicleId]);
      if (selecteItem1.length > 0) {
        formData = this.addNewField(formData, "vehicleAssetAssignmentId", selecteItem1[0].assetAssignmentId);
        formData['vehicleAssetId'] = assetVehicleId;
      }
    }

    let assetEquipmentId = "";
    if (formData['equipmentAssetId']) {
      assetEquipmentId = formData['equipmentAssetId'];
    } else {
      assetEquipmentId = this.state.createdEquipmentId;
    }
    if (assetEquipmentId !== "" && assetEquipmentId !== null) {
      const selecteItem2 = _.filter(taskDetails?.allEquipments, ['asset.assetId', assetEquipmentId]);
      if (selecteItem2.length > 0) {
        formData = this.addNewField(formData, "equipmentAssetAssignmentId", selecteItem2[0].assetAssignmentId);
        formData['equipmentAssetId'] = assetEquipmentId;
      }
    }
    mixpanel.identify(userInfo.data.id);
    mixpanel.track(SAVED_STD_TEMPLATE);
    createTask(activeFarm.farm.id, formData.activityId, formData);
    this.setState(() => ({
      onCreateTask: false,
      selectedActivity: []
    }));
  }

  handleToggleChange = (activityId) => {
    const { updateKartToggle, activeFarm } = this.props;
    this.setState(
      (prevState) => {
        const updatedStatus = {
          ...prevState.activitiesStatus,
          [activityId]: !prevState.activitiesStatus[activityId], // Toggle the activity's state
        };
        return { activitiesStatus: updatedStatus };
      },
      () => {
        const inactiveActivities = this.handleOffActivities();
        updateKartToggle(activeFarm.farm.id, inactiveActivities);
      }
    );
  };

  handleOffActivities = () => {
    const { activitiesStatus } = this.state;
    const { userActivities } = this.props;
    const { userActivitiesList } = userActivities;

    if (!userActivitiesList) return [];

    const allActivities = [
      ...(userActivitiesList.harvest?.activities || []),
      ...(userActivitiesList.soil?.activities || []),
      ...(userActivitiesList.fertilization?.activities || []),
      ...(userActivitiesList.others?.activities || []),
    ];

    // Filter inactive activities and map to their IDs
    return allActivities
      .filter(activity => !activitiesStatus[activity.id])
      .map(activity => activity.id);
  };

  getCategoryActivities = (category) => {
    const { userActivities, userInfo } = this.props;
    const { userActivitiesList } = userActivities;
    let categoryActivities = null;
    if (category === 'harvest') { categoryActivities = userActivitiesList && userActivitiesList.harvest; }
    if (category === 'soil') { categoryActivities = userActivitiesList && userActivitiesList.soil; }
    if (category === 'fertilization') { categoryActivities = userActivitiesList && userActivitiesList.fertilization; }
    if (category === 'others') { categoryActivities = userActivitiesList && userActivitiesList.others; }
    if (categoryActivities && categoryActivities.activities?.length > 0) {
      return (
        <Box>
          <Box sx={{ padding: '20px 10px 0px 20px', display: 'flex', justifyContent: 'flex-start' }}>
            <Typography style={manageKartStyle.modalTitleSubHeader}>{categoryActivities.name}</Typography>
          </Box>
          <Box>
            {categoryActivities.activities.map((userActivities) => {
              return (
                <div className="padding-left-right-important">
                  <div
                    className='padding-bottom-border-important'
                    style={{
                      ...manageKartStyle.toggleMenu,
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      cursor: "pointer"
                    }}
                    onClick={(e) => {
                      const toggleWidth = 60; // Adjust this if the toggle width is different
                      const clickX = e.clientX - e.currentTarget.getBoundingClientRect().left;

                      if (clickX < e.currentTarget.offsetWidth - toggleWidth) {
                        mixpanel.identify(userInfo.data.id);
                        mixpanel.track(CLICK_STD_REGISTRATION_TYPE, { "ActivityID": userActivities.id, "ActivityName": userActivities.activityName });
                        this.openNewTask(userActivities);
                      }
                    }}
                  >
                    <Typography
                      style={manageKartStyle.toggleMenuContent}
                      sx={{ paddingLeft: '6px', minHeight: '56px', flexGrow: 1, paddingTop: '17px' }}
                      onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = "#E6E9E8")}
                      onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = "transparent")}
                    >
                      {userActivities.activityName}
                    </Typography>
                    <Typography sx={{ width: '60px' }}>
                      <Box component="div">
                        <FormControlLabel
                          sx={{ marginRight: '6px !important' }}
                          control={
                            <ToggleMF
                              sx={{ m: 0 }}
                              checked={this.state.activitiesStatus[userActivities.id]}
                              onChange={(e) => {
                                e.stopPropagation();
                                this.handleToggleChange(userActivities.id);
                              }}
                              onClick={() => {
                                mixpanel.identify(userInfo.data.id);
                                mixpanel.track(CHANGE_STATE_STD_SETTINGS, { "ActivityID": userActivities.id, "ActivityName": userActivities.activityName });
                              }}
                            />
                          }
                        />
                      </Box>
                    </Typography>
                  </div>
                </div>
              );
            })
            }
          </Box>
        </Box>
      );
    }
    return null;
  };

  render() {
    const { intl, activeFarm, activitiesCommon, seasons, assetsDetails, locations, taskDetails, taskKartToggle, ensilationList } = this.props;
    return (
      <Box>
        <Dialog
          open={true}
          disablePortal
          disableScrollLock
          disableEnforceFocus
          PaperProps={{
            style: {
              backgroundColor: '#E6E9E8',
              width: '100%',
              minWidth: '100%',
              maxWidth: '100%',
              maxHeight: '100vh',
              overflow: 'hidden',
              height: '100vh'
            },
          }}
        >
          <Box sx={{
            padding: '0px 16px 0px 16px !important',
            overflowY: 'auto',
            '&::-webkit-scrollbar': {
              width: '5px',
              backgroundColor: 'rgba(0, 0, 0, 0.1)',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: '#A8A8A8',
              borderRadius: '4px',
              transition: 'background-color 0.3s ease',
            },
            '&::-webkit-scrollbar-thumb:hover': {
              backgroundColor: '#B8B8B8',
            }
          }}
          >
            <div style={{ paddingTop: '70px', paddingLeft: '20px' }}>
              <Box style={taskTemplateStyle.headerSticky}>
                <Box style={taskTemplateStyle.modalDialogHeader}>
                  <Box style={taskTemplateStyle.modalDialogHeader1}>
                    <Typography sx={{ paddingTop: '5px', paddingRight: '10px' }}>
                      <CloseIcon
                        width="24"
                        height="24"
                        onClick={() => window.history.go(-1)}
                        style={{ cursor: 'pointer' }}
                      />
                    </Typography>
                    <Typography style={taskTemplateStyle.modalDialogTitle}>{intl.formatMessage({ id: 'TOGGLE_SETTINGS_BETA' })}</Typography>
                  </Box>
                </Box>
              </Box>
            </div>
            <Box>
              {this.getCategoryActivities('harvest')}
              <Box sx={{ paddingTop: '48px' }}>&nbsp;</Box>
              {this.getCategoryActivities('soil')}
              <Box sx={{ paddingTop: '48px' }}>&nbsp;</Box>
              {this.getCategoryActivities('fertilization')}
              <Box sx={{ paddingTop: '48px' }}>&nbsp;</Box>
              {this.getCategoryActivities('others')}
            </Box>
          </Box>
        </Dialog>
        <AddTaskModal
          intl={intl}
          activeFarm={activeFarm}
          onCreateTask={this.state.onCreateTask}
          selectedFields={"bf42e850-0cdf-442e-8514-9df7e02ebd56"}
          selectedActivity={this.state.selectedActivity}
          locations={locations}
          taskDetails={taskDetails}
          closeNewTask={this.closeNewTask}
          saveNewTask={this.saveNewTask}
          assetsDetails={assetsDetails}
          createdVehicleId={this.state.createdVehicleId}
          createdEquipmentId={this.state.createdEquipmentId}
          seasons={seasons}
          activitiesCommon={activitiesCommon}
          taskKartToggle={taskKartToggle}
          ensilationList={ensilationList}
        />
      </Box>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getUserActivities: (farmId) => dispatch(getUserActivitiesAction.getUserActivities(farmId)),
  updateKartToggle: (farmId, inactiveActivities) => dispatch(updateKartToggleAction.updateKartToggle(farmId, inactiveActivities)),
  seasonsList: () => dispatch(Seasons.seasonsList()),
  getLocations: (farmId) => dispatch(baleFeedEventActions.getLocations(farmId)),
  getAllAssets: (token, category, farmId) => dispatch(taskActions.getAllAssets(token, category, farmId)),
  createTask: (farmId, activityId, taskData) => dispatch(taskKartToggleAction.updateTaskKartToggle(farmId, activityId, taskData)),
  getKartTask: (farmId, activityId) => dispatch(taskKartToggleAction.getTaskKartToggle(farmId, activityId)),
  nullifyCreateAssetEntitySuccess: () => dispatch(taskActions.nullifyCreateAssetEntitySuccess()),
  fetchEnsilationType: () => dispatch(ensilationTypeAction.fetchEnsilationType())
});

const mapStateToProps = state => ({
  userInfo: state.userDetails,
  activeFarm: state.farmList.activeFarm,
  userActivities: state.userActivities,
  activitiesCommon: state.masterData.data[0]?.activities,
  seasons: state.seasons,
  assetsDetails: state.assetsDetails,
  locations: state.baleFeedEvent.locationData,
  taskDetails: state.taskDetails,
  taskKartToggle: state.taskKartToggle,
  ensilationList: state.ensilationList
});

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(ManageKart));
