import _ from 'lodash';
import {
  FIELD_LIST,
  FIELD_LIST_ERROR,
  FIELD_LIST1,
  FIELD_LIST_ERROR1,
  IS_FIELD_LOADING,
  ADD_UPDATE_FIELD,
  ADD_UPDATE_FIELD_ERROR,
  DELETE_FIELD,
  DELETE_FIELD_ERROR,
  CLEAR_FIELD_MESSAGE,
  SAVE_FIELD_COORDINATES,
  UPDATE_FIELD_BATCH,
  UPDATE_FIELD_BATCH_ERROR,
  IMPORT_FIELD_SHAPE,
  IMPORT_FIELD_SHAPE_ERROR,
  DELETE_FIELD_LIST_SUCCESS,
  DELETE_FIELD_LIST_FAILURE
} from 'constants/store/Fields';
import { PUT, POST, GET, DELETE } from 'constants/index';
import { FIELD_V1, FIELD_V2, FIELD_V3 } from 'constants/ApiUrl';
import apiRequest from 'utils/ApiRequest';
import { GAEvent } from 'utils/GoogleAnalytics';
import {
  GA_ADD_FIELD,
  GA_ADD_FIELD_ERR,
  GA_UPDATE_FIELD,
  GA_DELETE_FIELD,
  GA_CAT_ERR,
  GA_CAT_ADD,
  GA_FIELD_LBL,
  GA_CAT_UPDATE,
  GA_CAT_DETELE
} from 'constants/GoogleAnalytics';

/**
 * Field list action
 * @param {array} data
 */
export const fieldDetails = data => ({
  type: FIELD_LIST,
  payload: data
});

/**
 * error on fetching field list
 * @param {*} error
 */
export const fieldDetailsError = error => ({
  type: FIELD_LIST_ERROR,
  payload: error
});

/**
 * Field list action
 * @param {array} data
 */
export const fieldDetails1 = data => ({
  type: FIELD_LIST1,
  payload: data
});

/**
 * error on fetching field list
 * @param {*} error
 */
export const fieldDetailsError1 = error => ({
  type: FIELD_LIST_ERROR1,
  payload: error
});

/**
 * add or update field
 * @param {object} data
 */
export const addUpdateFieldData = data => ({
  type: ADD_UPDATE_FIELD,
  payload: data
});

/**
 * add or update field error
 * @param {*} error
 */
export const addUpdateFieldError = error => ({
  type: ADD_UPDATE_FIELD_ERROR,
  payload: error
});

/**
 * import field shape
 * @param {object} data
 */
 export const importFieldShapeData = data => ({
  type: IMPORT_FIELD_SHAPE,
  payload: data
});

/**
 * import field shape error
 * @param {*} error
 */
export const importFieldShapeDataError = error => ({
  type: IMPORT_FIELD_SHAPE_ERROR,
  payload: error
});

/**
 * delete field success
 * @param {string} id
 */
export const deleteFieldSuccess = id => ({
  type: DELETE_FIELD,
  payload: id
});

/**
 * delete field error
 * @param {*} error
 */
export const deleteFieldError = error => ({
  type: DELETE_FIELD_ERROR,
  payload: error
});

const deleteFieldListSuccess = (data, deletedTasks) => ({
  type: DELETE_FIELD_LIST_SUCCESS,
  data,
  deletedTasks
});

const deleteFieldListError = data => ({
  type: DELETE_FIELD_LIST_FAILURE,
  payload: data
});

/**
 * farm details loadinf
 * @param {Boolean} bool
 */
export const isLoading = bool => ({
  type: IS_FIELD_LOADING,
  payload: bool
});

/**
 * Clear field action messages
 */
export const clearMessages = () => ({
  type: CLEAR_FIELD_MESSAGE
});

/**
 * fetch Field List dispatch field list api details
 * @param {string} farmId
 */
export const fetchFieldList = farmId => async dispatch => {
  dispatch(isLoading(true));
  await dispatch(
    apiRequest({
      url: `${FIELD_V1}${farmId}/fields`,
      method: GET
    })
  )
    .then(response => {
      let fieldList = response.data;
      /*if (fieldList.length > 1) {
        fieldList = _.sortBy(fieldList, 'fieldName');
      }*/
      dispatch(fieldDetails(fieldList));
    })
    .catch(error => {
      dispatch(fieldDetailsError(error));
    });
};

/**
 * fetch Field List dispatch field list api details
 * @param {string} farmId
 */
export const fetchFieldList1 = farmId => async dispatch => {
  dispatch(isLoading(true));
  await dispatch(
    apiRequest({
      url: `${FIELD_V2}${farmId}/fields`,
      method: GET
    })
  )
    .then(response => {
      let fieldList = response.data;
      if (fieldList.length > 1) {
        fieldList = _.sortBy(fieldList, 'fieldName');
      }
      dispatch(fieldDetails1(fieldList));
    })
    .catch(error => {
      dispatch(fieldDetailsError1(error));
    });
};

/**
 * Add or update field details
 * @param {object} data
 * @param {string} farmId
 */
export const addUpdateField = (data, farmId) => async dispatch => {
  dispatch(isLoading(true));
  const apiUrl = `${FIELD_V3}${farmId}/fields`;
  const method = data?.fieldId ? PUT : POST;
  await dispatch(
    apiRequest({
      url: data?.fieldId ? `${apiUrl}/${data.fieldId}` : apiUrl,
      method,
      data
    })
  )
    .then(response => {
      if (method === POST) {
        GAEvent(GA_ADD_FIELD, GA_CAT_ADD, GA_FIELD_LBL);
      } else {
        GAEvent(GA_UPDATE_FIELD, GA_CAT_UPDATE, GA_FIELD_LBL);
      }
      dispatch(addUpdateFieldData(response.data));
    })
    .catch(error => {
      if (method === POST) {
        GAEvent(GA_ADD_FIELD_ERR, GA_CAT_ERR, GA_FIELD_LBL);
      }
      dispatch(addUpdateFieldError(error));
    });
};

/**
 * Add or update field details
 * @param {object} data
 * @param {string} farmId
 */
export const addUpdateManureField = (data, farmId) => async dispatch => {
  dispatch(isLoading(true));
  const apiUrl = `${FIELD_V2}${farmId}/fields`;
  const method = data?.fieldId ? PUT : POST;
  await dispatch(
    apiRequest({
      url: data?.fieldId ? `${apiUrl}/${data.fieldId}` : apiUrl,
      method,
      data
    })
  )
    .then(response => {
      if (method === POST) {
        GAEvent(GA_ADD_FIELD, GA_CAT_ADD, GA_FIELD_LBL);
      } else {
        GAEvent(GA_UPDATE_FIELD, GA_CAT_UPDATE, GA_FIELD_LBL);
      }
      dispatch(addUpdateFieldData(response.data));
    })
    .catch(error => {
      if (method === POST) {
        GAEvent(GA_ADD_FIELD_ERR, GA_CAT_ERR, GA_FIELD_LBL);
      }
      dispatch(addUpdateFieldError(error));
    });
};

/**
 * delete farm request
 * @param {string} id
 */
export const deleteField = (farmId, fieldId) => async dispatch => {
  dispatch(isLoading(true));
  await dispatch(
    apiRequest({
      url: `${FIELD_V1}${farmId}/fields/${fieldId}`,
      method: 'DELETE'
    })
  )
    .then(response => {
      GAEvent(GA_DELETE_FIELD, GA_CAT_DETELE, GA_FIELD_LBL);
      dispatch(deleteFieldSuccess(fieldId));
    })
    .catch(error => {
      dispatch(deleteFieldError(error));
    });
};

export const fieldBatchUpdate = (data, farmId) => async dispatch => {
  dispatch(isLoading(true));
  await dispatch(
    apiRequest({
      url: `${FIELD_V1}${farmId}/fields/batch`,
      method: PUT,
      data
    })
  )
    .then(response => {
      dispatch({ type: UPDATE_FIELD_BATCH });
      dispatch(fetchFieldList(farmId));
    })
    .catch(error => {
      dispatch({ type: UPDATE_FIELD_BATCH_ERROR, ErrMsg: error });
    });
};

/**
 * Import the field shape
 * @param {object} data
 * @param {string} farmId
 */
 export const importShape = (data, farmId) => async dispatch => {
  dispatch(isLoading(true));
  const apiUrl = `${FIELD_V1}${farmId}/fields/import`;
  const method = POST;
  await dispatch(
    apiRequest({
      url: apiUrl,
      method,
      data
    })
  )
    .then(response => {
      dispatch(importFieldShapeData(response.data));
    })
    .catch(error => {
      dispatch(importFieldShapeDataError(error));
    });
};

/**
 * To save drawn boundary
 * @param {object} mapObj
 */
export const saveFieldCoordinates = mapObj => ({
  type: SAVE_FIELD_COORDINATES,
  payload: mapObj
});

export const deleteListFields = (farmId, data) => async dispatch => {
  dispatch(isLoading(true));
  const apiUrl = `${FIELD_V1}${farmId}/fields/batch`;
  const method = DELETE;
  await dispatch(
    apiRequest({
      url: apiUrl,
      method,
      data
    })
  )
    .then(response => {
      GAEvent(GA_DELETE_FIELD, GA_CAT_DETELE, GA_FIELD_LBL);
      dispatch(deleteFieldListSuccess(response, data));
    })
    .catch(error => {
      dispatch(deleteFieldListError(error));
    });
};
