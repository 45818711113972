import * as React from 'react';
import { Dialog, DialogTitle, Chip, Box, Grid, Typography, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { ErrorIcon } from 'components/mui-forms/MuiIcons';
import MuiTextBox from 'components/mui-forms/MuiTextBox';
import InsufficientFieldTab from 'mui-components/fields/fertilization/InsufficientFieldStorage/InsufficientFieldTab';
import CheckboxButton from '@tine/lib-frontend-components/components/inputs/checkbox-button';

const insufficientTextTitle = {
  paddingTop: "20px",
  fontFamily: "Inter",
  fontSize: "15px",
  fontWeight: "500",
  color: "#191C1C"
};
const insufficientTextSubTitle = {
  paddingTop: "8px",
  fontFamily: "Inter",
  fontSize: "12px",
  fontWeight: "500",
  color: "#3F4949"
};
const buttonStyle = {
  fontFamily: 'Inter',
  fontWeight: 500,
  fontSize: 14,
  color: '#00696D',
  padding: '8px',
  background: 'transparent'
};
const buttonFilledStyle = {
  fontFamily: 'Inter',
  fontWeight: 500,
  fontSize: 14,
  color: '#ffffff',
  padding: '10px',
  backgroundColor: '#00696D'
};
const insufficientIcon = {
  paddingTop: "8px"
};
const insufficientLink = {
  paddingTop: "8px",
  paddingBottom: "12px"
};
const dialogTitleStyle = {
  fontFamily: 'Inter',
  fontWeight: 400,
  fontSize: "18px",
  fontStyle: "normal",
  padding: "25px 30px 15px 15px",
  textAlign: "left",
  color: "#191C1C"
}
const dialogTitleMissingStyle = {
  ...dialogTitleStyle,
  padding: "25px 30px 15px 30px"
}
const dialogSubTitleCheckboxStyle = {
  ...dialogTitleStyle,
  fontSize: "16px",
  fontStyle: "normal",
  padding: "20px 24px",
  color: "#3F4949",
  maxHeight: '400px',
  overflowY: 'auto',
  WebkitOverflowScrolling: 'touch',
  scrollbarWidth: 'thin',
  scrollbarColor: '#6F7979 #E6E9E8'
}
const dialogFieldCheckboxStyle = {
  fontFamily: 'Inter',
  fontWeight: 400,
  textAlign: "left",
  fontSize: "16px",
  fontStyle: "normal",
  color: "#3F4949",
  overflowY: 'auto',
  WebkitOverflowScrolling: 'touch',
  scrollbarWidth: 'thin',
  scrollbarColor: '#6F7979 #E6E9E8'
}
const dialogTitleDialogStyle = { fontFamily: 'Inter', fontWeight: 400, fontSize: "16px", fontStyle: "normal", padding: "25px 20px 20px 30px", textAlign: "center", color: "#191C1C" }
const dialogTitleDialogExportStyle = { fontFamily: 'Inter', fontWeight: 800, fontSize: "18px", fontStyle: "normal", padding: "25px 20px 20px 30px", textAlign: "center", color: "#191C1C" }
const dialogSubTitleStyle = { ...dialogTitleStyle, fontSize: "14px", fontStyle: "normal", color: "#3F4949", paddingLeft: "30px", paddingRight: "30px", paddingBottom: "20px", paddingTop: "0px" }
const inputStyle = { fontFamily: 'Inter', fontWeight: 500, fontSize: "14px", padding: "0px 30px" };

const textStyle = { fontFamily: "Inter", fontWeight: "400", fontSize: "16px", color: "#191C1C" }
const textSmallStyle = { ...textStyle, fontSize: "14px", color: "#3F4949" }
const normalText = { ...textSmallStyle, fontSize: "12px", fontWeight: "600" }
const gridContainerStyle = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  width: '100%'
};
const gridTitleItemStyle = { flex: 3, paddingLeft: 5, paddingRight: 5 };
const gridStyle = { paddingLeft: 12, paddingRight: 12, paddingTop: 13 };
const gridItemStyle = { ...gridStyle };

const FertilizerModalData = ({ intl, fertilizationPlan, displayErrorFields, onCloseDisplayErrorFields, showChangeNameDialog,
  saveChangeName, cancelChangeName, disableChangeName, onChangingPlanName, versionDeleteDialog, noVersionDelete, yesVersionDelete,
  planDeleteDialog, noPlanDelete, yesPlanDelete, createTaskConfirm, closeCreateTaskModel, goToTaskPage, openVersionDialog,
  closeVersionDialog, selectedYearJson, selectedYearFiltered, onClickVersionChange, openInsufficientItemsLink, selectedField,
  onCloseOpenInsufficientItemsLink, onClickOpenInsufficientItemsLink, openGeneratePdf, closeExportModal, stateFertilizationHarvest,
  stateFirstHarvest, stateSecondHarvest, stateThirdHarvest, onChangeExportHarvest, generateSeasonPdfFertilizerPlan }) => (
  <div>
    <Dialog onClose={() => { }} open={displayErrorFields} PaperProps={{
      style: {
        backgroundColor: '#E6E9E8',
        borderRadius: "28px",
        width: "497px",
        minWidth: "280px",
        maxWidth: "560px",
      }
    }}>
      <DialogTitle style={dialogTitleMissingStyle}>{intl.formatMessage({ id: 'FIELD_MISSING_ATTRIBUTES' })}</DialogTitle>
      <Box style={dialogSubTitleCheckboxStyle} sx={{ padding: '20px' }}>
        {displayErrorFields && fertilizationPlan.errorMessage != null && fertilizationPlan.errorMessage.data && fertilizationPlan.errorMessage.data?.length > 0 &&
          fertilizationPlan.errorMessage.data.map((fieldFertilizationItem, index) => (
            <div>
              <Grid container sx={{
                background: "transparent", display: "block",
                textAlign: "center", backgroundColor: '#E0E3E3', padding: '10px', border: '1px solid #BEC8C9'
              }}>
                <Grid item >
                  <Typography style={insufficientTextTitle}>{intl.formatMessage({ id: 'INSUFFICIENT_DATA' })}</Typography>
                  <Typography style={insufficientIcon}><ErrorIcon /></Typography>
                  <Typography style={insufficientTextSubTitle}>
                    {fieldFertilizationItem.missingAttributes.map((missingAttribute) => (
                      <Box component="span" sx={{ paddingRight: '10px', paddingLeft: '10px' }}>
                        {missingAttribute}
                      </Box>
                    ))}
                  </Typography>
                  <Typography style={insufficientLink}>
                    <Chip
                      label={intl.formatMessage({ id: 'MISSING_ATTRIBUTES_TEXT' })}
                      color="primary"
                      style={buttonStyle}
                      sx={{ backgroundColor: 'transparent' }}
                      onClick={() => onClickOpenInsufficientItemsLink(fieldFertilizationItem.fieldId)}
                    />
                  </Typography>
                </Grid>
              </Grid>
              <div>&nbsp;</div>
            </div>
          ))}
      </Box>
      <div style={{ padding: "25px", textAlign: "right" }}>
        <span>
          <Chip
            label={intl.formatMessage({ id: 'cancel' })}
            clickable
            style={buttonStyle}
            sx={{
              backgroundColor: 'transparent',
              '&:hover, &:focus': {
                backgroundColor: 'transparent',
              }
            }}
            disableRipple
            onClick={onCloseDisplayErrorFields}
          />
        </span>
      </div>
    </Dialog>
    <Dialog onClose={() => { }} open={showChangeNameDialog} PaperProps={{
      style: {
        backgroundColor: '#E6E9E8',
        borderRadius: "28px",
        width: "397px",
        minWidth: "280px",
        maxWidth: "560px",
      }
    }}>
      <DialogTitle style={dialogTitleStyle}>{intl.formatMessage({ id: 'FERTILIZATION_CREATE_UPDATE_NAME_TITLE' })}</DialogTitle>
      <Box component="div" style={dialogSubTitleStyle}>
        {intl.formatMessage({ id: 'FERTILIZATION_CREATE_UPDATE_NAME_CONTENT' })}
      </Box>
      <Box style={inputStyle}>
        <Stack className='text-with-padding'>
          <MuiTextBox
            name="createPlanName"
            id="createPlanName"
            label={intl.formatMessage({ id: 'NAME' })}
            variant="outlined"
            showFullWidth="true"
            required={true}
            onChange={onChangingPlanName}
          />
        </Stack>
      </Box>
      <div style={{ padding: "30px 24px", textAlign: "right" }}>
        <span>
          <Chip
            label={intl.formatMessage({ id: 'cancel' })}
            clickable
            style={buttonStyle}
            sx={{
              backgroundColor: 'transparent',
              '&:hover, &:focus': {
                backgroundColor: 'transparent',
              }
            }}
            disableRipple
            onClick={cancelChangeName}
          />
          <Chip
            label={intl.formatMessage({ id: 'SAVE' })}
            clickable
            style={buttonFilledStyle}
            sx={{
              backgroundColor: 'transparent',
              '&:hover, &:focus': {
                backgroundColor: 'transparent',
              }
            }}
            disableRipple
            disabled={disableChangeName}
            onClick={saveChangeName}
          />
        </span>
      </div>
    </Dialog>
    <Dialog open={versionDeleteDialog} onClose={() => { }} PaperProps={{
      style: {
        backgroundColor: '#E6E9E8',
        borderRadius: "28px",
        width: "397px",
        minWidth: "280px",
        maxWidth: "560px",
      }
    }}>
      <DialogTitle style={dialogTitleDialogStyle}>
        <img src="./images/alertIcon.svg" className="alert-icon-size" alt="draw" /><br />
        {intl.formatMessage({ id: 'FERTILIZATION_DELETE_VERSION_CONTENT' })}
      </DialogTitle>
      <div style={{ padding: "10px 30px 20px", textAlign: "right" }}>
        <span>
          <Chip
            label={intl.formatMessage({ id: 'cancel' })}
            clickable
            style={buttonStyle}
            sx={{
              backgroundColor: 'transparent',
              '&:hover, &:focus': {
                backgroundColor: 'transparent',
              }
            }}
            disableRipple
            onClick={noVersionDelete}
          />
          <Chip
            label={intl.formatMessage({ id: 'YES' })}
            clickable
            style={buttonFilledStyle}
            sx={{
              backgroundColor: 'transparent',
              '&:hover, &:focus': {
                backgroundColor: 'transparent',
              }
            }}
            disableRipple
            onClick={yesVersionDelete}
          />
        </span>
      </div>
    </Dialog>
    <Dialog open={planDeleteDialog} onClose={() => { }} PaperProps={{
      style: {
        backgroundColor: '#E6E9E8',
        borderRadius: "28px",
        width: "397px",
        minWidth: "280px",
        maxWidth: "560px",
      }
    }}>
      <DialogTitle style={dialogTitleDialogStyle}>
        <img src="./images/alertIcon.svg" className="alert-icon-size" alt="draw" /><br />
        {intl.formatMessage({ id: 'FERTILIZATION_DELETE_PLAN_CONTENT' })}
      </DialogTitle>
      <div style={{ padding: "10px 30px 20px", textAlign: "right" }}>
        <span>
          <Chip
            label={intl.formatMessage({ id: 'cancel' })}
            clickable
            style={buttonStyle}
            sx={{
              backgroundColor: 'transparent',
              '&:hover, &:focus': {
                backgroundColor: 'transparent',
              }
            }}
            disableRipple
            onClick={noPlanDelete}
          />
          <Chip
            label={intl.formatMessage({ id: 'YES' })}
            clickable
            style={buttonFilledStyle}
            sx={{
              backgroundColor: 'transparent',
              '&:hover, &:focus': {
                backgroundColor: 'transparent',
              }
            }}
            disableRipple
            onClick={yesPlanDelete}
          />
        </span>
      </div>
    </Dialog>
    {/* <Dialog open={createTaskConfirm} onClose={() => { }} PaperProps={{
      style: {
        backgroundColor: '#E6E9E8',
        borderRadius: "28px",
        width: "397px",
        minWidth: "280px",
        maxWidth: "560px",
      }
    }}>
      <DialogTitle style={dialogTitleDialogStyle}>
        {(fertilizationPlan.createTaskData !== null && !fertilizationPlan.createTaskData.status) ?
          intl.formatMessage({ id: 'FERTILIZATION_SUCCESS' }) : ((fertilizationPlan.createTaskData !== null && fertilizationPlan.createTaskData.status) ? intl.formatMessage({ id: 'FERTILIZATION_FAILED' }) : '')}
      </DialogTitle>
      <Box component="div" style={dialogSubTitleStyle}>
        {(fertilizationPlan.createTaskData !== null && !fertilizationPlan.createTaskData.status) ?
          fertilizationPlan.createTaskData : ((fertilizationPlan.createTaskData !== null && fertilizationPlan.createTaskData.status) ? fertilizationPlan.createTaskData.data : '')}
      </Box>
      <div style={{ padding: "10px 30px 20px", textAlign: "right" }}>
        <span>
          <Chip
            label={intl.formatMessage({ id: 'FERTILIZATION_SHOW_CLOSE' })}
            clickable
            style={buttonStyle}
            sx={{
              backgroundColor: 'transparent',
              '&:hover, &:focus': {
                backgroundColor: 'transparent',
              }
            }}
            disableRipple
            onClick={closeCreateTaskModel}
          />
          <Chip
            label={intl.formatMessage({ id: 'FERTILIZATION_SHOW_TASKS' })}
            clickable
            style={buttonFilledStyle}
            sx={{
              backgroundColor: 'transparent',
              '&:hover, &:focus': {
                backgroundColor: 'transparent',
              }
            }}
            disableRipple
            onClick={goToTaskPage}
          />
        </span>
      </div>
    </Dialog> */}
    <Dialog
      open={createTaskConfirm}
      onClose={closeCreateTaskModel}
      PaperProps={{
        style: {
          backgroundColor: '#E6E9E8',
          borderRadius: "28px",
          width: "800px",
          minWidth: "600px",
          maxWidth: "1000px",
          maxHeight: "600px",
        },
      }}
    >
      <DialogTitle
        style={{
          textAlign: 'left',
          padding: '30px 30px 10px',
          fontSize: '20x',
          fontWeight: 600,
          fontFamily: 'Inter'
        }}
      >
        {intl.formatMessage({ id: 'FIELD_FERTILIZATION_MENU3_DIALOG_BOX_TITLE' })}
      </DialogTitle>
      <Box component="div" style={{ padding: '0px 15px', textAlign: 'left' }}>
        <TableContainer
          component={Paper}
          style={{ boxShadow: 'none', backgroundColor: 'transparent' }}
        >
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell style={{ borderBottom: '2px solid #ccc', width: '25%', fontSize: '1rem' }}>Gjødseltype</TableCell>
                <TableCell align="left" style={{ borderBottom: '2px solid #ccc', width: '22%', fontSize: '1rem' }}>Vårgjødsling</TableCell>
                <TableCell align="left" style={{ borderBottom: '2px solid #ccc', width: '18%', fontSize: '1rem' }}>Etter 1. slått</TableCell>
                <TableCell align="left" style={{ borderBottom: '2px solid #ccc', width: '18%', fontSize: '1rem' }}>Etter 2. slått</TableCell>
                <TableCell align="left" style={{ borderBottom: '2px solid #ccc', width: '17%', fontSize: '1rem' }}>Etter 3. slått</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {fertilizationPlan.data &&
                fertilizationPlan.data.seasonFertilizationPlanList &&
                fertilizationPlan.data.seasonFertilizationPlanList.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell
                      component="th"
                      scope="row"
                      style={{ borderBottom: 'none', fontSize: '1rem' }}
                    >
                      {row.name}
                    </TableCell>
                    <TableCell align="left" style={{ borderBottom: 'none', fontSize: '1rem' }}>
                      {row.springSeasonTotal === '0.0 m^3' || row.springSeasonTotal === '0 m^3' ||
                        row.springSeasonTotal === '0.0 kg' || row.springSeasonTotal === '0 kg'
                        ? <span style={{ fontSize: '1rem' }}>-</span>
                        : row.springSeasonTotal}
                    </TableCell>
                    <TableCell align="left" style={{ borderBottom: 'none', fontSize: '1rem' }}>
                      {row.afterFirstSeasonTotal === '0.0 m^3' || row.afterFirstSeasonTotal === '0 m^3' ||
                        row.afterFirstSeasonTotal === '0.0 kg' || row.afterFirstSeasonTotal === '0 kg'
                        ? <span style={{ fontSize: '1rem' }}>-</span>
                        : row.afterFirstSeasonTotal}
                    </TableCell>
                    <TableCell align="left" style={{ borderBottom: 'none', fontSize: '1rem' }}>
                      {row.afterSecondSeasonTotal === '0.0 m^3' || row.afterSecondSeasonTotal === '0 m^3' ||
                        row.afterSecondSeasonTotal === '0.0 kg' || row.afterSecondSeasonTotal === '0 kg'
                        ? <span style={{ fontSize: '1rem' }}>-</span>
                        : row.afterSecondSeasonTotal}
                    </TableCell>
                    <TableCell align="left" style={{ borderBottom: 'none', fontSize: '1rem' }}>
                      {row.afterThirdSeasonTotal === '0.0 m^3' || row.afterThirdSeasonTotal === '0 m^3' ||
                        row.afterThirdSeasonTotal === '0.0 kg' || row.afterThirdSeasonTotal === '0 kg'
                        ? <span style={{ fontSize: '1rem' }}>-</span>
                        : row.afterThirdSeasonTotal}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Box sx={{ padding: '20px 30px 20px', textAlign: 'right' }}>
        <Chip
          label={intl.formatMessage({ id: 'FERTILIZATION_SHOW_CLOSE' })}
          clickable
          sx={{
            fontFamily: 'Inter',
            fontWeight: 500,
            fontSize: 14,
            color: '#00696D',
            padding: '20px 8px',
            backgroundColor: 'transparent',
            borderRadius: '20px',
            transition: 'background-color 0.2s ease-in-out',
            '&:hover': {
              backgroundColor: '#d3d3d3',
            },
            '& .MuiChip-root': {
              borderRadius: '20px',
            },
          }}
          disableRipple
          onClick={closeCreateTaskModel}
        />
      </Box>
    </Dialog>
    <Dialog onClose={() => { }} open={openVersionDialog} PaperProps={{
      style: {
        backgroundColor: '#E6E9E8',
        borderRadius: "28px",
        width: "497px",
        minWidth: "280px",
        maxWidth: "560px",
      }
    }}>
      <DialogTitle style={dialogTitleMissingStyle}>{intl.formatMessage({ id: 'PREVIOUS_VERSION_HEADING' })} - {selectedYearFiltered}</DialogTitle>
      {selectedYearJson != null ?
        <Box component="div" style={dialogSubTitleStyle}>
          {intl.formatMessage({ id: 'PREVIOUS_VERSION_SUB_HEADING' })} {selectedYearFiltered}
        </Box> :
        <Box component="div" style={dialogSubTitleStyle}>
          {intl.formatMessage({ id: 'NO_PREVIOUS_VERSIONS_FOUND' })} {selectedYearFiltered}
        </Box>}
      {selectedYearJson != null ?
        <Box style={dialogSubTitleCheckboxStyle} sx={{ padding: '20px' }}>
          {selectedYearJson.map((selectedYear, index) => (
            <div style={{ borderTop: index !== 0 ? '3px solid #D1D1D1' : '', paddingTop: index !== 0 ? '15px' : '' }}>
              <Grid container style={gridContainerStyle} xs={12} sm={12} onClick={() => onClickVersionChange(selectedYear)} sx={{ cursor: 'pointer' }}>
                <Grid item style={gridTitleItemStyle}>
                  <Typography style={textStyle}>{selectedYear.versionName}</Typography>
                  <Typography style={textSmallStyle}>
                    {intl.formatMessage({ id: 'FIELD_FERTILIZATION_UPDATED_DATE' })}:&nbsp;
                    {selectedYear.lastUpdatedTime}
                  </Typography>
                </Grid>
                <Grid item style={gridItemStyle}>
                  <Typography style={normalText}>
                    {selectedYear.indexName}
                  </Typography>
                </Grid>
              </Grid>
              <div>&nbsp;</div>
            </div>
          ))}
        </Box> : ''}
      <div style={{ padding: "25px", textAlign: "right" }}>
        <span>
          <Chip
            label={intl.formatMessage({ id: 'cancel' })}
            clickable
            style={buttonStyle}
            sx={{
              backgroundColor: 'transparent',
              '&:hover, &:focus': {
                backgroundColor: 'transparent',
              }
            }}
            disableRipple
            onClick={closeVersionDialog}
          />
        </span>
      </div>
    </Dialog>
    <Dialog onClose={() => { }} open={openInsufficientItemsLink} PaperProps={{
      style: {
        backgroundColor: '#E6E9E8',
        borderRadius: "28px",
        width: "80%",
        minWidth: "80%",
        maxWidth: "80%",
        height: "90%"
      }
    }}>
      <DialogTitle style={dialogTitleMissingStyle}>{intl.formatMessage({ id: 'MANURE_DETAILS_TAB' })}</DialogTitle>
      <Box style={dialogFieldCheckboxStyle}>
        {selectedField !== '' &&
          <InsufficientFieldTab
            selectedField={selectedField}
            onCloseOpenInsufficientItemsLink={onCloseOpenInsufficientItemsLink}
          />
        }
      </Box>
    </Dialog>
    <Dialog open={openGeneratePdf} onClose={() => { }} PaperProps={{
      style: {
        backgroundColor: '#E6E9E8',
        borderRadius: "28px",
        width: "397px",
        minWidth: "280px",
        maxWidth: "560px",
      }
    }}>
      <DialogTitle style={dialogTitleDialogExportStyle}>
        {intl.formatMessage({ id: 'CHOOSE_SEASON' })}
      </DialogTitle>
      <Box component="div" style={dialogSubTitleStyle}>
        <CheckboxButton
          id="fertilizationHarvest"
          label="fertilizationHarvest"
          labelText={intl.formatMessage({ id: 'CHOOSE_FERTILIZATION' })}
          stlyeClass="font-style-div1"
          checked={stateFertilizationHarvest}
          onChange={() => onChangeExportHarvest("fertilization", stateFertilizationHarvest)}
        />
      </Box>
      <Box component="div" style={dialogSubTitleStyle}>
        <CheckboxButton
          id="firstHarvest"
          label="firstHarvest"
          labelText={intl.formatMessage({ id: 'CHOOSE_FIRST_HARVEST' })}
          stlyeClass="font-style-div1"
          checked={stateFirstHarvest}
          onChange={() => onChangeExportHarvest("first", stateFirstHarvest)}
        />
      </Box>
      <Box component="div" style={dialogSubTitleStyle}>
        <CheckboxButton
          id="secondHarvest"
          label="secondHarvest"
          labelText={intl.formatMessage({ id: 'CHOOSE_SECOND_HARVEST' })}
          stlyeClass="font-style-div1"
          checked={stateSecondHarvest}
          onChange={() => onChangeExportHarvest("second", stateSecondHarvest)}
        />
      </Box>
      <Box component="div" style={dialogSubTitleStyle}>
        <CheckboxButton
          id="thirdHarvest"
          label="thirdHarvest"
          labelText={intl.formatMessage({ id: 'CHOOSE_THIRD_HARVEST' })}
          stlyeClass="font-style-div1"
          checked={stateThirdHarvest}
          onChange={() => onChangeExportHarvest("third", stateThirdHarvest)}
        />
      </Box>
      <div style={{ padding: "10px 30px 20px", textAlign: "right" }}>
        <span>
          <Chip
            label={intl.formatMessage({ id: 'FERTILIZATION_SHOW_CLOSE' })}
            clickable
            style={buttonStyle}
            sx={{
              backgroundColor: 'transparent',
              '&:hover, &:focus': {
                backgroundColor: 'transparent',
              }
            }}
            disableRipple
            onClick={closeExportModal}
          />
          <Chip
            label={intl.formatMessage({ id: 'REPORTS_EXPORT' })}
            clickable
            style={buttonFilledStyle}
            sx={{
              backgroundColor: 'transparent',
              '&:hover, &:focus': {
                backgroundColor: 'transparent',
              }
            }}
            disableRipple
            disabled={!stateFertilizationHarvest && !stateFirstHarvest && !stateSecondHarvest && !stateThirdHarvest}
            onClick={generateSeasonPdfFertilizerPlan}
          />
        </span>
      </div>
    </Dialog>
    <Dialog open={openGeneratePdf} onClose={() => { }} PaperProps={{
      style: {
        backgroundColor: '#E6E9E8',
        borderRadius: "28px",
        width: "397px",
        minWidth: "280px",
        maxWidth: "560px",
      }
    }}>
      <DialogTitle style={dialogTitleDialogExportStyle}>
        {intl.formatMessage({ id: 'CHOOSE_SEASON' })}
      </DialogTitle>
      <Box component="div" style={dialogSubTitleStyle}>
        <CheckboxButton
          id="fertilizationHarvest"
          label="fertilizationHarvest"
          labelText={intl.formatMessage({ id: 'CHOOSE_FERTILIZATION' })}
          stlyeClass="font-style-div1"
          checked={stateFertilizationHarvest}
          onChange={() => onChangeExportHarvest("fertilization", stateFertilizationHarvest)}
        />
      </Box>
      <Box component="div" style={dialogSubTitleStyle}>
        <CheckboxButton
          id="firstHarvest"
          label="firstHarvest"
          labelText={intl.formatMessage({ id: 'CHOOSE_FIRST_HARVEST' })}
          stlyeClass="font-style-div1"
          checked={stateFirstHarvest}
          onChange={() => onChangeExportHarvest("first", stateFirstHarvest)}
        />
      </Box>
      <Box component="div" style={dialogSubTitleStyle}>
        <CheckboxButton
          id="secondHarvest"
          label="secondHarvest"
          labelText={intl.formatMessage({ id: 'CHOOSE_SECOND_HARVEST' })}
          stlyeClass="font-style-div1"
          checked={stateSecondHarvest}
          onChange={() => onChangeExportHarvest("second", stateSecondHarvest)}
        />
      </Box>
      <Box component="div" style={dialogSubTitleStyle}>
        <CheckboxButton
          id="thirdHarvest"
          label="thirdHarvest"
          labelText={intl.formatMessage({ id: 'CHOOSE_THIRD_HARVEST' })}
          stlyeClass="font-style-div1"
          checked={stateThirdHarvest}
          onChange={() => onChangeExportHarvest("third", stateThirdHarvest)}
        />
      </Box>
      <div style={{ padding: "10px 30px 20px", textAlign: "right" }}>
        <span>
          <Chip
            label={intl.formatMessage({ id: 'FERTILIZATION_SHOW_CLOSE' })}
            clickable
            style={buttonStyle}
            sx={{
              backgroundColor: 'transparent',
              '&:hover, &:focus': {
                backgroundColor: 'transparent',
              }
            }}
            disableRipple
            onClick={closeExportModal}
          />
          <Chip
            label={intl.formatMessage({ id: 'REPORTS_EXPORT' })}
            clickable
            style={buttonFilledStyle}
            sx={{
              backgroundColor: 'transparent',
              '&:hover, &:focus': {
                backgroundColor: 'transparent',
              }
            }}
            disableRipple
            disabled={!stateFertilizationHarvest && !stateFirstHarvest && !stateSecondHarvest && !stateThirdHarvest}
            onClick={generateSeasonPdfFertilizerPlan}
          />
        </span>
      </div>
    </Dialog>
  </div>
);

export default FertilizerModalData;