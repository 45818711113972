import * as React from 'react';
import { Dialog, DialogTitle, Chip, Box, TextField, Grid, styled, InputAdornment } from '@mui/material';
import { buttonStyle, buttonFilledStyle } from 'mui-components/fields/fertilization/services/fertilizationStyles';
import * as ManureAction from 'actions/ManureStorage';
import { connect } from 'react-redux';
import { DecimalValidation } from 'utils/index';
import { Field, reduxForm } from 'redux-form';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';

const dialogTitleStyle = {
  fontFamily: 'Inter',
  fontWeight: 400,
  fontSize: '24px',
  padding: '25px 30px 20px 30px',
  textAlign: 'left',
  color: '#191C1C',
};

const StyledTextField = styled(TextField)({
  '& label.Mui-focused': {
    color: '#003300',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#003300',
  },
  '& .MuiOutlinedInput-root': {
    '&.Mui-focused fieldset': {
      borderColor: '#003300',
    },
  },
});

const renderTextField = ({ input, label, meta: { touched, error }, unit, ...custom }) => (
  <StyledTextField
    label={label}
    variant="outlined"
    fullWidth
    {...input}
    {...custom}
    error={touched && error}
    helperText={touched && error}
    margin="normal"
    InputProps={{
      endAdornment: unit ? <InputAdornment position="end">{unit}</InputAdornment> : null,
    }}
  />
);

class ManureStorageModalWindow extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmitForManureStorage = this.handleSubmitForManureStorage.bind(this);
    this.state = {
      openDialogWindowManureStorage: false,
      errorMsg: '',
      isNameTaken: false,
    };
  }

  componentDidUpdate() {
    const { openDialogWindowManureStorage } = this.state;
    const { activeFarm, manureStorage, fetchManureType } = this.props;
    const { submitSuccess } = manureStorage;

    if (submitSuccess && openDialogWindowManureStorage) {
      fetchManureType(activeFarm.farm.id);
      this.setState({ openDialogWindowManureStorage: false });
      manureStorage.submitSuccess = false;
    }
  }

  handleSubmitForManureStorage = async () => {
    const { activeFarm, createManure, formValues } = this.props;

    if (this.props.invalid) return;
    if (!formValues?.ManureStorageModalWindow?.values) return;

    let formData = {
      farmId: activeFarm.farm.id,
      ...formValues.ManureStorageModalWindow.values,
    };

    try {
      await createManure(activeFarm.farm.id, formData);
      this.setState({ errorMsg: '', isNameTaken: false });
    } catch (error) {
      console.error('Error creating manure:', error);

      if (error.response?.status === 400 && error.response.data.message.includes('already exists')) {
        this.setState({ errorMsg: 'This name is already in use. Please choose a different name.', isNameTaken: true });
      } else {
        this.setState({ errorMsg: 'An error occurred while creating manure.' });
      }
    }
  };

  render() {
    const { intl, openDialogWindowManureStorage, closeDialogWindowManureStorage, manureStorage } = this.props;

    return (
      <Dialog
        open={openDialogWindowManureStorage}
        onClose={closeDialogWindowManureStorage}
        PaperProps={{
          style: {
            backgroundColor: '#E6E9E8',
            borderRadius: '28px',
            width: '450px',
            minWidth: '280px',
            maxWidth: '560px',
            overflowY: 'visible', // Ensure scrolling works inside the modal
          },
        }}
      >
        <DialogTitle style={dialogTitleStyle}>{intl.formatMessage({ id: 'create' })}</DialogTitle>

        {/* Scrollable Container */}
        <Box
          sx={{
            maxHeight: '420px', // Ensure height limit
            overflowY: 'auto',
            padding: '0px 24px',
            '&::-webkit-scrollbar': {
              width: '8px',
              backgroundColor: 'transparent',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: '#A8A8A8',
              borderRadius: '4px',
              transition: 'background-color 0.3s ease',
            },
            '&::-webkit-scrollbar-thumb:hover': {
              backgroundColor: '#B8B8B8',
            },
          }}
        >
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, padding: '10px' }}>
            <Grid container spacing={1}>
              <Grid item xs={12}><Field name="name" label={intl.formatMessage({ id: 'NAME' })} component={renderTextField} /></Grid>
              <Grid item xs={12}><Field name="nitrogen" label={intl.formatMessage({ id: 'NITROGEN' })} component={renderTextField} unit="kg/ton" /></Grid>
              <Grid item xs={12}><Field name="ammonium" label={intl.formatMessage({ id: 'AMMONIUM' })} component={renderTextField} unit="kg/ton" /></Grid>
              <Grid item xs={12}><Field name="potassium" label={intl.formatMessage({ id: 'POTASSIUM' })} component={renderTextField} unit="kg/ton" /></Grid>
              <Grid item xs={12}><Field name="phosphorus" label={intl.formatMessage({ id: 'PHOSPHORUS' })} component={renderTextField} unit="kg/ton" /></Grid>
              <Grid item xs={12}><Field name="sulfur" label={intl.formatMessage({ id: 'SULFUR' })} component={renderTextField} unit="kg/ton" /></Grid>
            </Grid>
          </Box>
        </Box>

        {/* Error Message */}
        {manureStorage?.hasManureTypeError || this.state.isNameTaken ? (
          <div style={{ color: '#CC0000', padding: '10px 30px' }}>
            {manureStorage?.hasManureTypeError && manureStorage.errorTypeMsg.data}
            {this.state.isNameTaken && <div>{this.state.errorMsg}</div>}
          </div>
        ) : null}

        {/* Action Buttons */}
        <div style={{ padding: '30px 24px', textAlign: 'right' }}>
          <Chip label={intl.formatMessage({ id: 'cancel' })} clickable style={buttonStyle} onClick={closeDialogWindowManureStorage} />
          <Chip label={intl.formatMessage({ id: 'SAVE' })} clickable style={buttonFilledStyle} disabled={this.props.invalid} onClick={this.handleSubmitForManureStorage} />
        </div>
      </Dialog>
    );
  }
}

const validate = (values, props) => {
  const errors = {};
  const { intl } = props;

  if (!values.name?.trim()) errors.name = intl.formatMessage({ id: 'AFN_RQ' });

  ['nitrogen', 'ammonium', 'phosphorus', 'potassium', 'sulfur'].forEach(field => {
    if (!values[field] || !DecimalValidation(values[field])?.valid) {
      errors[field] = intl.formatMessage({ id: 'invalid' });
    }
  });

  return errors;
};

export default injectIntl(reduxForm({ form: 'ManureStorageModalWindow', validate })(
  withRouter(connect(
    state => ({ activeFarm: state.farmList.activeFarm, manureStorage: state.manureStorage, formValues: state.form }),
    dispatch => ({ createManure: (farmId, values) => dispatch(ManureAction.createManure(farmId, values)) })
  )(ManureStorageModalWindow))
));
