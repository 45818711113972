import React from 'react';
import { Redirect } from 'react-router-dom';
import TrashcanIcon from '@tine/lib-frontend-components/elements/icons/trashcan-icon/index';
import { FormattedMessage, injectIntl } from 'react-intl';
import { reduxForm, SubmissionError } from 'redux-form';
import ErrorText from '@tine/lib-frontend-components/components/errors/error-text';
import { connect } from 'react-redux';
import _ from 'lodash';
import ConfirmDialogBox from 'components/ConfirmDialogBox';
import IconButton from '@tine/lib-frontend-components/components/buttons/icon-button';
import Header from 'mui-components/header';
import FieldActivity from 'containers/tasks/tasklist/FieldActivity';
import TimeRecorded from 'containers/tasks/tasklist/TimeRecorded';
import AssigneeNotes from 'containers/tasks/tasklist/AssigneeNotes';
import DrivingPattern from 'containers/tasks/create/DrivingPattern';
import TaskSubmit from 'containers/tasks/create/TaskSubmit';
import * as taskActions from 'actions/Tasks';
import * as getTaskAction from 'actions/GetTask';
import Loader from 'components/Loader';
import * as Seasons from 'actions/Seasons';
import * as baleFeedEventActions from 'actions/BaleFeedEvent';
import mixpanel from 'mixpanel-browser';
import { TASK_COMPLETED } from 'constants/MixPanel';
import { ADMIN, OWNER, ADVISOR } from 'constants/index';
import SectionContainer from '@tine/lib-frontend-components/components/alignment/section-container';
import { regulationList } from 'actions/Regulations';
import * as ManureAction from 'actions/ManureStorage';
import { Box } from '@mui/material';
import {
  PLANNED,
  ACT1,
  ACT5,
  ACT7,
  ACT9,
  ACT10,
  ACT11,
  ACT12,
  ACT13,
  ACT14,
  ACT15,
  ACT16,
  ACT17,
  ACT18,
  ACT19,
  ACT20,
  ACT28,
  ACT30,
  ACT31,
  ACT32,
  COMPLETED
} from 'constants/index';
import {
  ActivityTranslation,
  FertilizerTypeTranslation,
  ManureTypeTranslation,
  SelectAllOption
} from 'constants/DropdownAndTranslationOptions';
import * as fieldActions from 'actions/Fields';
import DynamicSectionForm from 'containers/tasks/tasklist/DynamicSectionForm';
import WaterJournalForm from 'containers/tasks/tasklist/WaterJournalForm';
import {
  DynamicPayloadAttributesObj,
  waterUnitOptions,
  mineralFertilizerLimingUnitOptions
} from 'utils/DynamicFields';
import validate from 'utils/DynamicInputValidation';

class CreateTask extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dynamicSecActId: null,
      paramId: props.match.params.id,
      taskStatus: PLANNED,
      deleteConfirmShow: false,
      completeTask: false,
      initializeData: false,
      showPopupHarvest: false,
      handleValues: null,
      editValueObject: null,
      notSelectedError: false,
      waterJournalOn: false,
      waterEquipmentUsed: true,
      checkLessThan2PCSlope: false,
      checkVegetatedBufferExists: false,
      checkNozzlesProvideTargetMovement: false,
      checkDamperSetForOptimalDistribution: false,
      checkOneEndClosedToAvoidSurfaceWater: false,
      paramFieldId: props.match.params.fieldId ? props.match.params.fieldId : null
    };
    this.updateAgreementError = this.updateAgreementError.bind(this);
  }

  componentDidMount() {
    const { paramId } = this.state;
    const {
      history,
      editTask,
      nullifySuccess,
      initialize,
      fetchFieldList,
      activeFarm,
      savetaskInfo,
      seasons,
      seasonsList,
      getTask,
      activitiesCommon,
      getLocations,
      getUserRegulations,
      userDetails,
      fetchManureList
    } = this.props;
    getTask(activeFarm.farm.id, paramId);
    fetchManureList(activeFarm.farm.id);
    getUserRegulations(userDetails.id);
    if (!this.props.editTaskDetails && paramId !== 'create') {
      history.goBack();
    }
    if (paramId === 'create') {
      const values = {
        field: '',
        activity: '',
        //sowingunit: sowingUnitOptions(activitiesCommon)[0],
        mineralfertilizerunit: mineralFertilizerLimingUnitOptions(activitiesCommon)[0],
        /*fertilizerunit: mineralFertilizerLimingUnitOptions(activitiesCommon)[0],
        fertilizerplanunit: mineralFertilizerLimingUnitOptions(activitiesCommon)[0],
        cutunit: balePressUnitOptions(activitiesCommon)[0],
        ensilationunit: balePressEnsilationOptions(activitiesCommon)[0],*/
        waterunit: waterUnitOptions(activitiesCommon)[0]
      };
      initialize(values);
      // resetting values on create task
      const newTaskObj = {};
      newTaskObj.wind = '';
      newTaskObj.eoa = '';
      newTaskObj.harvestDate = '';
      newTaskObj.weather = '';
      newTaskObj.sDate = '';
      newTaskObj.eDate = '';
      savetaskInfo(newTaskObj);
      editTask({});
    }
    nullifySuccess();
    if (activeFarm) {
      fetchFieldList(activeFarm.farm.id);
    }
    seasons.seasonsList = null;
    seasonsList();
    getLocations(activeFarm.farm.id);
  }

  /*static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.activeFarm !== prevState.activeFarm && nextProps.activeFarm !== undefined) {
      return { activeFarm: nextProps.activeFarm };
    }
    if (nextProps.taskDetails.editTaskObj !== prevState.editTaskObj) {
      return { editTaskObj: nextProps.taskDetails.editTaskObj };
    }
    return null;
  }*/

  componentDidUpdate(prevProps) {
    const { history, activeFarm, seasons, editTaskDetails, locations, fieldDetails, fetchManureList } = this.props;
    const { data } = fieldDetails;
    const { initializeData, paramId } = this.state;
    if (prevProps.activeFarm !== activeFarm) {
      fetchManureList(activeFarm.farm.id);
      history.goBack();
    }
    if (editTaskDetails.isGetTaskFailure && paramId !== 'create') {
      history.goBack();
    }
    if (!initializeData && editTaskDetails.isGetTaskSuccess && seasons.seasonsList != null && locations && data) {
      this.initializeValues();
    }
  }

  // To initialize values in redux form
  initializeValues = () => {
    const { initialize, intl, savetaskInfo, seasons, editTaskDetails, locations, fieldDetails } = this.props;
    const { data } = fieldDetails;
    const { paramId } = this.state;
    const { isGetTaskData } = editTaskDetails;
    const initObj = {};
    this.setState({ initializeData: true });
    if (isGetTaskData && paramId !== 'create') {
      this.setState({ taskStatus: isGetTaskData.status });
      let selectedVehicleOption = {};
      let selectedEquipmentOption = {};
      let selectedAssigneeOption = {};

      //assigning the water journal values
      if (isGetTaskData.waterJournal !== null) {
        this.setState({
          waterJournalOn: true
        });
        if (isGetTaskData.waterJournal.sprayerType === 'FIELD_SPRAYER') {
          this.setState({
            waterEquipmentUsed: true
          });
          if (isGetTaskData.waterJournal.lessThan2PCSlope) {
            this.setState({
              checkLessThan2PCSlope: true
            });
          }
          initObj.distanceToWaterBody = isGetTaskData.waterJournal.distanceToWaterBody;
          initObj.distanceToWaterWell = isGetTaskData.waterJournal.distanceToWaterWell;
          initObj.reqdVegetatedBufferZone = isGetTaskData.waterJournal.reqdVegetatedBufferZone;
          initObj.nozzleName = isGetTaskData.waterJournal.nozzleName;
          initObj.driftReduction = isGetTaskData.waterJournal.driftReduction;
          initObj.maxDrivingSpeed = isGetTaskData.waterJournal.maxDrivingSpeed;
          initObj.pressure = isGetTaskData.waterJournal.pressure;
          initObj.nozzleHeight = isGetTaskData.waterJournal.nozzleHeight;
        } else {
          this.setState({
            waterEquipmentUsed: false
          });
          if (isGetTaskData.waterJournal.vegetatedBufferExists) {
            this.setState({
              checkVegetatedBufferExists: true
            });
          }
          if (isGetTaskData.waterJournal.nozzlesProvideTargetMovement) {
            this.setState({
              checkNozzlesProvideTargetMovement: true
            });
          }
          if (isGetTaskData.waterJournal.damperSetForOptimalDistribution) {
            this.setState({
              checkDamperSetForOptimalDistribution: true
            });
          }
          if (isGetTaskData.waterJournal.oneEndClosedToAvoidSurfaceWater) {
            this.setState({
              checkOneEndClosedToAvoidSurfaceWater: true
            });
          }
          initObj.distanceToWaterBody = isGetTaskData.waterJournal.distanceToWaterBody;
          initObj.distanceToWaterWell = isGetTaskData.waterJournal.distanceToWaterWell;
          initObj.locationAndDistance = isGetTaskData.waterJournal.locationAndDistance;
          initObj.cultureBySpray = isGetTaskData.waterJournal.cultureBySpray;
          initObj.fieldAge = isGetTaskData.waterJournal.fieldAge;
          initObj.developmentStage = isGetTaskData.waterJournal.developmentStage;
          initObj.reqdDistanceToSurfaceWater = isGetTaskData.waterJournal.reqdDistanceToSurfaceWater;
          initObj.reqdVegetatedBufferZone = isGetTaskData.waterJournal.reqdVegetatedBufferZone;
          initObj.fogSprayerModel = isGetTaskData.waterJournal.fogSprayerModel;
          initObj.nozzleName = isGetTaskData.waterJournal.nozzleName;
          initObj.driftReduction = isGetTaskData.waterJournal.driftReduction;
          initObj.maxDrivingSpeed = isGetTaskData.waterJournal.maxDrivingSpeed;
          initObj.pressure = isGetTaskData.waterJournal.pressure;
          initObj.amount = isGetTaskData.waterJournal.amount;
          initObj.ptoSpeed = isGetTaskData.waterJournal.ptoSpeed;
          initObj.sprayingTechnique = isGetTaskData.waterJournal.sprayingTechnique;
        }
      }
      if (isGetTaskData.fieldIds !== null && isGetTaskData.fieldIds.length > 0) {
        let selectedFieldOptionArray = [];
        let selectedFieldOption = {};
        isGetTaskData.fieldIds.forEach(fieldIdValue => {
          const [selectedF] = _.filter(data, ['fieldId', fieldIdValue]);
          selectedFieldOption = {
            value: selectedF.fieldId,
            label: selectedF.fieldName
          };
          selectedFieldOptionArray.push(selectedFieldOption);
        });
        initObj.field = selectedFieldOptionArray;
      }
      else if (isGetTaskData.fieldId !== null) {
        const selectedFieldOption = {
          value: isGetTaskData.fieldId,
          label: isGetTaskData.fieldName
        };
        initObj.field = selectedFieldOption;
      }
      if (isGetTaskData.activityId !== null) {
        const selectedActivityOption = {
          value: isGetTaskData.activityId,
          label: intl.formatMessage({ id: ActivityTranslation(isGetTaskData.activityName) })
        };
        this.showDynamicSection(selectedActivityOption);
        initObj.activity = selectedActivityOption;
      }
      if (isGetTaskData.vehicleAssetId !== null) {
        selectedVehicleOption = {
          value: isGetTaskData.vehicleAssetId,
          label: `${isGetTaskData.vehicleAssetBrand} ${isGetTaskData.vehicleAssetModel}`
        };
        initObj.vehicle = selectedVehicleOption;
      }
      if (isGetTaskData.equipmentAssetId !== null) {
        selectedEquipmentOption = {
          value: isGetTaskData.equipmentAssetId,
          label: `${isGetTaskData.equipmentAssetBrand} ${isGetTaskData.equipmentAssetModel}`
        };
        initObj.equipment = selectedEquipmentOption;
      }
      if (isGetTaskData.assigneeId !== null) {
        selectedAssigneeOption = {
          value: isGetTaskData.assigneeId,
          label: `${isGetTaskData.assigneeFirstName} ${isGetTaskData.assigneeLastName}`
        };
        initObj.assignee = selectedAssigneeOption;
      }
      if (isGetTaskData.assigneeId === null || isGetTaskData.assigneeId === '') {
        selectedAssigneeOption = {
          value: null,
          label: <FormattedMessage id="ALL" />
        };
        initObj.assignee = selectedAssigneeOption;
      }
      initObj.notes = isGetTaskData.notes;
      if (isGetTaskData) {
        const dynamicValues = {};
        dynamicValues.weather = isGetTaskData.weather || '';
        if (isGetTaskData) {
          const { taskActivity } = isGetTaskData;
          const dynamicDataObj = taskActivity;
          if (dynamicDataObj) {
            this.setState({
              editValueObject: dynamicDataObj
            });
            const mappingObj = DynamicPayloadAttributesObj(isGetTaskData.activityId);
            // To populate dropdown form values
            Object.keys(dynamicDataObj).map(item => {
              if (
                item === 'activityName' ||
                item === 'activityId' ||
                item === 'pesticide' ||
                item === 'harvestDate'
              ) {
                return true;
              }
              if (
                item === 'unit' ||
                item === 'cut' ||
                item === 'mineralFertiType' ||
                item === 'comboFertiUnit' ||
                item === 'comboSowingUnit' ||
                item === 'type' ||
                item === 'subType' ||
                item === 'ensilationUnit' ||
                item === 'ensilationText' ||
                item === 'cutUnit' ||
                item === 'waterUnit' ||
                item === 'pestData' ||
                item === 'growthStage' ||
                item === 'growthStageSub' ||
                item === 'growthStageSubSubType' ||
                item === 'planUnit' ||
                item === 'season' ||
                item === 'locationId' ||
                item === 'manureStorageId'
              ) {
                let unitObj = {
                  label: dynamicDataObj[item],
                  value: dynamicDataObj[item]
                };
                if (
                  (item === 'season') &&
                  (isGetTaskData.activityId === ACT7 || isGetTaskData.activityId === ACT30 ||
                    isGetTaskData.activityId === ACT31 || isGetTaskData.activityId === ACT32)
                ) {
                  let seasonName = dynamicDataObj[item];
                  if (seasons.seasonsList) {
                    Object.entries(seasons.seasonsList).map(([key, value]) => {
                      if (value.id === dynamicDataObj[item]) {
                        seasonName = value.season;
                      }
                      return true;
                    });
                  }
                  unitObj = {
                    label: seasonName,
                    value: dynamicDataObj[item]
                  };
                }
                if (item === 'manureStorageId' && isGetTaskData.activityId === ACT30) {
                  unitObj = {
                    label: dynamicDataObj['manureStorageName'],
                    value: dynamicDataObj[item]
                  };
                }
                if (
                  (item === 'locationId') &&
                  (isGetTaskData.activityId === ACT11 || isGetTaskData.activityId === ACT12 || isGetTaskData.activityId === ACT16 || isGetTaskData.activityId === ACT18)
                ) {
                  let locationName = dynamicDataObj[item];
                  if (locations) {
                    locations.map(location => {
                      if (location.id === dynamicDataObj[item]) {
                        locationName = location.name;
                      }
                      return true;
                    });
                  }
                  unitObj = {
                    label: locationName,
                    value: dynamicDataObj[item]
                  };
                }
                if (
                  (item === 'type' || item === 'subType') &&
                  (isGetTaskData.activityId === ACT7 || isGetTaskData.activityId === ACT31 || isGetTaskData.activityId === ACT32)
                ) {
                  unitObj = {
                    label: dynamicDataObj[item]
                      ? intl.formatMessage({
                        id:
                          item === 'type'
                            ? FertilizerTypeTranslation(dynamicDataObj[item])
                            : ManureTypeTranslation(dynamicDataObj[item])
                      })
                      : dynamicDataObj[item],
                    value: dynamicDataObj[item]
                  };
                }
                // attaching into initilzing form
                initObj[mappingObj[item]] = unitObj;
                
              } else if (item === 'pestSubList' && dynamicDataObj.pestSubList && dynamicDataObj.pestSubList.length > 0) {
                // to populate multi select
                const pestSubArr = [];
                dynamicDataObj.pestSubList.map(pest =>
                  pestSubArr.push({ label: pest, value: pest })
                );
                initObj[mappingObj[item]] = pestSubArr;
              } else {
                initObj[mappingObj[item]] = dynamicDataObj[item];
              }
              return true;
            });
            // To update savetaskInfo obj and to populate selected values on edit task
            // not associtae with redux
            dynamicValues.wind = dynamicDataObj.windSpeed || '';
            dynamicValues.eoa = dynamicDataObj.effectApplication || '';
            dynamicValues.harvestDate = dynamicDataObj.harvestDate || '';
            dynamicValues.pestAmountArr = dynamicDataObj.pesticideType || [];
          }
        }
        savetaskInfo(dynamicValues);
      }
      initialize({ ...initObj });
    }
  };

  changeWaterJournalToggle = () => {
    const { waterJournalOn } = this.state;
    this.setState({
      waterJournalOn: !waterJournalOn
    })
  }

  changeWaterEquipmentUsed = e => {
    const { change } = this.props;
    this.setState({
      waterEquipmentUsed: e
    });
    change('waterEquipmentUsed', e);
  };

  changeLessThan2PCSlope1 = e => {
    const { change } = this.props;
    this.setState({
      checkLessThan2PCSlope: e
    });
    change('checkLessThan2PCSlope', e);
  };

  changeVegetatedBufferExists = () => {
    const { checkVegetatedBufferExists } = this.state;
    this.setState({
      checkVegetatedBufferExists: !checkVegetatedBufferExists
    });
  };

  changeNozzlesProvideTargetMovement = () => {
    const { checkNozzlesProvideTargetMovement } = this.state;
    this.setState({
      checkNozzlesProvideTargetMovement: !checkNozzlesProvideTargetMovement
    });
  }

  changeDamperSetForOptimalDistribution = () => {
    const { checkDamperSetForOptimalDistribution } = this.state;
    this.setState({
      checkDamperSetForOptimalDistribution: !checkDamperSetForOptimalDistribution
    });
  }

  changeOneEndClosedToAvoidSurfaceWater = () => {
    const { checkOneEndClosedToAvoidSurfaceWater } = this.state;
    this.setState({
      checkOneEndClosedToAvoidSurfaceWater: !checkOneEndClosedToAvoidSurfaceWater
    });
  }

  // To show/hide dynamic fields section
  showDynamicSection = option => {
    if (
      [
        ACT1,
        ACT5,
        ACT7,
        ACT9,
        ACT10,
        ACT11,
        ACT12,
        ACT13,
        ACT14,
        ACT15,
        ACT16,
        ACT17,
        ACT18,
        ACT19,
        ACT20,
        ACT28,
        ACT30,
        ACT31,
        ACT32
      ].includes(option.value)
    ) {
      this.setState({ dynamicSecActId: option.value });
    } else {
      this.setState({ dynamicSecActId: null });
    }
  };

  fetchUser = id => {
    const { farmUsers } = this.props;
    return _.filter(farmUsers, item => item.user.id === id);
  };

  /** To get selected vehicle from all vehicles
   * @param {object} selectedVehicle
   * @returns {object}
   *
   */
  fetchVehicle = selectedVehicle => {
    const { taskDetails } = this.props;
    const { allVehicles } = taskDetails;
    return _.filter(
      allVehicles,
      item =>
        selectedVehicle.label === `${item.asset.brand} ${item.asset.model}` &&
        selectedVehicle.value === item.asset.assetId
    )[0];
  };

  updateAgreementError = (value) => {
    if (value) {
      this.setState({
        notSelectedError: false
      })
    }
  }

  /** To get selected equipment from all equipments
   * @param {object} selectedEquipment
   * @returns {object}
   *
   */
  fetchEquipment = selectedEquipment => {
    const { taskDetails } = this.props;
    const { allEquipments } = taskDetails;
    return _.filter(
      allEquipments,
      item =>
        selectedEquipment.label === `${item.asset.brand} ${item.asset.model}` &&
        selectedEquipment.value === item.asset.assetId
    )[0];
  };

  /**
   * to delete tasks
   * @param {boolean} action
   */
  deleteTaskItems = action => {
    const { deleteSelectedTasks, activeFarm } = this.props;
    const { paramId } = this.state;
    if (action) {
      deleteSelectedTasks([paramId], activeFarm.farm.id);
    }
    this.setState(prevState => ({
      deleteConfirmShow: !prevState.deleteConfirmShow
    }));
  };

  /**
   * to update tasks
   * @param {boolean} action
   */
  updateTaskItems = action => {
    if (action) {
      this.handleCreateTask(this.state.handleValues);
    }
    this.setState(prevState => ({
      showPopupHarvest: !prevState.showPopupHarvest
    }));
  };

  /**
   * Assignee confimation boby content
   * @param {array} selectedtask
   * @returns { HTMLElement }
   */
  getDeleteConfirmMsg = () => {
    const { intl } = this.props;
    return `<div class="confirm-msg-wrapper"> 
        <p> ${intl.formatMessage({ id: 'ASSET_DELETE_TEXT_MSG' })} </p>
      </div>`;
  };

  getUpdatableConfirmMsg = () => {
    const { intl } = this.props;
    return `<div> 
        <p> ${intl.formatMessage({ id: 'TASK_UPDATABLE_CONTENT' })} </p>
      </div>`;
  };

  /** To get acitivity label for given activity id
   * @param {number} actId
   * @returns {string}
   *
   */
  getActivityLabel = actId => {
    const { activityList } = this.props;
    return activityList.data.filter(item => item.id === actId)[0].activityName;
  };

  // To replace values for payload object form redux form values
  replaceFormValues = (dynamicFieldsObj, values) => {
    const { manureStorage } = this.props;
    const obj = { ...dynamicFieldsObj };
    Object.keys(obj).map(item => {
      if (item === 'activityName') {
        obj[item] = this.getActivityLabel(values.activity.value);
      } else if (item === 'activityId') {
        obj[item] = values.activity.value;
      } else if (item === 'manureStorageName') {
        const manureStorageList = manureStorage?.manureList;
        const manureName = manureStorageList && manureStorageList.find(item => item.id === values.manurestorageid.value);
        obj[item] = manureName ? manureName.name : null;
      } else if (item === 'pestSubList' && values.pestsubdata) {
        const pestSubArr = [];
        values.pestsubdata.map(pest => pestSubArr.push(pest.value));
        obj[item] = pestSubArr;
      } else {
        obj[item] =
          typeof values[obj[item]] === 'object' ? values[obj[item]] && values[obj[item]].value : values[obj[item]];
      }
      return true;
    });
    return obj;
  };

  enableDelete = () => {
    const {
      props: { activeFarm, hasSupportAdminRole },
      state: { paramId }
    } = this;
    if (
      activeFarm &&
      (activeFarm.role ? activeFarm.role.roleName === 'OWNER' || activeFarm.role.roleName === 'ADMIN' : hasSupportAdminRole.data) &&
      paramId !== 'create'
    ) {
      return true;
    }
    return false;
  };

  setTaskComplete = () => {
    this.setState({
      completeTask: true
    });
  };

  // To create/edit a task upon submit
  handleCreateTask = values => {
    const { createTask, taskDetails, fieldDetails, activeFarm, editTaskDetails, userDetails, userRegulations } = this.props;
    const { taskInfo } = taskDetails;
    const { data } = fieldDetails;
    const { paramId, completeTask, showPopupHarvest, editValueObject } = this.state;
    this.setState({
      notSelectedError: false
    })
    if (values.activity.value === ACT19) {
      let checkOne = false;
      if (userRegulations && userRegulations.regulationList !== null) {
        userRegulations.regulationList.map(regulationList => (
          checkOne = regulationList.opted === true ? true : checkOne
        ));
        if (!checkOne) {
          this.setState({
            notSelectedError: true
          });
          return;
        }
      }
    }

    if (paramId !== 'create' && !editTaskDetails.isGetTaskData.updatable && !showPopupHarvest &&
      (values.activity.value === ACT11 || values.activity.value === ACT12 || values.activity.value === ACT16 ||
        values.activity.value === ACT18)) {
      let edit_activity_id = editTaskDetails.isGetTaskData.activityId;
      let edit_field_id = editTaskDetails.isGetTaskData.fieldId;
      let edit_cut_text = null;
      let edit_cut_amount = null;
      let edit_locationId = null;

      // eslint-disable-next-line
      Object.keys(editValueObject).map(item => {
        if (item === "cutText") {
          edit_cut_text = editValueObject[item];
        }
        if (item === "cutAmount") {
          edit_cut_amount = editValueObject[item];
        }
        if (item === "locationId") {
          edit_locationId = editValueObject[item];
        }
      })

      if (edit_activity_id !== values.activity.value || edit_field_id !== values.field.value ||
        edit_cut_text !== values.cut.value || edit_cut_amount !== values.cutamount ||
        (values.locationId && edit_locationId !== values.locationId.value)) {
        this.setState({
          showPopupHarvest: true,
          handleValues: values
        });
        return;
      }
    }

    const selectedAssignee = values.assignee?.value
      ? this.fetchUser(values.assignee.value).length > 0 ? this.fetchUser(values.assignee.value)[0].user : ''
      : '';
    const dataObj = editTaskDetails.isGetTaskData === {} ? { ...values } : { ...editTaskDetails.isGetTaskData };
    // To construct payload object based on the acitivty chosen
    const dynamicFieldsObj = DynamicPayloadAttributesObj(values.activity.value);

    // replace dynamicFieldsObj values with redux form values
    const finalObj = this.replaceFormValues(dynamicFieldsObj, values);
    let taskComplete = false;

    // unit fpr pressing and combipressing
    if (values.activity.value === ACT11 || values.activity.value === ACT12 || values.activity.value === ACT16 || values.activity.value === ACT18) {
      finalObj.cutUnit = 'bales';
    }

    // adding effect of applicaton and wind speed incase of pesticide application
    if (values.activity.value === ACT19) {
      finalObj.effectApplication = taskInfo.eoa;
      finalObj.windSpeed = taskInfo.wind;
      finalObj.pesticideType = taskInfo.pestAmountArr;
      finalObj.harvestDate = taskInfo.harvestDate;
    }

    if (values.activity.value === ACT7) {
      finalObj.type = 'Mineral fertilizer';
    }
    if (values.activity.value === ACT30) {
      finalObj.type = 'Manure';
    }
    if (values.activity.value === ACT31) {
      finalObj.type = 'Liming';
    }
    if (values.activity.value === ACT32) {
      finalObj.type = 'Foliar fertilizer';
    }

    let waterJournal = null;
    if (values.activity.value === ACT19) {
      const { waterJournalOn, waterEquipmentUsed, checkLessThan2PCSlope, checkVegetatedBufferExists,
        checkNozzlesProvideTargetMovement, checkDamperSetForOptimalDistribution, checkOneEndClosedToAvoidSurfaceWater } = this.state;
      if (waterJournalOn) {
        waterJournal = {};
        if (waterEquipmentUsed) {
          waterJournal["sprayerType"] = "FIELD_SPRAYER";
          waterJournal["distanceToWaterBody"] = values.distanceToWaterBody ? values.distanceToWaterBody : null;
          waterJournal["distanceToWaterWell"] = values.distanceToWaterWell ? values.distanceToWaterWell : null;
          waterJournal["lessThan2PCSlope"] = checkLessThan2PCSlope;
          waterJournal["reqdVegetatedBufferZone"] = values.reqdVegetatedBufferZone ? values.reqdVegetatedBufferZone : null;
          waterJournal["nozzleName"] = values.nozzleName ? values.nozzleName : null;
          waterJournal["driftReduction"] = values.driftReduction ? values.driftReduction : null;
          waterJournal["maxDrivingSpeed"] = values.maxDrivingSpeed ? values.maxDrivingSpeed : null;
          waterJournal["pressure"] = values.pressure ? values.pressure : null;
          waterJournal["nozzleHeight"] = values.nozzleHeight ? values.nozzleHeight : null;
          waterJournal["vegetatedBufferExists"] = null;
          waterJournal["locationAndDistance"] = null;
          waterJournal["cultureBySpray"] = null;
          waterJournal["fieldAge"] = null;
          waterJournal["developmentStage"] = null;
          waterJournal["reqdDistanceToSurfaceWater"] = null;
          waterJournal["fogSprayerModel"] = null;
          waterJournal["amount"] = null;
          waterJournal["ptoSpeed"] = null;
          waterJournal["nozzlesProvideTargetMovement"] = null;
          waterJournal["damperSetForOptimalDistribution"] = null;
          waterJournal["oneEndClosedToAvoidSurfaceWater"] = null;
          waterJournal["sprayingTechnique"] = null;
        } else {
          waterJournal["sprayerType"] = "FOG_SPRAYER";
          waterJournal["distanceToWaterBody"] = values.distanceToWaterBody ? values.distanceToWaterBody : null;
          waterJournal["distanceToWaterWell"] = values.distanceToWaterWell ? values.distanceToWaterWell : null;
          waterJournal["vegetatedBufferExists"] = checkVegetatedBufferExists;
          if (checkVegetatedBufferExists) {
            waterJournal["locationAndDistance"] = values.locationAndDistance ? values.locationAndDistance : null;
          } else {
            waterJournal["locationAndDistance"] = null;
          }
          waterJournal["cultureBySpray"] = values.cultureBySpray ? values.cultureBySpray : null;
          waterJournal["fieldAge"] = values.fieldAge ? values.fieldAge : null;
          waterJournal["developmentStage"] = values.developmentStage ? values.developmentStage : null;
          waterJournal["reqdDistanceToSurfaceWater"] = values.reqdDistanceToSurfaceWater ? values.reqdDistanceToSurfaceWater : null;
          waterJournal["reqdVegetatedBufferZone"] = values.reqdVegetatedBufferZone ? values.reqdVegetatedBufferZone : null;
          waterJournal["fogSprayerModel"] = values.fogSprayerModel ? values.fogSprayerModel : null;
          waterJournal["nozzleName"] = values.nozzleName ? values.nozzleName : null;
          waterJournal["driftReduction"] = values.driftReduction ? values.driftReduction : null;
          waterJournal["maxDrivingSpeed"] = values.maxDrivingSpeed ? values.maxDrivingSpeed : null;
          waterJournal["pressure"] = values.pressure ? values.pressure : null;
          waterJournal["amount"] = values.amount ? values.amount : null;
          waterJournal["ptoSpeed"] = values.ptoSpeed ? values.ptoSpeed : null;
          waterJournal["nozzlesProvideTargetMovement"] = checkNozzlesProvideTargetMovement;
          waterJournal["damperSetForOptimalDistribution"] = checkDamperSetForOptimalDistribution;
          waterJournal["oneEndClosedToAvoidSurfaceWater"] = checkOneEndClosedToAvoidSurfaceWater;
          waterJournal["sprayingTechnique"] = values.sprayingTechnique ? values.sprayingTechnique : null;
        }
      }
    }

    // adding taskActivityDetails
    dataObj.taskActivityDetails = {};

    // adding dynamicData
    dataObj.taskActivityDetails.taskActivity = finalObj;

    // adding weather attribute
    dataObj.taskActivityDetails.wheather = taskInfo.weather;

    dataObj.activity = {};
    if (values.activity) {
      dataObj.activity.id = values.activity.value;
      dataObj.activity.activityName = this.getActivityLabel(values.activity.value);
    }

    dataObj.farm = activeFarm.farm;
    if (values.vehicle) {
      dataObj.vehicle = this.fetchVehicle(values.vehicle);
    }
    if (values.equipment) {
      dataObj.equipment = this.fetchEquipment(values.equipment);
    }
    if (taskInfo) {
      dataObj.startTime = taskInfo.sDate || '';
      dataObj.endTime = taskInfo.eDate || '';
      if (taskInfo.sDate && taskInfo.eDate && taskInfo.sDate > taskInfo.eDate) {
        return;
      }
    }
    if (selectedAssignee !== '') {
      dataObj.assignee = selectedAssignee;
    } else {
      dataObj.assignee = null;
    }
    if (paramId !== 'create') {
      dataObj.status = editTaskDetails.isGetTaskData.status;
      dataObj.id = editTaskDetails.isGetTaskData.id;
    }
    if (completeTask && (dataObj.status === PLANNED)) {
      dataObj.status = COMPLETED;
    }
    dataObj.notes = values.notes ? values.notes : '';
    let tasks;
    let taskValueArray = [];
    let taskFieldIdArray = [];
    if (values.field.length !== undefined && values.field.length > 0) {
      // To remove select all option
      _.remove(values.field, { value: SelectAllOption.value });
      tasks = values.field.map(field => {
        const [selectedF] = _.filter(data, ['fieldId', field.value]);
        const currectTask = { ...dataObj };
        currectTask.field = selectedF;
        return currectTask;
      });
    } else {
      [dataObj.field] = _.filter(data, ['fieldId', values.field.value]);
      tasks = [dataObj];
    }
    tasks.map(task => {
      taskFieldIdArray.push((task.field && task.field !== 'undefined' && task.field !== null) ? task.field.fieldId : task.fieldId);
      const taskValue = {
        "farmId": task.farm.id,
        "activityId": task.activity.id,
        "fieldId": (task.field && task.field !== 'undefined' && task.field !== null) ? task.field.fieldId : task.fieldId,
        "waterJournalAdded": this.state.waterJournalOn,
        "waterJournal": waterJournal,
        "fieldIds": []
      }
      if (task.vehicle && task.vehicle.asset) {
        taskValue["vehicleAssetId"] = task.vehicle.asset.assetId;
      }
      if (task.vehicle && task.vehicle.assetAssignmentId) {
        taskValue["vehicleAssetAssignmentId"] = task.vehicle.assetAssignmentId;
      }
      if (task.equipment && task.equipment.asset) {
        taskValue["equipmentAssetId"] = task.equipment.asset.assetId;
      }
      if (task.equipment && task.equipment.assetAssignmentId) {
        taskValue["equipmentAssetAssignmentId"] = task.equipment.assetAssignmentId;
      }
      if (task.assignee) {
        taskValue["assigneeId"] = task.assignee.id;
      }
      if (task.taskActivityDetails && task.taskActivityDetails.taskActivity) {
        taskValue["taskActivity"] = task.taskActivityDetails.taskActivity;
      }
      if (task.taskActivityDetails && task.taskActivityDetails.wheather) {
        taskValue["weather"] = task.taskActivityDetails.wheather;
      }
      if (task.status) {
        taskValue["status"] = task.status;
      }
      if (task.notes) {
        taskValue["notes"] = task.notes;
      }
      if (completeTask || (task.id && task.status && task.status !== PLANNED)) {
        if (task.startTime) {
          taskValue["startTime"] = task.startTime;
        }
        if (task.endTime) {
          taskValue["endTime"] = task.endTime;
        }
        if (task.startTime && task.endTime && task.endTime >= task.startTime) {
          const diffInMs = Math.abs(new Date(task.endTime) - new Date(task.startTime));
          taskValue["netDuration"] = diffInMs / 1000;
        }
        if (task.startTime && !task.endTime && paramId === 'create') {
          let newEndDate = new Date();
          taskValue["endTime"] = newEndDate;
          if (Math.abs(new Date(newEndDate)) >= Math.abs(new Date(task.startTime))) {
            let diffInMs = Math.abs(new Date(newEndDate) - new Date(task.startTime));
            taskValue["netDuration"] = diffInMs / 1000;
          }
        }
      }
      if (paramId !== 'create') {
        taskValue["id"] = task.id;
      }
      if (completeTask) {
        taskComplete = true;
      }
      taskValueArray.push(taskValue);
      return null;
    });
    if (!completeTask) {
      if (paramId !== 'create') {
        taskValueArray[0].fieldIds = taskFieldIdArray;
        taskValueArray[0].fieldId = "";
        if (taskValueArray[0]['fieldId'] === "") {
          delete taskValueArray[0]['fieldId']
        }
        createTask(taskValueArray[0], paramId, taskComplete, activeFarm.farm.id, "notcomplete");
      } else {
        createTask(taskValueArray, paramId, taskComplete, activeFarm.farm.id, "notcomplete");
      }
    } else {
      taskValueArray[0].fieldIds = taskFieldIdArray;
      taskValueArray[0].fieldId = "";
      if (taskValueArray[0]['fieldId'] === "") {
        delete taskValueArray[0]['fieldId']
      }
      //taskValueArray.map((taskArrayValue,index) => {
      //if(taskValueArray.length === index+1) {
      mixpanel.identify(userDetails.id);
      mixpanel.track(TASK_COMPLETED, { "ActivityID": taskValueArray[0].activityId, "ActivityName": this.getActivityLabel(taskValueArray[0].activityId) });
      createTask(taskValueArray[0], paramId, taskComplete, activeFarm.farm.id, "complete");
      /*} else {
        createTask(taskArrayValue[0], paramId, taskComplete, activeFarm.farm.id, "notcomplete");
      }*/
      return null;
      //});
    }
    this.setState({
      completeTask: false
    });
  };

  render() {
    const { dynamicSecActId, deleteConfirmShow, paramId, taskStatus, showPopupHarvest, notSelectedError, waterJournalOn,
      waterEquipmentUsed, checkLessThan2PCSlope, checkVegetatedBufferExists, checkNozzlesProvideTargetMovement,
      checkDamperSetForOptimalDistribution, checkOneEndClosedToAvoidSurfaceWater, paramFieldId } = this.state;
    const { handleSubmit, taskDetails, fieldDetails, tasksList, intl, editTaskDetails, activeFarm, hasSupportAdminRole } = this.props;
    const enableDelete = !(paramId !== "create" && (activeFarm.role ? activeFarm.role.id === OWNER || activeFarm.role.id === ADMIN || activeFarm.role.id === ADVISOR : hasSupportAdminRole.data));
    return (
      <Box className={`${'main-content'}`}>
        <Box>
          <Header
            bcLink={{ to: '/tasks', text: intl.formatMessage({ id: 'TASKS' }) }}
            bcTitle={intl.formatMessage({ id: paramId === 'create' ? 'CT_CT' : 'CT_ET' })}
            bcLinkArrow={true}
          />
        </Box>
        <Box className="settings-tabs">
          <SectionContainer
            styling={{
              backgroundColor: 'white',
              mobileOffset: true
            }}
          >
            <div className="action-menu">
              <div className="action-menu__items">
                <span className={`btn-action btn-action--delete ${enableDelete ? 'disabled' : ''}`}>
                  <IconButton
                    disabled={enableDelete}
                    onClick={() => {
                      this.setState(prevState => ({
                        deleteConfirmShow: !prevState.deleteConfirmShow
                      }));
                    }}
                  >
                    <TrashcanIcon scale={1} />
                  </IconButton>
                </span>
              </div>
            </div>
          </SectionContainer>
          <div className="create-task-wrapper">
            <div className="create-task-left-container">
              {fieldDetails.isLoading ? (
                <Loader styleName="task-loader" />
              ) : (
                <form
                  onSubmit={handleSubmit(
                    values =>
                      new Promise((resolve, reject) => {
                        const errors = validate(values, this.props);
                        if (errors && Object.values(errors).length > 0) {
                          reject(new SubmissionError(errors));
                        } else {
                          resolve(this.handleCreateTask(values));
                        }
                      })
                  )}
                >
                  <DrivingPattern taskId={paramId} taskStatus={taskStatus} />
                  <div className="section-item">
                    <FieldActivity
                      showDynamicSectionCallback={this.showDynamicSection}
                      {...this.props}
                    />
                  </div>
                  <div className="section-item">
                    <TimeRecorded chosenActId={dynamicSecActId} {...this.props} />
                  </div>
                  {dynamicSecActId && (
                    <div className="section-item">
                      <DynamicSectionForm
                        waterJournalOn={waterJournalOn}
                        changeWaterJournalToggle={this.changeWaterJournalToggle}
                        chosenActId={dynamicSecActId}
                        {...this.props}
                      />
                    </div>
                  )}
                  {dynamicSecActId && waterJournalOn && (
                    <div className="section-item">
                      <WaterJournalForm
                        waterEquipmentUsed={waterEquipmentUsed}
                        changeWaterEquipmentUsed={this.changeWaterEquipmentUsed}
                        changeLessThan2PCSlope1={this.changeLessThan2PCSlope1}
                        checkLessThan2PCSlope={checkLessThan2PCSlope}
                        checkVegetatedBufferExists={checkVegetatedBufferExists}
                        changeVegetatedBufferExists={this.changeVegetatedBufferExists}
                        checkNozzlesProvideTargetMovement={checkNozzlesProvideTargetMovement}
                        changeNozzlesProvideTargetMovement={this.changeNozzlesProvideTargetMovement}
                        checkDamperSetForOptimalDistribution={checkDamperSetForOptimalDistribution}
                        changeDamperSetForOptimalDistribution={this.changeDamperSetForOptimalDistribution}
                        checkOneEndClosedToAvoidSurfaceWater={checkOneEndClosedToAvoidSurfaceWater}
                        changeOneEndClosedToAvoidSurfaceWater={this.changeOneEndClosedToAvoidSurfaceWater}
                        chosenActId={dynamicSecActId}
                        {...this.props}
                      />
                    </div>
                  )}
                  <div className="section-item remove-connet">
                    <AssigneeNotes {...this.props} />
                  </div>
                  <div className="task-pesticide-check-agreement-error">
                    {notSelectedError && <ErrorText>{intl.formatMessage({ id: 'SELECT_REGULATION' })}</ErrorText>}
                  </div>
                  <TaskSubmit updateAgreementError={this.updateAgreementError} setTaskComplete={this.setTaskComplete} taskId={paramId} chosenActId={dynamicSecActId} {...this.props} />
                </form>
              )}
            </div>
            <div className="create-task-delete">
              <div className="delete-icon">
                {this.enableDelete() && (
                  <IconButton
                    onClick={() => {
                      this.setState(prevState => ({
                        deleteConfirmShow: !prevState.deleteConfirmShow
                      }));
                    }}
                  >
                    <TrashcanIcon scale={0.85} />
                  </IconButton>
                )}
              </div>
            </div>
            {deleteConfirmShow && (
              <ConfirmDialogBox
                heading={intl.formatMessage({ id: 'DELETE' })}
                body={this.getDeleteConfirmMsg()}
                confirmText="DELETE"
                cancelText="CANCEL"
                callback={e => this.deleteTaskItems(e)}
                warning
              />
            )}
            {showPopupHarvest && (
              <ConfirmDialogBox
                heading=""
                body={this.getUpdatableConfirmMsg()}
                cancelText={intl.formatMessage({ id: 'TASK_NO' })}
                confirmText={intl.formatMessage({ id: 'TASK_YES' })}
                callback={e => this.updateTaskItems(e)}
              />
            )}
            {taskDetails.isLoading || editTaskDetails.isLoading || (tasksList && tasksList.isDeleteLoading) ? (
              <Loader styleName="task-loader" />
            ) : null}
            {tasksList?.deleteSuccess || taskDetails?.isSuccess ? (paramFieldId === null ? <Redirect to="/tasks" /> : (tasksList.deleteSuccess = false, taskDetails.isSuccess = false, window.location.href = `#/managefields/${paramFieldId}/historical`)) : null}
          </div>
        </Box>
      </Box>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  createTask: (taskData, type, taskComplete, farmId, status) => dispatch(taskActions.createUpdateTask(taskData, type, taskComplete, farmId, status, false, 3)),
  editTask: obj => dispatch(taskActions.editTask(obj)),
  nullifySuccess: () => dispatch(taskActions.nullifySuccess()),
  fetchFieldList: farmId => dispatch(fieldActions.fetchFieldList(farmId)),
  savetaskInfo: obj => dispatch(taskActions.savetaskInfo(obj)),
  deleteSelectedTasks: (tasks, farmId) => dispatch(taskActions.deleteTasks(tasks, farmId)),
  seasonsList: () => dispatch(Seasons.seasonsList()),
  updateTaskDuration: (farmId, taskId, data) =>
    dispatch(taskActions.taskUpdateDuration(farmId, taskId, data)),
  getTask: (farmId, taskId) => dispatch(getTaskAction.getTask(farmId, taskId)),
  getLocations: (farmId) => dispatch(baleFeedEventActions.getLocations(farmId)),
  getUserRegulations: userId => dispatch(regulationList(userId)),
  fetchManureList: farmId => dispatch(ManureAction.fetchManureList(farmId))
});

const mapStateToProps = state => ({
  activityList: state.ActivityList,
  userInfo: state.userDetails,
  userDetails: state.userDetails.data,
  taskDetails: state.taskDetails,
  activeFarm: state.farmList.activeFarm,
  tasksList: state.TaskList,
  farmUsers: state.farmUserDetails.userList.users,
  fieldDetails: state.fieldDetails,
  seasons: state.seasons,
  editTaskDetails: state.getTask,
  activitiesCommon: state.masterData.data[0]?.activities,
  locations: state.baleFeedEvent.locationData,
  userRegulations: state.regulations,
  hasSupportAdminRole: state.SupportAdminRole,
  manureStorage: state.manureStorage
});

export default reduxForm({
  form: 'CreateTaskForm',
  validate
})(injectIntl(connect(mapStateToProps, mapDispatchToProps)(CreateTask)));
