import * as React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import * as subscriptionAction from 'actions/Subscription';
import SubscriptionList from 'mui-mobile-components/settings/subscription/SubscriptionList';
import Loader from 'components/Loader';

class ManageSubscription extends React.Component {
  componentDidMount() {
    const { getSubscriptionList, activeFarm } = this.props;
    if (activeFarm) {
      getSubscriptionList();
    }
  }

  componentDidUpdate(prevProps) {
    const { activeFarm, getSubscriptionList } = this.props;
    if (activeFarm !== prevProps.activeFarm) {
      getSubscriptionList();
    }
  }

  render() {
    const { subscriptionDetails } = this.props;
    const { isLoading, subscriptionList } = subscriptionDetails;
    return (
      <div className="asset-list-wrapper">
        {isLoading && <Loader styleName="fixed-loader" />}
        {subscriptionList?.length > 0 && (
          <SubscriptionList subscriptionList={subscriptionList} />
        )}
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getSubscriptionList: () => dispatch(subscriptionAction.fetchSubscriptionUsers())
});

const mapStateToProps = state => ({
  userInfo: state.userDetails,
  activeFarm: state.farmList.activeFarm,
  rolePermissions: state.RolesDetails,
  subscriptionDetails: state.subscription,
  hasSupportAdminRole: state.SupportAdminRole
});

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(ManageSubscription));
