import React, { useState, useRef, useEffect } from "react";
import { Typography, Box, Chip, Dialog } from '@mui/material';
import MuiTextField from 'components/mui3-forms/MuiTextField';
import MuiSelectDropdown from 'components/mui3-forms/MuiSelectDropdown';
import * as taskTemplateStyle from 'mui-components/kart/fieldmap/tasktemplates/TaskTemplateStyle';
import AddVehicleModal from 'mui-components/kart/fieldmap/vehicleModals/AddVehicleModal';
import AddEquipmentModal from 'mui-components/kart/fieldmap/vehicleModals/AddEquipmentModal';
import { getDropDownListValues } from 'utils/index';
import AddIcon from '@mui/icons-material/Add';

const AddTaskTemplate1 = (props) => {
  const { intl, activeFarm, selectedActivity, locations, taskDetails, closeNewTask, saveNewTask, createdVehicleId, createdEquipmentId, taskKartToggle, ensilationList
  } = props;
  const initialFormState = {
    farmId: activeFarm.farm.id,
    activityId: selectedActivity?.id,
    activityName: selectedActivity?.activityName,
    locationId: "",
    ensilationText: "",
    ensilationAmount: "",
    drymatter: "",
    weight: "",
    vehicleAssetId: "",
    equipmentAssetId: "",
    waterJournal: null,
    waterJournalAdded: false,
    cut: selectedActivity?.cut
  };
  const [formState, setFormState] = useState(initialFormState);
  const [formErrors, setFormErrors] = useState({});
  const initialStateRef = useRef(initialFormState);
  const [isModalOpen, setModalOpen] = useState(false);
  const [showAddVehicleDialog, setShowAddVehicleDialog] = useState(false);
  const [showAddEquipmentDialog, setShowAddEquipmentDialog] = useState(false);

  useEffect(() => {
    if (taskKartToggle?.getTaskList?.taskActivity) {
      setFormState((prevState) => {
        const updatedFormState = {
          ...prevState,
          locationId: taskKartToggle.getTaskList.taskActivity.locationId,
          ensilationText: taskKartToggle.getTaskList.taskActivity.ensilationText,
          ensilationAmount: taskKartToggle.getTaskList.taskActivity.ensilationAmount,
          drymatter: taskKartToggle.getTaskList.taskActivity.drymatter,
          weight: taskKartToggle.getTaskList.taskActivity.weight,
          vehicleAssetId: taskKartToggle.getTaskList.vehicleAssetId,
          equipmentAssetId: taskKartToggle.getTaskList.equipmentAssetId,
          cut: taskKartToggle.getTaskList.taskActivity.cut
        };

        initialStateRef.current = updatedFormState;
        return updatedFormState;
      });
    }
  }, [taskKartToggle]);

  const AddVehicle = () => (
    <Box style={taskTemplateStyle.assetAdd} onClick={() => setShowAddVehicleDialog(true)}>
      <Box sx={{ padding: '5px 15px 0px 0px' }}>
        <AddIcon />
      </Box>
      <Typography style={taskTemplateStyle.assetAddHeader}>{intl.formatMessage({ id: 'CT_ANV' })}</Typography>
    </Box>
  );
  const vehicleOptions = () => {
    let vehicleList = [];
    vehicleList.push({ value: null, label: <AddVehicle /> });
    if (taskDetails.allVehicles && (taskDetails.allVehicles).length > 0) {
      (taskDetails.allVehicles).map(data => (
        vehicleList.push({
          value: data.asset.assetId,
          label: `${data.asset.brand} ${data.asset.model}`
        })
      ));
      return vehicleList;
    }
    return vehicleList;
  };
  const AddEquipment = () => (
    <Box style={taskTemplateStyle.assetAdd} onClick={() => setShowAddEquipmentDialog(true)}>
      <Box sx={{ padding: '5px 15px 0px 0px' }}>
        <AddIcon />
      </Box>
      <Typography style={taskTemplateStyle.assetAddHeader}>{intl.formatMessage({ id: 'CT_ANE' })}</Typography>
    </Box>
  );
  const equipmentOptions = () => {
    let equipmentList = [];
    equipmentList.push({ value: null, label: <AddEquipment /> });
    if (taskDetails.allEquipments && (taskDetails.allEquipments).length > 0) {
      (taskDetails.allEquipments).map(data => (
        equipmentList.push({
          value: data.asset.assetId,
          label: `${data.asset.brand} ${data.asset.model}`
        })
      ));
      return equipmentList;
    }
    return equipmentList;
  };
  const getEnsilationOptions = () => {
    if (!ensilationList || !ensilationList.ensilationTypeData) {
      return [];
    }
    return ensilationList.ensilationTypeData.map((name) => ({
      value: name, // Use the ensilation name as the value
      label: name, // Use the ensilation name as the label
    }));
  };

  const harvestOptions = [
    { label: intl.formatMessage({ id: 'FIRST_HARVEST_YIELD' }), value: 1 },
    { label: intl.formatMessage({ id: 'SECOND_HARVEST_YIELD' }), value: 2 },
    { label: intl.formatMessage({ id: 'THIRD_HARVEST_YIELD' }), value: 3 },
    { label: intl.formatMessage({ id: 'FOURTH_HARVEST_YIELD' }), value: 4 }
  ];

  const handleFieldChange = (fieldName, value) => {
    setFormState((prevState) => ({
      ...prevState,
      [fieldName]: value
    }));
  };

  const handleNumericValidation = (fieldName, value) => {
    if (/^\d*([.,]?\d*)?$/.test(value)) {
      setFormState((prevState) => ({
        ...prevState,
        [fieldName]: value,
      }));
      setFormErrors((prevErrors) => {
        const newErrors = { ...prevErrors };
        delete newErrors[fieldName];
        return newErrors;
      });
    } else {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        [fieldName]: intl.formatMessage({ id: 'DYNAMIC_SECTION_AMOUNT_VAL_MSG' }),
      }));
    }
  };

  const handleSave = () => {
    setModalOpen(false);
    if (formErrors && Object.values(formErrors).length === 0) {
      saveNewTask(formState);
    }
  };

  const handleContinueSave = () => {
    setModalOpen(false);
  };

  const handleCancel = () => {
    let isChanged = JSON.stringify(initialStateRef.current) !== JSON.stringify(formState);
    if (isChanged) {
      setModalOpen(true);
    } else {
      handleCancelButton();
    }
  };

  const handleCancelButton = () => {
    setModalOpen(false);
    closeNewTask();
  };

  const onCloseVehicleDialog = () => {
    setShowAddVehicleDialog(false);
  }

  const onCloseEquipmentDialog = () => {
    setShowAddEquipmentDialog(false);
  }

  return (
    <Box sx={{
      padding: '0px 24px 0px 24px',
      overflowY: 'auto',
      '&::-webkit-scrollbar': {
        width: '10px',
        backgroundColor: 'rgba(0, 0, 0, 0.1)',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#A8A8A8',
        borderRadius: '4px',
        transition: 'background-color 0.3s ease',
      },
      '&::-webkit-scrollbar-thumb:hover': {
        backgroundColor: '#B8B8B8',
      }
    }}
    >
      <Box style={taskTemplateStyle.headerSticky}>
        <Box style={taskTemplateStyle.modalDialogHeader}>
          <Typography style={taskTemplateStyle.modalDialogTitle}>{selectedActivity?.activityName}</Typography>
        </Box>
        <Box style={taskTemplateStyle.modalDialogHeader} sx={{ paddingTop: '16px' }}>
          <Typography style={taskTemplateStyle.modalDialogTitleContent}>{intl.formatMessage({ id: 'DEFAULT_FARM_VALUES_INFO' })}</Typography>
        </Box>
      </Box>
      <Box>
        <Box sx={{ paddingTop: '20px', width: '100%' }}>
          <Box sx={{ paddingTop: '16px' }}> {/* Adjusted padding */}
            <MuiSelectDropdown
              defaultLabel={intl.formatMessage({ id: 'TASK_HARVEST' })}
              defaultValue={formState.cut}
              options={harvestOptions}
              fieldName='cut'
              onChange={handleFieldChange}
              errorHelperText={formErrors['firstHarvestYield']}
            />
          </Box>
          <Box sx={{ paddingTop: '20px' }}>
            <MuiTextField
              defaultLabel={intl.formatMessage({ id: 'GRASS_ANALYSIS_FIELD_DRY_MATTER' })}
              defaultValue={formState.drymatter}
              inputAdornment={'%'}
              fieldName='drymatter'
              onChange={handleNumericValidation}
              autoFocus={false}
              errorHelperText={formErrors['drymatter']}
            />
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", paddingTop: '24px' }}>
            <MuiTextField
              defaultLabel={intl.formatMessage({ id: 'TASK_SNITT_VEKT' })}
              defaultValue={formState.weight}
              inputAdornment={intl.formatMessage({ id: 'TASK_SNITT_VEKT_UNIT' })}
              fieldName='weight'
              onChange={handleNumericValidation}
              autoFocus={false}
              errorHelperText={formErrors['weight']}
            />
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", paddingTop: '56px' }}>
            <MuiSelectDropdown
              defaultLabel={intl.formatMessage({ id: 'CT_ENSILATIONTYPE' })}
              defaultValue={formState.ensilationText}
              options={getEnsilationOptions()}
              fieldName='ensilationText'
              onChange={handleFieldChange}
              autoFocus={false}
            />
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", paddingTop: '24px' }}>
            <MuiTextField
              defaultLabel={intl.formatMessage({ id: 'ENSILATIONTYPE_AMOUNT' })}
              inputAdornment={intl.formatMessage({ id: 'ENSILATIONTYPE_UNIT' })}
              defaultValue={formState.ensilationAmount}
              fieldName='ensilationAmount'
              onChange={handleNumericValidation}
              autoFocus={false}
              errorHelperText={formErrors['ensilationAmount']}
            />
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", paddingTop: '56px' }}>
            <MuiSelectDropdown
              defaultLabel={intl.formatMessage({ id: 'HARVEST_LOCATION_LABEL' })}
              defaultValue={formState.locationId}
              options={getDropDownListValues(locations, false)}
              fieldName="locationId"
              onChange={handleFieldChange}
            />
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", paddingTop: '56px' }}>
            <MuiSelectDropdown
              key={createdVehicleId}
              defaultLabel={intl.formatMessage({ id: 'VEHICLE' })}
              defaultValue={createdVehicleId}
              options={vehicleOptions()}
              fieldName="vehicleAssetId"
              onChange={handleFieldChange}
            />
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", paddingTop: '24px' }}>
            <MuiSelectDropdown
              key={createdEquipmentId}
              defaultLabel={intl.formatMessage({ id: 'EQUIPMENT' })}
              defaultValue={createdEquipmentId}
              options={equipmentOptions()}
              fieldName="equipmentAssetId"
              onChange={handleFieldChange}
            />
          </Box>
        </Box>
      </Box>
      <Box style={taskTemplateStyle.buttonBox}>
        <Box style={taskTemplateStyle.buttonBoxBottom}>
          <Chip
            label={intl.formatMessage({ id: 'cancel' })}
            clickable
            style={taskTemplateStyle.cancelButtonStyle}
            sx={{
              backgroundColor: 'transparent',
              '&:hover, &:focus': {
                backgroundColor: 'transparent',
              }
            }}
            disableRipple
            onClick={handleCancel}
          />
          <Chip
            label={intl.formatMessage({ id: 'SAVE' })}
            clickable
            style={taskTemplateStyle.okButtonStyle}
            sx={{
              backgroundColor: 'transparent',
              '&:hover, &:focus': {
                backgroundColor: 'transparent',
              }
            }}
            disableRipple
            onClick={handleSave}
          />
        </Box>
      </Box>
      {/* Modal */}
      <Box>
        <Dialog onClose={() => { }} open={isModalOpen} PaperProps={{
          style: {
            backgroundColor: '#E6E9E8',
            borderRadius: "28px",
            width: "312px",
            minWidth: "280px",
            maxWidth: "312px",
            maxHeight: '30vh'
          }
        }}>
          <Box style={taskTemplateStyle.cancelModalBox}>
            <Typography style={taskTemplateStyle.cancelModalTitle}>{intl.formatMessage({ id: 'TASK_CANCEL_MODAL_TITLE' })}</Typography>
            <Typography style={taskTemplateStyle.cancelModalSubTitle}>{intl.formatMessage({ id: 'TASK_CANCEL_MODAL_SUB_TITLE' })}</Typography>
          </Box>
          <Box style={taskTemplateStyle.cancelModalBoxButton}>
            <Chip
              label={intl.formatMessage({ id: 'cancel' })}
              clickable
              style={taskTemplateStyle.cancelButtonStyle}
              sx={{
                backgroundColor: 'transparent',
                '&:hover, &:focus': {
                  backgroundColor: 'transparent',
                }
              }}
              disableRipple
              onClick={handleCancelButton}
            />
            <Chip
              label={intl.formatMessage({ id: 'TASK_CONTINUE_REGISTRATION' })}
              clickable
              style={taskTemplateStyle.cancelButtonStyle}
              sx={{
                backgroundColor: 'transparent',
                '&:hover, &:focus': {
                  backgroundColor: 'transparent',
                }
              }}
              disableRipple
              onClick={handleContinueSave}
            />
          </Box>
        </Dialog>
      </Box>
      <AddVehicleModal
        intl={intl}
        vehicleSelectedId={'new'}
        onCloseVehicleDialog={onCloseVehicleDialog}
        showAddVehicleDialog={showAddVehicleDialog}
        assetType={'vehicle'}
        taskDetails={taskDetails}
      />
      <AddEquipmentModal
        intl={intl}
        equipmentSelectedId={'new'}
        onCloseEquipmentDialog={onCloseEquipmentDialog}
        showAddEquipmentDialog={showAddEquipmentDialog}
        assetType={'equipment'}
        taskDetails={taskDetails}
      />
    </Box>
  );
};

export default AddTaskTemplate1;