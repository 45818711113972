import React from 'react';
import Header from 'mui-mobile-components/header';
import SectionContainer from '@tine/lib-frontend-components/components/alignment/section-container';
import { FormattedMessage } from 'react-intl';

import StatsHeader from 'mui-mobile-components/stats/StatsHeader';
import StatsActivity from 'mui-mobile-components/stats/StatsActivity';
import Selectbox from 'components/forms/Selectbox';
import Text from '@tine/lib-frontend-components/elements/typography/text';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import * as StatsAction from 'actions/Stats';
import { getYearsForKPI } from 'utils/index';
import mixpanel from 'mixpanel-browser';
import { CLICK_NITROGEN_BOX } from 'constants/MixPanel';
 
class Stats extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedYear: null,
      completeSelectedYear: null,
      openNitrogenListModal: false,
      selectedField: '',
      selectedNitrogen: '',
      selectedFieldCheckbox: [],
      selectAllFields: true,
      updatedSelectAllFields: false
    };
    this.onSelectChange = this.onSelectChange.bind(this);
    this.nitrogenListModal = this.nitrogenListModal.bind(this);
    this.onFieldChange = this.onFieldChange.bind(this);
    this.handleSelectedNitrogen = this.handleSelectedNitrogen.bind(this);
  }

  componentDidMount = () => {
    const { activeFarm, fetchStatsHeader } = this.props;
    const currentYear = new Date().getFullYear();
    if (activeFarm) {      
      fetchStatsHeader(activeFarm.farm.id, currentYear);   
    }
    this.setState({
      selectedYear: currentYear,
      completeSelectedYear: { "label": currentYear, "value": currentYear}
    });
  };

  componentDidUpdate = prevProps => {
    const { activeFarm, fetchStatsHeader } = this.props;
    const {
      props: {
        statsDetails: { activities }
      }
    } = this;
    const { data } = activities;
    const { updatedSelectAllFields } = this.state;
    if (activeFarm !== prevProps.activeFarm) {
      const currentYear = new Date().getFullYear();
      fetchStatsHeader(activeFarm.farm.id, currentYear);
      this.setState({
        selectedYear: currentYear,
        completeSelectedYear: { "label": currentYear, "value": currentYear}
      });
    }
    if(data && data.harvestData && !updatedSelectAllFields) {
      let selectedArray = [];
      data.harvestData.forEach((option, index) => {
        if (index > 0) selectedArray.push(option.id);
      });
      this.setState({
        selectedFieldCheckbox: selectedArray,
        updatedSelectAllFields: true
      });
    }
  };

  isSetState = (newState) => {
    this.setState(newState);
  };

  nitrogenListModal = () => {
    const { openNitrogenListModal } = this.state;
    this.setState({ openNitrogenListModal: !openNitrogenListModal });
    const { userDetails } = this.props;
    mixpanel.identify(userDetails.id);
    mixpanel.track(CLICK_NITROGEN_BOX);
    const {
      props: {
        statsDetails: { activities }
      }
    } = this;
    const { data } = activities;
    if(data && data.harvestData) {
      let selectedArray = [];
      data.harvestData.forEach((option, index) => {
        if (index > 0) selectedArray.push(option.id);
      });
      this.setState({
        selectedFieldCheckbox: selectedArray
      });
    }
    this.setState({
      selectedField: '',
      selectedNitrogen: ''
    })
  }

  handleSelectedNitrogen = (nitrogenSelected) => {
    this.setState({
      selectedNitrogen: nitrogenSelected
    });
  }

  // on tab change passing page title to the header
  onSelectChange = (value) => {
    const { activeFarm, fetchStatsHeader } = this.props;
    fetchStatsHeader(activeFarm.farm.id, value.value);
    this.setState({
      selectedYear: value.value,
      completeSelectedYear: { "label": value.label, "value": value.value}
    });
  };

  onFieldChange = (event) => {
    const {
      target: { value },
    } = event;
    const {
      props: {
        statsDetails: { activities }
      }
    } = this;
    const { data } = activities;
    if(value.length === (data && data.harvestData).length){
      this.isSetState({
        selectedFieldCheckbox: []
      });
    } else if(value.includes('all')){
      if(data && data.harvestData) {
        let selectedArray = [];
        data.harvestData.forEach((option, index) => {
          if (index > 0) selectedArray.push(option.id);
        });
        this.setState({
          selectedFieldCheckbox: selectedArray
        });
      }
    } else {
      this.isSetState({
        selectedFieldCheckbox: typeof value === 'string' ? value.split(',') : value,
      });
    }
  };
 
  render() {
    const {
      statsHeader: { isLoading, data, hasError },
      intl
    } = this.props;
    const { openNitrogenListModal } = this.state;
    return (
    <div>
      <Header bcTitle={<FormattedMessage id="STATS" />} />
      <div className={`full-width ${'main-content-mobile'}`} style={{paddingTop: '140px'}}>
        {!openNitrogenListModal &&
        <SectionContainer
          styling={{
            backgroundColor: 'white',
            paddingAllSides: 'low',
            mobileOffset: true
          }}
          className = "stats_header_class"
        >
          <StatsHeader
            isLoading = {isLoading}
            data = {data}
            hasError = {hasError}
            intl = {intl}
          />
        </SectionContainer>}
        {!openNitrogenListModal &&
        <SectionContainer
          styling={{
            backgroundColor: 'white',
            paddingAllSides: 'low',
            mobileOffset: true
          }}
          className = "stats_filter_class"
        >
          <div style={{ paddingLeft: '20px' }}>
            <span className="filter-dd-lbl">
              <Text>
                {<FormattedMessage id="CHOOSE_YEAR" />}
              </Text>
            </span>
            <Selectbox
              id="chooseYear"
              styleName="select-box-common filter-dropdown"
              options={getYearsForKPI()}
              value={this.state.completeSelectedYear}
              onChange={value => {
                this.onSelectChange(value);
              }}
            />
          </div>
        </SectionContainer>}
        {!openNitrogenListModal &&
        <SectionContainer
          styling={{
            backgroundColor: 'white',
            paddingAllSides: 'low',
            mobileOffset: true,
            padding: '1.5rem !important'
          }}
        >
        {this.state.selectedYear !== null ? <StatsActivity 
          yearSelected={this.state.selectedYear}
          onSelectChange={this.onSelectChange}
          completeSelectedYear={this.state.completeSelectedYear}
          nitrogenListModal={this.nitrogenListModal}
          openNitrogenListModal={this.state.openNitrogenListModal}
          onFieldChange={this.onFieldChange}
          selectedNitrogen={this.state.selectedNitrogen}
          handleSelectedNitrogen={this.handleSelectedNitrogen}
          selectedFieldCheckbox={this.state.selectedFieldCheckbox}
        /> : ''}
        </SectionContainer>}
        {openNitrogenListModal &&
        <div>
          {this.state.selectedYear !== null ? <StatsActivity 
            yearSelected={this.state.selectedYear}
            onSelectChange={this.onSelectChange}
            completeSelectedYear={this.state.completeSelectedYear}
            nitrogenListModal={this.nitrogenListModal}
            openNitrogenListModal={this.state.openNitrogenListModal}
            onFieldChange={this.onFieldChange}
            selectedNitrogen={this.state.selectedNitrogen}
            handleSelectedNitrogen={this.handleSelectedNitrogen}
            selectedFieldCheckbox={this.state.selectedFieldCheckbox}
          /> : ''}
        </div>}
      </div>
    </div>
    );
  }
}
const mapStateToProps = state => ({
  activeFarm: state.farmList.activeFarm,
  statsHeader: state.statsDetails.statsHeader,
  statsDetails: state.statsDetails,
  userDetails: state.userDetails.data
});

const mapDispatchToProps = dispatch => ({
  fetchStatsHeader: (farmId, yearSelection) => dispatch(StatsAction.fetchStatsHeader(farmId, yearSelection))
});

export default injectIntl(withRouter(connect(mapStateToProps, mapDispatchToProps)(Stats)));