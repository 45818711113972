import _ from 'lodash';
import {
  MANURE_STORAGE_TYPE,
  MANURE_STORAGE_TYPE_ERROR,
  MANURE_STORAGE_LOADING,
  MANURE_STORAGE_LIST,
  MANURE_STORAGE_LIST_ERROR,
  MANURE_STORAGE_SUBMIT_ERROR,
  MANURE_STORAGE_TYPE_SUBMIT_ERROR,
  MANURE_STORAGE_SUBMIT,
  MANURE_STORAGE_LIST_LOADING,
  MANURE_STORAGE_CALCULATE,
  MANURE_STORAGE_CALCULATE_ERROR,
  MANURE_STORAGE_CLEAR
} from 'constants/store/Fertilization';

const initialState = {
  isLoading: false,
  manureList: null,
  manureType: null,
  hasError: false,
  hasManureTypeError: false,
  hasDeleteError: false,
  deleteErrorMsg: null,
  errorMsg: null,
  errorTypeMsg: null,
  manureNewId: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case MANURE_STORAGE_LOADING:
      return {
        ...state,
        isLoading: true,
        hasError: false,
        hasManureTypeError: false,
        submitSuccess: false,
        hasTypeError: false
      };
    case MANURE_STORAGE_LIST_LOADING:
      return {
        ...state,
        isListLoading: true,
        hasListError: false
      };
    case MANURE_STORAGE_CLEAR:
      return { initialState };
    case MANURE_STORAGE_LIST:
      return {
        ...state,
        hasError: false,
        hasManureTypeError: false,
        isListLoading: false,
        manureList: action.payload,
        submitSuccess: false,
        manureNewId: null
      };
    case MANURE_STORAGE_LIST_ERROR:
      return {
        ...state,
        hasListError: true,
        isListLoading: false,
        manureList: null,
        errorMsg: action.payload,
        manureNewId: null,
        errorPattern: action.error
      };
    case MANURE_STORAGE_SUBMIT:
      return {
        ...state,
        hasError: false,
        hasManureTypeError: false,
        isLoading: false,
        submitSuccess: true
      };
    case MANURE_STORAGE_SUBMIT_ERROR:
      return {
        ...state,
        hasError: true,
        hasManureTypeError: false,
        isLoading: false,
        errorMsg: action.payload,
        errorPattern: action.error
      };
    case MANURE_STORAGE_TYPE_SUBMIT_ERROR:
      return {
        ...state,
        hasManureTypeError: true,
        isLoading: false,
        errorTypeMsg: action.payload,
        errorPattern: action.error
      };
    case MANURE_STORAGE_CALCULATE: {
      const ManureIndex = _.findIndex(state.manureList, item => {
        return item.id === action.oldId;
      });
      const manureList = [...state.manureList];
      if (ManureIndex > -1) {
        manureList[ManureIndex] = action.payload;
      }
      return {
        ...state,
        manureList,
        hasError: false,
        hasManureTypeError: false,
        isLoading: false,
        submitSuccess: action.hasRedirect,
        manureNewId: manureList[ManureIndex].id
      };
    }
    case MANURE_STORAGE_CALCULATE_ERROR:
      return {
        ...state,
        hasError: true,
        hasManureTypeError: false,
        isLoading: false,
        errorMsg: action.payload,
        manureNewId: null,
        errorPattern: action.error
      };
    case MANURE_STORAGE_TYPE:
      return {
        ...state,
        hasTypeError: false,
        isLoading: false,
        manureType: action.payload,
        submitSuccess: false
      };
    case MANURE_STORAGE_TYPE_ERROR:
      return {
        ...state,
        hasTypeError: true,
        isLoading: false,
        manureList: null,
        errorMsg: action.payload,
        errorPattern: action.error
      };

    default:
      return state;
  }
};
