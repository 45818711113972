import * as React from 'react';

import { styled } from '@mui/material/styles';
import { Dialog, DialogTitle, Chip, Box, Switch, Checkbox, FormGroup, FormControlLabel, Grid, InputAdornment } from '@mui/material';
import MuiTextBox from 'components/mui-forms/MuiTextBox';

const ToggleMF = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 40,
  height: 24,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#6F7979',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#00696D' : '#00696D',
        opacity: 1,
        border: 0,
      },
      '& .MuiSwitch-thumb': {
        color: '#f1f1f1'
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #00696D',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color: '#6F7979'
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 14,
    height: 14,
    marginTop: '3px',
    marginLeft: '3px',
    color: '#6F7979'
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    border: "3px solid #6F7979",
    backgroundColor: "#E6E9E8"
  },
}));
const dialogTitleStyle = {
  fontFamily: 'Inter',
  fontWeight: 400,
  fontSize: "24px",
  fontStyle: "normal",
  padding: "25px 20px 15px 20px",
  textAlign: "left",
  color: "#191C1C"
}
const dialogSubTitleStyle = { ...dialogTitleStyle, fontSize: "14px", fontStyle: "normal", color: "#3F4949", padding: "0px 22px 6px 22px" }
const dialogSubTitleToggleStyle = { ...dialogTitleStyle, fontSize: "16px", fontStyle: "normal", padding: "0px 24px 12px 24px" }
const dialogSubTitleCheckboxStyle = {
  ...dialogTitleStyle,
  fontSize: "16px",
  fontStyle: "normal",
  padding: "0px 24px",
  color: "#3F4949",
  maxHeight: '300px'
}
const buttonStyle = { fontFamily: 'Inter', fontWeight: 500, fontSize: "14px", color: '#00696D', padding: '10px', background: 'transparent' };

const getFarmFields = (fieldDetails, checkedItems, handleCheckbox) => {  
  if (fieldDetails.data && fieldDetails.data?.length > 0) {
    return fieldDetails.data.map((field, index) => (
      <FormControlLabel 
        control={
          <Checkbox
            checked={!!checkedItems.get(field.fieldId) === undefined ? false : !!checkedItems.get(field.fieldId)}
            name={field.fieldId}
            onClick={e => handleCheckbox(e)}
            sx={{
              color: '#3F4949',
              '&.Mui-checked': {
                color: '#00696D'
              }
            }}
          />
        }
        label={field.displayName}
        sx={{ color: "#3F4949", paddingTop: "10px" }}
      />
    ));
  }
};

const FertilizerModalData = ({ intl, updateFertilizationPlanDialogShow, cancelFertilizationUpdate, saveFertilizationUpdate,
  mineralizedFertilizer, onChangeMineralizedFertilizer, checkedItems, handleCheckboxAll, selectAllFields,
  handleCheckbox, disablePlanUpdateButton, fieldDetails, onChangeUpdateFPQuestion, updateFPQuestion, onChangeUpdateFPLock, updateFPLock, 
  updateFPCutValue, onChangeUpdateFPCutText }) => (
  <div>
    <Dialog onClose={() => {}} open={updateFertilizationPlanDialogShow} PaperProps={{
      style: {
        backgroundColor: '#E6E9E8',
        borderRadius: "28px",
        width: "397px",
        minWidth: "280px",
        maxWidth: "560px"
      }}}>
      <DialogTitle style={dialogTitleStyle}>{intl.formatMessage({ id: 'UPDATE_FERTILIZATION' })}</DialogTitle>
      <Box component="div" style={dialogSubTitleStyle}>
        {intl.formatMessage({ id: 'FERTILIZATION_UPDATE_DIALOG_SUBTITLE' })}
      </Box>
      <div style={{ overflowY: 'auto',
  WebkitOverflowScrolling: 'touch',
  scrollbarWidth: 'thin',
  scrollbarColor: '#6F7979 #E6E9E8'}}>
        <Box component="div" style={dialogSubTitleToggleStyle}>
          <FormControlLabel
            control={<ToggleMF sx={{ m: 1 }} checked={mineralizedFertilizer} onChange={onChangeMineralizedFertilizer} />}
            label={intl.formatMessage({ id: 'FERTILIZATION_MF_TOGGLE' })}
          />
        </Box>
        <Box component="div" style={dialogSubTitleToggleStyle}>
          <FormControlLabel
            control={<ToggleMF sx={{ m: 1 }} checked={updateFPLock} onChange={onChangeUpdateFPLock} />}
            label={intl.formatMessage({ id: 'FERTILIZATION_LOCK_TOGGLE' })}
          />
        </Box>
        <Box component="div" style={dialogSubTitleToggleStyle}>
          <FormControlLabel
            control={<ToggleMF sx={{ m: 1 }} checked={updateFPQuestion} onChange={onChangeUpdateFPQuestion} />}
            label={intl.formatMessage({ id: 'UPDATE_FERTILIZATION_QUESTION' })}
          />
        </Box>
        {updateFPQuestion && 
        <Box>
          <Box component="div" style={dialogSubTitleToggleStyle}>
            {intl.formatMessage({ id: 'UPDATE_FERTILIZATION_TEXT_EXPLAIN' })}
          </Box>
          <Box component="div" style={dialogSubTitleToggleStyle}>
            {intl.formatMessage({ id: 'UPDATE_FERTILIZATION_TEXT' })}
          </Box>
          <Box component="div" style={dialogSubTitleToggleStyle}>
            <Grid container justifyContent="flex-end" sx={{ width: "100%", padding: '10px 0px' }}>
              <Grid item md={12} sm={12} xs={12} sx={{ paddingRight: '10px'}}>
                <MuiTextBox
                  id="cutText1"
                  name="cutText1"
                  value={updateFPCutValue ? updateFPCutValue[0] : null}
                  label={intl.formatMessage({ id: 'UPDATE_FERTILIZATION_CUT1' })}
                  onChange={(e) => onChangeUpdateFPCutText(e, 'cut1')}
                  slotProps={{
                    input: {
                      startAdornment: <InputAdornment position="start">kg</InputAdornment>,
                    },
                  }}
                  variant="outlined"
                  showFullWidth="false"
                />
              </Grid>
            </Grid>
          </Box>
          <Box component="div" style={dialogSubTitleToggleStyle}>
            <Grid container justifyContent="flex-end" sx={{ width: "100%", paddingBottom: '10px' }}>
              <Grid item md={12} sm={12} xs={12} sx={{ paddingRight: '10px'}}>
                <MuiTextBox
                  id="cutText2"
                  name="cutText2"
                  value={updateFPCutValue ? updateFPCutValue[1] : null}
                  label={intl.formatMessage({ id: 'UPDATE_FERTILIZATION_CUT2' })}
                  onChange={(e) => onChangeUpdateFPCutText(e, 'cut2')}
                  endAdornment={<InputAdornment position="end">%</InputAdornment>}
                  variant="outlined"
                  showFullWidth="false"
                />
              </Grid>
            </Grid>
          </Box>
          <Box component="div" style={dialogSubTitleToggleStyle}>
            <Grid container justifyContent="flex-end" sx={{ width: "100%", paddingBottom: '10px' }}>
              <Grid item md={12} sm={12} xs={12} sx={{ paddingRight: '10px'}}>
                <MuiTextBox
                  id="cutText3"
                  name="cutText3"
                  value={updateFPCutValue ? updateFPCutValue[2] : null}
                  label={intl.formatMessage({ id: 'UPDATE_FERTILIZATION_CUT3' })}
                  onChange={(e) => onChangeUpdateFPCutText(e, 'cut3')}
                  endAdornment={<InputAdornment position="end">%</InputAdornment>}
                  variant="outlined"
                  showFullWidth="false"
                />
              </Grid>
            </Grid>
          </Box>
          <Box component="div" style={dialogSubTitleToggleStyle}>
            <Grid container justifyContent="flex-end" sx={{ width: "100%", paddingBottom: '10px' }}>
              <Grid item md={12} sm={12} xs={12} sx={{ paddingRight: '10px'}}>
                <MuiTextBox
                  id="cutText4"
                  name="cutText4"
                  value={updateFPCutValue ? updateFPCutValue[3] : null}
                  label={intl.formatMessage({ id: 'UPDATE_FERTILIZATION_CUT4' })}
                  onChange={(e) => onChangeUpdateFPCutText(e, 'cut4')}
                  endAdornment={<InputAdornment position="end">%</InputAdornment>}
                  variant="outlined"
                  showFullWidth="false"
                />
              </Grid>
            </Grid>
          </Box>
        </Box>}
        <Box sx={{ borderBottom: '1px solid #BEC8C9', margin: '0px 20px', padding: '0px 5px 0px 15px' }}></Box>
        <Box style={dialogSubTitleCheckboxStyle}>
          <FormGroup>
            <FormControlLabel control={
              <Checkbox 
                onChange={() => handleCheckboxAll()}
                checked={selectAllFields}
                sx={{
                  color: '#3F4949',
                  '&.Mui-checked': {
                    color: '#00696D'
                  }
                }}
              />} 
              label={intl.formatMessage({ id: 'ALL' })} sx={{ paddingBottom: "15px", paddingTop: "15px" }}
            />
            {getFarmFields(fieldDetails, checkedItems, handleCheckbox)}
          </FormGroup>
        </Box>
      </div>
      <div style={{padding: "25px", textAlign: "right"}}>
        <span>
          <Chip
            label={intl.formatMessage({ id: 'cancel' })}
            clickable
            style={buttonStyle}
            sx={{ backgroundColor: 'transparent',
              '&:hover, &:focus': {
                backgroundColor: 'transparent',
              }
            }}
            disableRipple
            onClick={cancelFertilizationUpdate}
          />
          <Chip
            label={intl.formatMessage({ id: 'update' })}
            clickable
            style={buttonStyle}
            sx={{ backgroundColor: 'transparent',
              '&:hover, &:focus': {
                backgroundColor: 'transparent',
              }
            }}
            disableRipple
            disabled={disablePlanUpdateButton}
            onClick={saveFertilizationUpdate}
          />
        </span>
      </div>
    </Dialog>
  </div>
);

export default FertilizerModalData;