import { ENSILATION_TYPE_URL } from 'constants/ApiUrl';
import {
  ENSILATION_TYPE_LOADING,
  ENSILATION_TYPE_ERROR,
  ENSILATION_TYPE_SUCCESS
} from 'constants/store/EnsilationType';
import apiRequest from 'utils/ApiRequest';
import { GetLanguageCode } from 'utils/index';

export const fetchEnsilationType = () => async (dispatch, getState) => {
  dispatch({ type: ENSILATION_TYPE_LOADING, payload: true });
  const { preferredLanguage } = getState().userDetails?.data;
  await dispatch(
    apiRequest({
      url: `${ENSILATION_TYPE_URL}?language=${GetLanguageCode(
        preferredLanguage
      )}`,
      method: 'get'
    })
  )
    .then(response => {
      dispatch({ type: ENSILATION_TYPE_SUCCESS, payload: response.data });
    })
    .catch(error => {
      dispatch({ type: ENSILATION_TYPE_ERROR, payload: error });
    });
};
