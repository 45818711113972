import React from 'react';
import { Link } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { Typography, Grid } from '@mui/material';

class SubscriptionCard extends React.Component {
  render() {
    const { intl, subscription } = this.props;
    const titleStyle = {
      fontFamily: 'Inter',
      fontStyle: 'normal',
      letterSpacing: '0.5px',
    };

    return (
      <li className={`list-item`}>
        <Link className={`settings-list-item-link-mobile`} to="#">
          <Grid container>
            <Grid item sm={12} xs={12}>
              <Typography style={{ ...titleStyle, color: '#3F4949', fontSize: '12px', fontWeight: 500, lineHeight: '16px' }}>
                {intl.formatMessage({ id: 'SUBSCRIPTION_TITLE1' })}
              </Typography>
              <Typography style={{ ...titleStyle, color: '#191C1C', fontSize: '16px', fontWeight: 400, lineHeight: '24px' }}>
                {subscription?.orgNo}
              </Typography>
            </Grid>
            <Grid item sm={12} xs={12} sx={{paddingTop: '15px'}}>
              <Typography style={{ ...titleStyle, color: '#3F4949', fontSize: '12px', fontWeight: 500, lineHeight: '16px' }}>
                {intl.formatMessage({ id: 'SUBSCRIPTION_TITLE2' })}
              </Typography>
              <Typography style={{ ...titleStyle, color: '#191C1C', fontSize: '16px', fontWeight: 400, lineHeight: '24px' }}>
                {subscription?.emailId}
              </Typography>
            </Grid>
            <Grid item sm={12} xs={12} sx={{paddingTop: '15px'}}>
              <Grid container>
                <Grid item sm={6} xs={6}>
                  <Typography style={{ ...titleStyle, color: '#3F4949', fontSize: '12px', fontWeight: 500, lineHeight: '16px' }}>
                    {intl.formatMessage({ id: 'SUBSCRIPTION_TITLE3' })}
                  </Typography>
                  <Typography style={{ ...titleStyle, color: '#191C1C', fontSize: '16px', fontWeight: 400, lineHeight: '24px' }}>
                    {subscription?.quarter}
                  </Typography>
                </Grid>
                <Grid item sm={6} xs={6}>
                  <Typography style={{ ...titleStyle, color: '#3F4949', fontSize: '12px', fontWeight: 500, lineHeight: '16px' }}>
                    {intl.formatMessage({ id: 'SUBSCRIPTION_TITLE4' })}
                  </Typography>
                  <Typography style={{ ...titleStyle, color: '#191C1C', fontSize: '16px', fontWeight: 400, lineHeight: '24px' }}>
                    {subscription?.year}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Link>
      </li>
    );
  }
}

export default injectIntl(SubscriptionCard);