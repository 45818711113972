import _ from 'lodash';

/** Validate email */
export function ValidateEmail(email) {
  const emailRegx = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return emailRegx.test(email);
}
/** Check string has number  */
export function hasNumber(myString) {
  return /\d/.test(myString);
}
/** Name validation  */
export function Namevalidation(name) {
  const nameText = name.trim();
  if (hasNumber(nameText)) {
    return { valid: false, message: 'NameValdation_1' };
  }
  if (nameText.length < 2) {
    return { valid: false, message: 'NameValdation_2' };
  }
  if (nameText.length > 50) {
    return { valid: false, message: 'NameValdation_3' };
  }
  return { valid: true };
}

/** Password validation */
export function PasswordValidation(password) {
  const passwordRegx = /^(?=.*[A-Z])(?=.*\d)[a-zA-Z!@æÆøØåÅàáâãåāôòóœōõÄäÖöÜüẞß#$%^&*?\d]{8,}$/;
  // use this if one lowercase must(?=.*[a-z])
  return passwordRegx.test(password);
}

export function PhoneValidation(number) {
  const passwordRegx = /^((0047)?|(\+47)?|(47)?)\d{8}$/;
  // use this if one lowercase must(?=.*[a-z])
  return passwordRegx.test(number);
}

/* Only number validation */
export function NumberValidation(number) {
  const numberRegex = /^\d*$/;
  return numberRegex.test(number);
}

/* decimal number with precision 2 validation */
export function DecimalValidation(value) {
  const str = value.toString();
  const decimalRegex = /^[0-9]*([,.][0-9]{1,2})?$/;
  const decimalPortion = str.split('.')[1] || str.split(',')[1];
  if (decimalPortion && decimalPortion.length > 2) {
    return { valid: false, message: 'AEF_PMS2' };
  }
  if (!decimalRegex.test(str)) {
    return { valid: false, message: 'AEF_PMS' };
  }
  return { valid: true };
}

/* decimal number with precision 2 validation */
export function IntegerValidation(value) {
  const str = value.toString();
  const decimalRegex = /^[0-9]*?$/;
  if (!decimalRegex.test(str)) {
    return { valid: false, message: 'AEF_PMS' };
  }
  return { valid: true };
}

export function FarmFieldValidation(value, maxChar, type) {
  const nameRegex = /[`~<>;':"[\]|{}=!@#$%^&*]/;
  const charLength = maxChar || 35;
  if (value.length > charLength) {
    return { valid: false, message: 'AF_VALIDATION_1' };
  }
  /*if ((value.indexOf('.') !== -1 || value.indexOf(',') !== -1) && type === 'name') {
    return { valid: false, message: 'invalid' };
  }*/
  if (nameRegex.test(value)) {
    return { valid: false, message: 'invalid' };
  }
  return { valid: true };
}

export function FarmUsageId(value) {
  const valLength = value.trim().length;
  if (
    value.indexOf('.') !== -1 ||
    !NumberValidation(value) ||
    !(valLength >= 1 && valLength <= 25) ||
    value < 1
  ) {
    return { valid: false, message: 'AF_VALIDATION_5' };
  }
  return { valid: true };
}

export function PostalCodeValidation(value) {
  if (!NumberValidation(value) || value.length > 5) {
    return { valid: false, message: 'invalid' };
  }
  return { valid: true };
}

export function OrganizationalNumberValidation(value) {
  if (!NumberValidation(value) || value.length !== 9) {
    return { valid: false, message: 'AF_VALIDATION_7' };
  }
  return { valid: true };
}

export function MunicipalityNumberValidation(value) {
  if (!NumberValidation(value) || value.length !== 4) {
    return { valid: false, message: 'AF_VALIDATION_8' };
  }
  return { valid: true };
}

/** Store details in local storage */

export function SetLocalData(item, obj) {
  if (typeof Storage !== 'undefined') {
    localStorage.setItem(item, btoa(JSON.stringify(obj)));
  }
}

/** get details in local storage */

export function GetLocalData(item) {
  const data = localStorage.getItem(item);
  if (data) {
    return JSON.parse(atob(data));
  }
  return null;
}
/** remove details in local storage */
export async function DeleteLocalData(item) {
  await null;
  return localStorage.removeItem(item);
}

/** Get day difference */
export function GetDayTimeDiff(startTime, endTime) {
  const diffTime = Math.abs(endTime.getTime() - startTime.getTime());
  const days = parseInt(diffTime / (1000 * 3600 * 24), 10);
  const minutes = parseInt(diffTime / (1000 * 60), 10);
  return { days, minutes };
}

export function MinsToHrsMins(mins) {
  let hours = Math.floor(mins / 60);
  let minutes = Math.round(mins % 60);
  hours = hours < 10 ? `0${hours}` : hours;
  minutes = minutes < 10 ? `0${minutes}` : minutes;
  return { hours, minutes };
}

/**
 * get hours and minutes by passing seconds
 * @param {int} seconds
 */
export function SecondsTohoursMinutes(seconds) {
  const hours = Math.floor(seconds / 60 / 60);
  const minutes = Math.floor(seconds / 60) - hours * 60;
  return { hours, minutes };
}

/** Encode Image data from aws */
export function EncodeImgData(data) {
  const str = String.fromCharCode.apply(null, new Uint8Array(data));
  return btoa(str).replace(/.{76}(?=.)/g, '$&\n');
}

/** To get language code */
export function GetLanguageCode(lang) {
  switch (lang) {
    case 'English':
      return 'en';
    case 'Nynorsk':
      return 'nn';
    case 'Bokmål':
      return 'nb';
    default:
      return 'nb';
  }
}

/** Store details in local storage */

export function SetSessionData(item, obj) {
  if (typeof Storage !== 'undefined') {
    sessionStorage.setItem(item, btoa(JSON.stringify(obj)));
  }
}

/**
 * get details in local storage
 * @param {string} item
 * @returns {*} stored value
 */

export function GetSessionData(item) {
  const data = sessionStorage.getItem(item);
  if (data) {
    return JSON.parse(atob(data));
  }
  return null;
}

/** remove details in local storage */
export async function DeleteSessionData(item) {
  await null;
  return sessionStorage.removeItem(item);
}

/** get browser language
 * @returns {string}
 */
export function BrowserLanguage() {
  const lang =
    (navigator.languages && navigator.languages[0]) || navigator.language || navigator.userLanguage;
  return lang.substr(0, 2);
}
/**
 * get the value to in between the string.
 *
 * @param str, startStr, endStr - {string || Char}
 * @returns {string}
 */
export function GetInbetweenString(str, startStr, endStr) {
  return str.split(startStr).pop().split(endStr).shift();
}

/**
 * to get the size of the data
 * @param {int} bytes
 * @param {int} decimals
 * @returns {string}
 */
export function FormatBytes(bytes, decimals = 2) {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat(bytes / k ** i).toFixed(dm)} ${sizes[i]}`;
}

/** check role permissions for each action
 * @param {string, object, object} action, activeFarm, rolePermissions
 * @returns {boolean}
 *
 */
export function checkPermissions(action, activeFarm, rolePermissions) {
  let result = false;
  if (activeFarm && activeFarm.role) {
    _.map(rolePermissions.data, item => {
      if (item.role.roleName === activeFarm.role.roleName) {
        _.map(item.permissions, (val, key) => {
          if (key === action) {
            result = val;
          }
        });
      }
    });
  }
  if (activeFarm && activeFarm.superAdmin) {
    result = true;
  }
  if (action === 'createFarm') {
    result = true;
  }
  return result;
}

export function getYears(startYear) {
  const currentYear = new Date().getFullYear()+1;
  const years = [];
  let startingYear = startYear || 1969;
  startingYear -= 1;
  while (startingYear < currentYear) {
    startingYear += 1;
    years.push({ label: startingYear, value: startingYear });
  }
  return years.reverse();
}

export function getYearsField(startYear) {
  let currentYear = new Date().getFullYear();
  const years = [];
  years.push({ label: "", value: "" });
  while (currentYear >= 1990) {
    years.push({ label: currentYear, value: currentYear });
    currentYear = currentYear-1;
  }
  return years;
}

export function getSoilTypes() {
  const soilTypes = [];
  soilTypes.push({ label: "1 - Grovsand", value: 1 });
  soilTypes.push({ label: "2 - Mellomsand", value: 2 });
  soilTypes.push({ label: "3 - Finsand", value: 3 });
  soilTypes.push({ label: "4 - Siltig grovsand", value: 4 });
  soilTypes.push({ label: "5 - Siltig mellomsand", value: 5 });
  soilTypes.push({ label: "6 - Siltig finsand", value: 6 });
  soilTypes.push({ label: "7 - Sandig silt", value: 7 });
  soilTypes.push({ label: "8 - Silt", value: 8 });
  soilTypes.push({ label: "9 - Lettleire", value: 9 });
  soilTypes.push({ label: "10 - Siltig leittleir", value: 10 });
  soilTypes.push({ label: "11 - Mellomleire", value: 11 });
  soilTypes.push({ label: "12 - Stiv Leire", value: 12 });
  soilTypes.push({ label: "13 - Mineralblandet moldjord", value: 13 });
  soilTypes.push({ label: "14 - Organisk jord", value: 14 });
  return soilTypes;
}

export function getMoldClass() {
  const moldClass = [];
  moldClass.push({ label: "1 - Moldfattig (0-2,9%)", value: 1 });
  moldClass.push({ label: "2 - Moldholdig (3 - 4,4%)", value: 2 });
  moldClass.push({ label: "3 - Moldholdig (4,5 - 12,4%)", value: 3 });
  moldClass.push({ label: "4 - Moldholdig (12,5 - 20,4%)", value: 4 });
  moldClass.push({ label: "5 - Moldholdig (20,5 - 40,4%)", value: 5 });
  moldClass.push({ label: "6 - Organisk (> 40,4%)", value: 6 });
  return moldClass;
}

export function getYearsForKPI() {
  const currentYear = new Date().getFullYear();
  const years = [];
  let startingYear = 2017;
  while (startingYear <= currentYear) {
    years.push({ label: startingYear, value: startingYear });
    startingYear += 1;
  }
  return years.reverse();
}

export function getHarvest(totalHarvest) {
  const harvest = [];
  let startingHarvest = 1;
  harvest.push({ label: '', value: '' });
  while (startingHarvest <= totalHarvest) {
    harvest.push({ label: startingHarvest, value: startingHarvest });
    startingHarvest++;
  }
  return harvest;
}

export function getCurrentYears() {
  const yearList = [new Date().getFullYear() + 1, new Date().getFullYear()];
  return yearList?.map(item => ({
    value: item,
    label: item
  }));
}

/** construct new Date object with provided date and time
 * @param {string, string} date, time
 * @returns {Date}
 *
 */
export function GetDateObj(date, time) {
  const [day, month, year] = date.split('.');
  if (time && time !== '00:00') {
    const [hour, min] = time ? time.split(':') : [0, 0];
    if (!date) {
      const currentDate = new Date();
      return new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        currentDate.getDate(),
        hour,
        min
      );
    }
    return new Date(year, month - 1, day, hour, min);
  }
  if (date) {
    return new Date(year, month - 1, day);
  }
  return null;
}

/** destructuring date and time into appropriate formats from Date object
 * @param {Date} date
 * @returns {object}
 *
 */
export function GetValuesFromDate(date) {
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString();
  const day = date.getDate().toString();
  const hours = date.getHours().toString();
  const mins = date.getMinutes().toString();
  return {
    date: `${day < 10 && day.length === 1 ? 0 + day : day}.${
      month < 10 && month.length === 1 ? 0 + month : month
    }.${year}`,
    time: `${hours < 10 && hours.length === 1 ? 0 + hours : hours}:${
      mins < 10 && mins.length === 1 ? 0 + mins : mins
    }`
  };
}

/** parsing ios date string to local date
 * @param {string} dateStr
 * @returns {Date}
 *
 */
export function ParseISOString(dateStr) {
  const isoStr = dateStr.split(/\D+/);
  return new Date(
    Date.UTC(isoStr[0], isoStr[1] - 1, isoStr[2], isoStr[3], isoStr[4], isoStr[5], isoStr[6])
  );
}

/**
 * To append 0 for day/month less than 10
 */
export function AppendZeroToDayAndMonth(val) {
  return val < 10 ? `0${val}` : val;
}

/**
 * To change map co-ordinates to array of objects and to save in store
 * @param {string} coOrdsString
 */
export function ParseToPayloadObjFormat(coOrdsString) {
  const newArr = [];
  _.map(JSON.parse(coOrdsString), item => {
    const newObj = {};
    const [lat, lng] = item;
    newObj.lattitude = lat;
    newObj.longitude = lng;
    newArr.push(newObj);
  });
  return newArr;
}

/**
 * To change map co-ordinates to array of arrays to pass to drawPolygon
 * @param {object} coOrdsArr
 */
export function StringifyToPolygonArrFormat(coOrdsArr) {
  const newArr = [];
  _.map(coOrdsArr, item => {
    const subArr = [];
    subArr.push(item.lattitude);
    subArr.push(item.longitude);
    newArr.push(subArr);
  });
  return newArr;
}

/**
 * Dynamic section Amount validation
 *
 */
export function AmountValidation(value) {
  const str = value && value.toString();
  const decimalRegex = /^[0-9]*([,.][0-9]{1,})?$/;
  return decimalRegex.test(str);
}

/**
 * adding zero before (padstring)
 * @param {int} number
 */

export function PadString(number) {
  return number.toString().padStart(2, '0');
}

/**
 * Escape regular expersion check
 */
export function EscapeRegExp(str) {
  if (!_.isString(str)) {
    return '';
  }
  return str.replace(/[-[\]/{}()*+?.\\^$|]/g, '\\$&');
}

// change date format to yyyy-mm-dd
export function AssetsDateFormate(date) {
  const [dd, mm, yyyy] = date.split('.');
  return `${yyyy}-${mm}-${dd}`;
}

/**
 * Remove Item in the array if exist4
 * @param{array, value }
 *  */
export function removeArrayElm(values, item) {
  const index = values.indexOf(item);
  if (index !== -1) {
    values.splice(index, 1);
  }
  return values;
}

/**
 * get the dropdown from the property
 * @param {string} elm
 * @param {array} dropdownList
 */

export const getDropDownOptions = (elm, dropdownList) => {
  const [elmDropdown] = _.filter(dropdownList, ['element', elm]);
  return elmDropdown.leaves?.map(item => ({
    value: item,
    label: item
  }));
};

export const getDropDownValues = dropdownList => {
  return dropdownList.data?.map(item => ({
    value: item.id,
    label: item.name
  }));
};

export const getDropDownValuesForYear = dropdownList => {
  return dropdownList?.map(item => ({
    value: item,
    label: item
  }));
};

export const getDropDownValuesForYearValue = () => {
  let dropdownListArray = [];
  let currentDate = new Date();
  let currentYear = currentDate.getFullYear();
  dropdownListArray.push({value: currentYear, label: currentYear});
  dropdownListArray.push({value: currentYear-1, label: currentYear-1});
  dropdownListArray.push({value: currentYear-2, label: currentYear-2});
  dropdownListArray.push({value: currentYear-3, label: currentYear-3});
  dropdownListArray.push({value: currentYear-4, label: currentYear-4});
  return dropdownListArray;
};

export const getYesOrNoOption = (intl) => {
  let dropdownListArray = [];
  dropdownListArray.push({value: true, label: intl.formatMessage({ id: 'YES' })});
  dropdownListArray.push({value: false, label: intl.formatMessage({ id: 'NO' })});
  return dropdownListArray;
};

export const getSoilSampleDropdown = () => {
  let dropdownListArray = [];
  dropdownListArray.push({value: 'EUROFINS', label: 'Eurofins'});
  dropdownListArray.push({value: 'SKIFTEPLAN', label: 'Skifteplan'});
  dropdownListArray.push({value: 'JORDPLAN', label: 'Jordplan'});
  return dropdownListArray;
};

export function getDropDownListValues(dropdownList,requiredStatus) {
  let dropdownListArray = [];
  if(requiredStatus) {
    dropdownListArray.push({value: '', label: ''});
  }
  dropdownList && dropdownList.map(item => (
    dropdownListArray.push({
    value: item.id,
    label: item.name
  })));
  return dropdownListArray;
};

export function getDropDownActivitiesList(dropdownList) {
  let dropdownListArray = [];
  dropdownListArray.push({value: '', label: ''});
  dropdownList && dropdownList.map(item => (
    dropdownListArray.push({
    value: item.id,
    label: item.activityName
  })));
  return dropdownListArray;
};

export const getDropDownMethod = dropdownList => {
  return dropdownList.fieldMethodList?.map(item => ({
    value: item.id,
    label: item.method
  }));
};

export const getDropDownNameValues = dropdownList => {
  return dropdownList?.map(item => ({
    value: item.name,
    label: item.name
  }));
};

export const getDropDownMethodNew = dropdownList => {
  let dropdownListArray = [];
  //dropdownListArray.push({value: '', label: ''});
  dropdownList.fieldMethodList && dropdownList.fieldMethodList.map(item => (
    dropdownListArray.push({
    value: item.id,
    label: item.method
  })));
  return dropdownListArray;
};

export const getDropDownValuesNew = dropdownList => {
  let dropdownListArray = [];
  //dropdownListArray.push({value: '', label: ''});
  dropdownList.data && dropdownList.data.map(item => (
    dropdownListArray.push({
    value: item.id,
    label: item.name
  })));
  return dropdownListArray;
};

export const getDropDownFieldsStatuses = dropdownList => {
  return dropdownList?.map(item => ({
    value: item.id,
    label: item.name
  }));
};

export const getFieldNumbers = (disabledNumbersList, currentValue) => {
  let dropdownListArray = [];
  const numbers = Array.from({ length: 10000 }, (_, i) => i + 1);

  // Filter the currentValue from the disabledNumbers array
  const updatedDisabledNumbers = disabledNumbersList.filter((number) => number !== currentValue);

  // Sort numbers: Non-disabled first, then disabled
  const sortedNumbers = numbers.sort((a, b) => {
    const aIsDisabled = updatedDisabledNumbers.includes(a);
    const bIsDisabled = updatedDisabledNumbers.includes(b);
    if (aIsDisabled === bIsDisabled) {
      return a - b; // Both either disabled or not, sort numerically
    }
    return aIsDisabled ? 1 : -1; // Place disabled numbers after non-disabled
  });

  // Add a default option at the beginning
  dropdownListArray.push({ value: null, label: '', disabled: false });

  // Create dropdown options
  sortedNumbers.forEach((item) => {
    dropdownListArray.push({
      value: item,
      label: item,
      disabled: updatedDisabledNumbers.includes(item)
    });
  });

  return dropdownListArray;
};

export function getDropDownMineralFertilizers(dropdownList) {
  let dropdownListArray = [];
  //dropdownListArray.push({value: '', label: ''});
  dropdownList !== null && dropdownList.length > 0 && dropdownList.map(item => (
    dropdownListArray.push({
    value: item,
    label: item
  })));
  return dropdownListArray;
};

export function getDropDownValuesMineralFertilizers(dropdownList) {
  let dropdownListArray = [];
  dropdownList !== null && dropdownList.length > 0 && dropdownList.map(item => (
    dropdownListArray.push({
    value: item.name,
    label: item.name
  })));
  return dropdownListArray;
};

export function getDropDownManureStorages(dropdownList) {
  let dropdownListArray = [];
  //dropdownListArray.push({value: '', label: ''});
  dropdownList !== null && dropdownList.length > 0 && dropdownList.map(item => (
    dropdownListArray.push({
    value: item.id,
    label: item.name
  })));
  return dropdownListArray;
};

export const getDropDownWeatherStations = dropdownList => {
  return dropdownList?.map(item => ({
    value: item.stationId,
    label: item.stationName
  }));
};

export const getDropDownActivities = dropdownList => {
  return dropdownList?.map(item => ({
    value: item.id,
    label: item.activityName
  }));
};

export const getDropDownNameSeasons = dropdownList => {
  return dropdownList?.map(item => ({
    value: item.id,
    label: item.season
  }));
};

export const formatDate = date => {
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString();
  const day = date.getDate().toString();
  if (date) {
    return `${PadString(day)}.${PadString(month)}.${year}`;
  }
  return '00.00.0000';
};

export const formatDateValue = date => {
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString();
  const day = date.getDate().toString();
  if (date) {
    return `${year}-${PadString(month)}-${PadString(day)}`;
  }
  return '';
};

export const formatTaskDateValue = date => {
  if (date) {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString();
    const day = date.getDate().toString();
    return `${day}.${month}.${year}`;
  }
  return '';
};

export const formatTaskTimeValue = date => {
  if (date) {
    const hours = date.getHours();
    const minutes = date.getMinutes();
    return `${hours}:${minutes}`;
  }
  return '';
};

export const getValueByDateAndTime = (date,time) => {
  const dateValue = date.split(".");
  const timeValue = time.split(":");
  if (date) {
    return new Date(dateValue[2], dateValue[1]-1, dateValue[0], timeValue[0], timeValue[1]);
  }
  return '';
};

export const SoilSampleDateFormate = str => {
  const dateFormate = str.split('-');
  return `${dateFormate[1]}-${dateFormate[0]}-${dateFormate[2]}`;
};

export const CheckInteger = number => {
  const num = parseInt(number, 10);
  return Number.isInteger(num);
};
