import React, { useState, useRef, useEffect } from "react";
import { Typography, Box, Chip, Dialog, Switch, FormControlLabel } from '@mui/material';
import { styled } from '@mui/material/styles';
import MuiDatePicker from 'components/mui3-forms/MuiDatePicker';
import MuiTextField from 'components/mui3-forms/MuiTextField';
import MuiTextFieldArea from 'components/mui3-forms/MuiTextFieldArea';
import MuiSelectMobileDropdown from 'components/mui3-forms/MuiSelectMobileDropdown';
import MuiCollapseComponent from 'components/mui3-forms/MuiCollapseComponent';
import * as taskTemplateStyle from 'mui-mobile-components/kart/fieldmap/tasktemplates/TaskTemplateStyle';
import AddVehicleModal from 'mui-mobile-components/kart/fieldmap/vehicleModals/AddVehicleModal';
import AddEquipmentModal from 'mui-mobile-components/kart/fieldmap/vehicleModals/AddEquipmentModal';
import AddIcon from '@mui/icons-material/Add';
import _ from 'lodash';
import { Close as CloseIcon } from '@mui/icons-material';
import { ACT7, PESTICIDE_MINERAL_FERTILIZER_EN, PESTICIDE_MINERAL_FERTILIZER_NB } from 'constants/index';

const ToggleMF = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 52,
  height: 32,
  padding: 0,
  position: 'relative',
  '&:hover .MuiSwitch-thumb': {
    backgroundColor: '#3F4949', // Thumb color change on hover
  },
  '& .MuiSwitch-switchBase': {
    padding: 0,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#ffffff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#005A5C' : '#00696D',
        opacity: 1,
        border: 0,
      },
      '& .MuiSwitch-thumb': {
        backgroundColor: '#FFFFFF',
        width: 24,
        height: 24,
        marginTop: '4px',
        marginRight: '3px',
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.3)',
      },
      '&:hover .MuiSwitch-thumb': {
        backgroundColor: '#6FF6FC', // Thumb color change on hover
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #00696D',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color: '#BDBDBD',
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 16,
    height: 16,
    marginTop: '8px',
    marginLeft: '8px',
    backgroundColor: '#6F7979',
    position: 'relative', // Ensure pseudo-element is positioned correctly
    '&::before': {
      content: '""',
      position: 'absolute',
      top: '50%',
      left: '50%',
      width: '32px', // Default size for the grey circle
      height: '32px', // Default size for the grey circle
      backgroundColor: 'rgba(128, 128, 128, 0.3)', // Grey transparent background
      borderRadius: '50%', // Ensure the grey circle is fully rounded
      transform: 'translate(-50%, -50%)',
      opacity: 0,
      transition: 'opacity 0.2s ease-in-out, width 0.2s, height 0.2s', // Smooth size transition
    },
  },
  '&:hover .MuiSwitch-track': {
    backgroundColor: '#D3D3D3', // Change background when hovering anywhere on switch
    borderRadius: '100px', // Keep the track background rounded
  },
  '& .MuiSwitch-track': {
    borderRadius: '100px', // Ensure the track is rounded
    backgroundColor: '#E6E9E8',
    border: '3px solid #6F7979',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 300,
    }),
  },
}));

const AddTaskTemplate6 = (props) => {
  const {intl, activeFarm, selectedFields, selectedActivity, taskDetails, closeNewTask, saveNewTask,
    createdVehicleId, createdEquipmentId, activitiesCommon, taskKartToggle, handleRemoveFieldId
  } = props;
  const initialFormState = {
    farmId: activeFarm.farm.id,
    activityId: selectedActivity?.id,
    fieldIds: selectedFields[0]?.fieldId,
    activityName: selectedActivity?.activityName,
    startTime: new Date(new Date().setHours(0, 0, 0, 0)),
    note: "",
    comboSowingAmount: "",
    ...Object.fromEntries(
      selectedFields[0]?.fieldId.map(field => [`comboFertiAmount_${field}`, ""])
    ),
    sowingTypeText: "",
    comboFertiAmount: "",
    mineralFertiType: "",
    comboSowingUnit: "kg/daa",
    comboFertiUnit: "kg/daa",    
    vehicleAssetId: "",
    equipmentAssetId: "",
    waterJournal: null,
    waterJournalAdded: false,
    perField: false
  };
  const [formState, setFormState] = useState(initialFormState);
  const [formErrors, setFormErrors] = useState({});
  const initialStateRef = useRef(initialFormState);
  const [isModalOpen, setModalOpen] = useState(false);
  const [showAddVehicleDialog, setShowAddVehicleDialog] = useState(false);
  const [showAddEquipmentDialog, setShowAddEquipmentDialog] = useState(false);
  const [isPerField, setIsPerField] = useState(false);

  useEffect(() => {
    setFormState((prevState) => {
      let preSelected = formState.perField;
      if(selectedFields[0]?.fieldId.length <= 1) {
        preSelected = false;
      }
      const updatedFormState = {
        ...prevState,
        fieldIds: selectedFields[0]?.fieldId,
        perField: preSelected
      };
      initialStateRef.current = updatedFormState;
      return updatedFormState;
    });
  }, [selectedFields, formState.perField]);

  useEffect(() => {
    if (taskKartToggle?.getTaskList?.taskActivity) {
      setFormState((prevState) => {
        const updatedFormState = {
          ...prevState,
          comboSowingUnit: taskKartToggle.getTaskList.taskActivity.comboSowingUnit || "kg/daa",
          comboFertiUnit: taskKartToggle.getTaskList.taskActivity.comboFertiUnit || "kg/daa",
          vehicleAssetId: taskKartToggle.getTaskList.vehicleAssetId,
          equipmentAssetId: taskKartToggle.getTaskList.equipmentAssetId
        };
        
        initialStateRef.current = updatedFormState;
        return updatedFormState;
      });
    }
  }, [taskKartToggle]);

  const unitOptions1 = [    
    { label: 'kg/daa', value: 'kg/daa' },
    { label: intl.formatMessage({ id: 'PLANT_UNIT' }), value: 'plants/m^2' }
  ];

  const unitOptions2 = [
    { label: 'kg', value: 'kg' },
    { label: 'kg/daa', value: 'kg/daa' }
  ];

  const mineralFertilizerOptions = () => {
    let mineralFertilizerOptionsArray = [];
    _.filter(activitiesCommon, ['activityId', ACT7])[0].dropdowns[0].elements.map(elements => (
      (elements.element === PESTICIDE_MINERAL_FERTILIZER_EN || elements.element === PESTICIDE_MINERAL_FERTILIZER_NB) &&
      elements.leaves.map(options => (
        mineralFertilizerOptionsArray.push({ label: options, value: options })
      ))
    ))
    return mineralFertilizerOptionsArray;
  }

  const AddVehicle = () => (
    <Box style={taskTemplateStyle.assetAdd} onClick={() => setShowAddVehicleDialog(true)}>
      <Box sx={{ padding: '5px 15px 0px 0px' }}>
        <AddIcon />
      </Box>
      <Typography style={taskTemplateStyle.assetAddHeader}>{intl.formatMessage({ id: 'CT_ANV' })}</Typography>
    </Box>
  );
  const vehicleOptions = () => {
    let vehicleList = [];
    vehicleList.push({value: null, label: <AddVehicle />});
    if (taskDetails.allVehicles && (taskDetails.allVehicles).length > 0) {
      (taskDetails.allVehicles).map(data => (
        vehicleList.push({
          value: data.asset.assetId,
          label: `${data.asset.brand} ${data.asset.model}`
        })
      ));
      return vehicleList;
    }
    return vehicleList;
  };
  const AddEquipment = () => (
    <Box style={taskTemplateStyle.assetAdd} onClick={() => setShowAddEquipmentDialog(true)}>
      <Box sx={{ padding: '5px 15px 0px 0px' }}>
        <AddIcon />
      </Box>
      <Typography style={taskTemplateStyle.assetAddHeader}>{intl.formatMessage({ id: 'CT_ANE' })}</Typography>
    </Box>
  );
  const equipmentOptions = () => {
    let equipmentList = [];
    equipmentList.push({value: null, label: <AddEquipment />});
    if (taskDetails.allEquipments && (taskDetails.allEquipments).length > 0) {
      (taskDetails.allEquipments).map(data => (
        equipmentList.push({
          value: data.asset.assetId,
          label: `${data.asset.brand} ${data.asset.model}`
        })
      ));
      return equipmentList;
    }
    return equipmentList;
  };

  const handleFieldChange = (fieldName, value) => {
    setFormState((prevState) => ({
      ...prevState,
      [fieldName]: value
    }));
  };

  const handleNumericValidation = (fieldName, value) => {
    if (/^\d*([.,]?\d*)?$/.test(value)) {
      setFormState((prevState) => ({
        ...prevState,
        [fieldName]: value,
      }));
      setFormErrors((prevErrors) => {
        const newErrors = { ...prevErrors };
        delete newErrors[fieldName];
        return newErrors;
      });
    } else {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        [fieldName]: intl.formatMessage({ id: 'DYNAMIC_SECTION_AMOUNT_VAL_MSG' }),
      }));
    }
  };

  const handleToggleChange = () => {
    setFormState((prevState) => ({
      ...prevState,
      'perField': !isPerField,
    }));
    setIsPerField((prev) => !prev); // Toggle the state
  };

  const handleCloseField = (field) => {
    handleRemoveFieldId(field);
  };

  const handleSave = () => {
    setModalOpen(false);
    if(formErrors && Object.values(formErrors).length === 0) {
      saveNewTask(formState);
    }
  };

  const handleContinueSave = () => {
    setModalOpen(false);
  };

  const handleCancel = () => {
    let isChanged = JSON.stringify(initialStateRef.current) !== JSON.stringify(formState);
    if (isChanged) {
      setModalOpen(true);
    } else {
      handleCancelButton();
    }
  };

  const handleCancelButton = () => {
    setModalOpen(false);
    closeNewTask();
  };

  const onCloseVehicleDialog = () => {
    setShowAddVehicleDialog(false);
  }

  const onCloseEquipmentDialog = () => {
    setShowAddEquipmentDialog(false);
  }

  return (
    <Box sx={{
      padding: '0px 16px 0px 16px',
      overflowY: 'auto',
      '&::-webkit-scrollbar': {
        width: '5px',
        backgroundColor: 'rgba(0, 0, 0, 0.1)',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#A8A8A8',
        borderRadius: '4px',
        transition: 'background-color 0.3s ease',
      },
      '&::-webkit-scrollbar-thumb:hover': {
        backgroundColor: '#B8B8B8',
      }}}
    >
      <Box style={taskTemplateStyle.headerSticky}>
        <Box style={taskTemplateStyle.modalDialogHeader}>
          <Box style={taskTemplateStyle.modalDialogHeader1}>
            <Typography sx={{ paddingTop: '5px', paddingRight: '10px' }}>
              <CloseIcon
                width="24"
                height="24"
                onClick={handleCancel}
                style={{ cursor: 'pointer' }}
              />
            </Typography>
            <Typography style={taskTemplateStyle.modalDialogTitle}>{selectedActivity?.activityName}</Typography>
          </Box>
        </Box>
        <Box style={taskTemplateStyle.modalDialogSubHeader}>
          <Typography style={taskTemplateStyle.modalDialogSubTitle}>{selectedFields[0].fieldName.join(", ")}</Typography>
        </Box>
      </Box>
      <Box>
        <Box sx={{ display: "flex", alignItems: "center", paddingTop: '28px' }}>
          <MuiDatePicker
            defaultLabel={intl.formatMessage({ id: 'FIELD_WEATHER_DATE' })}
            dateFormat={"dd/MM/yyyy"}
            fieldName='startTime'
            onChange={handleFieldChange}
            maxDateOption={true}
          />
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", paddingTop: '24px' }}>
          <MuiSelectMobileDropdown
            defaultLabel={intl.formatMessage({ id: 'TASK_REGISTRATION_MINERAL' })}
            options={mineralFertilizerOptions()}
            fieldName="mineralFertiType"
            onChange={handleFieldChange}
            textLabel={intl.formatMessage({ id: 'TASK_REGISTRATION_MINERAL' })}
          />
        </Box>
        {selectedFields[0].fieldId.length > 1 &&
        <Box sx={{ display: "flex", alignItems: "center", paddingTop: '44px', paddingBottom: '8px' }}>
          <FormControlLabel
            label={
              <Box sx={{ pointerEvents: 'none' }}> 
                <Typography sx={{ 
                  color: 'var(--M3-sys-light-on-surface, #191C1C)',
                  fontFamily: 'Inter',
                  fontSize: '16px',
                  fontStyle: 'normal',
                  fontWeight: '400',
                  lineHeight: '24px',
                  letterSpacing: '0.5px',
                  paddingRight: '16px'
                }}>
                  {intl.formatMessage({ id: 'PER_SKIFTE' })}
                </Typography>
              </Box>
            }
            sx={{
              display: "flex",
              width: "100%",
              flexDirection: "row-reverse", // Swap label and toggle positions
              marginRight: '6px !important'
            }}
            control={
              <ToggleMF 
                sx={{ m: 0 }} 
                checked={isPerField} 
                onChange={(e) => {
                  e.stopPropagation(); // Prevent row click
                  handleToggleChange();
                }}
              />
            }
          />
        </Box>}
        {(selectedFields[0].fieldId.length > 1 && isPerField) ? (
          selectedFields[0].fieldId.map((field, index) => {
            const fieldKey = `comboFertiAmount_${field}`;
            return (
              <Box key={field} sx={{ display: "flex", alignItems: "center", paddingTop: '24px', gap: 2 }}>
                {/* X Mark */}
                <Typography sx={{ paddingTop: '12px', width: 48, height: 48 }}>
                  <CloseIcon
                      width="24"
                      height="24"
                      style={{ cursor: 'pointer' }}
                      onClick={() => handleCloseField(field)}
                  />
                </Typography>

                {/* Label */}
                <Typography 
                  title={selectedFields[0].fieldName[index]}
                  sx={{
                      color: 'var(--M3-sys-light-on-surface, #191C1C)',
                      fontFamily: 'Inter',
                      fontSize: '16px',
                      fontStyle: 'normal',
                      fontWeight: '400',
                      lineHeight: '24px',
                      letterSpacing: '0.5px',
                      paddingRight: '8px',
                      width: '180px',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap'
                  }}
                >
                  {selectedFields[0].fieldName[index]}
                </Typography>

                {/* TextField */}
                <MuiTextField
                  defaultLabel={intl.formatMessage({ id: 'TASK_REGISTRATION_MENGDE_MINERAL' })}
                  inputAdornment={`${(formState.comboFertiUnit === "" || formState.comboFertiUnit === null) ? 'kg/daa' : (formState.comboFertiUnit === 'plants/m^2' ? intl.formatMessage({ id: 'PLANT_UNIT' }) : formState.comboFertiUnit)}`}
                  fieldName={fieldKey}
                  onChange={handleNumericValidation}
                  numberKeypad={true}
                  errorHelperText={formErrors[fieldKey]}
                />
              </Box>
            );
          })
        ) : (
          <Box sx={{ display: "flex", alignItems: "center", paddingTop: '24px' }}>
            <MuiTextField
              defaultLabel={intl.formatMessage({ id: 'TASK_REGISTRATION_MENGDE_MINERAL' })}
              inputAdornment={`${(formState.comboFertiUnit === "" || formState.comboFertiUnit === null) ? 'kg/daa' : (formState.comboFertiUnit === 'plants/m^2' ? intl.formatMessage({ id: 'PLANT_UNIT' }) : formState.comboFertiUnit)}`}
              fieldName='comboFertiAmount'
              onChange={handleNumericValidation}
              autoFocus={true}
              numberKeypad={true}
              errorHelperText={formErrors['comboFertiAmount']}
            />
          </Box>
        )}
        {/*<Box sx={{ display: "flex", alignItems: "center", paddingTop: '24px' }}>
          <MuiTextField
            defaultLabel={intl.formatMessage({ id: 'TASK_REGISTRATION_MENGDE_MINERAL' })}
            inputAdornment={`${(formState.comboFertiUnit === "" || formState.comboFertiUnit === null) ? 'kg/daa' : (formState.comboFertiUnit === 'plants/m^2' ? intl.formatMessage({ id: 'PLANT_UNIT' }) : formState.comboFertiUnit)}`}
            fieldName='comboFertiAmount'
            onChange={handleNumericValidation}
            numberKeypad={true}
            errorHelperText={formErrors['comboFertiAmount']}
          />
        </Box>*/}
        <Box sx={{ display: "flex", alignItems: "center", paddingTop: '56px' }}>
          <MuiTextField
            defaultLabel={intl.formatMessage({ id: 'TASK_REGISTRATION_MENGDE' })}
            inputAdornment={`${(formState.comboSowingUnit === "" || formState.comboSowingUnit === null) ? 'kg/daa' : formState.comboSowingUnit}`}
            fieldName='comboSowingAmount'
            onChange={handleNumericValidation}
            numberKeypad={true}
            errorHelperText={formErrors['comboSowingAmount']}
          />
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", paddingTop: '24px' }}>
          <MuiTextField
            defaultLabel={intl.formatMessage({ id: 'TASK_REGISTRATION_LANDINGSTYPE' })}
            inputAdornment={''}
            fieldName='sowingTypeText'
            onChange={handleFieldChange}
            errorHelperText={formErrors['sowingTypeText']}
          />
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", paddingTop: '56px' }}>
          <MuiTextFieldArea
            defaultLabel={intl.formatMessage({ id: 'NOTE' })}
            fieldName='note'
            onChange={handleFieldChange}
          />
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", paddingTop: '40px' }}>
          <MuiCollapseComponent
            title={intl.formatMessage({ id: 'TASK_MORE_INFORMATION' })}
            content={
              <Box sx={{ paddingTop: '48px', width: '100%' }}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <MuiSelectMobileDropdown
                    defaultLabel={intl.formatMessage({ id: 'TASK_REGISTRATION_LANDINGSTYPE_UNIT' })}
                    defaultValue={formState.comboSowingUnit || 'kg/daa'}
                    options={unitOptions1}
                    fieldName="comboSowingUnit"
                    onChange={handleFieldChange}
                    textLabel={intl.formatMessage({ id: 'TASK_REGISTRATION_LANDINGSTYPE_UNIT' })}
                    deSelect={false}
                  />
                </Box>
                <Box sx={{ display: "flex", alignItems: "center", paddingTop: '24px' }}>
                  <MuiSelectMobileDropdown
                    defaultLabel={intl.formatMessage({ id: 'TASK_REGISTRATION_MENGDE_MINERAL_UNIT' })}
                    defaultValue={formState.comboFertiUnit || 'kg/daa'}
                    options={unitOptions2}
                    fieldName="comboFertiUnit"
                    onChange={handleFieldChange}
                    textLabel={intl.formatMessage({ id: 'TASK_REGISTRATION_MENGDE_MINERAL_UNIT' })}
                    deSelect={false}
                  />
                </Box>
                <Box sx={{ display: "flex", alignItems: "center", paddingTop: '56px' }}>
                  <MuiSelectMobileDropdown
                    key={createdVehicleId}
                    defaultLabel={intl.formatMessage({ id: 'VEHICLE' })}
                    defaultValue={createdVehicleId}
                    options={vehicleOptions()}
                    fieldName="vehicleAssetId"
                    onChange={handleFieldChange}
                    textLabel={intl.formatMessage({ id: 'VEHICLE' })}
                  />
                </Box>
                <Box sx={{ display: "flex", alignItems: "center", paddingTop: '24px' }}>
                  <MuiSelectMobileDropdown
                    key={createdEquipmentId}
                    defaultLabel={intl.formatMessage({ id: 'EQUIPMENT' })}
                    defaultValue={createdEquipmentId}
                    options={equipmentOptions()}
                    fieldName="equipmentAssetId"
                    onChange={handleFieldChange}
                    textLabel={intl.formatMessage({ id: 'EQUIPMENT' })}
                  />
                </Box>
              </Box>
            }
          />
        </Box>
      </Box>
      <Box style={taskTemplateStyle.buttonBox}>
        <Box style={taskTemplateStyle.buttonBoxBottom}>
          <Chip
            label={intl.formatMessage({ id: 'SAVE' })}
            clickable
            style={taskTemplateStyle.okButtonStyle}
            sx={{ backgroundColor: 'transparent',
              '&:hover, &:focus': {
                backgroundColor: 'transparent',
              }
            }}
            disableRipple
            onClick={handleSave}
          />
        </Box>
      </Box>
      {/* Modal */}
      <Box>    
        <Dialog onClose={() => {}} open={isModalOpen} PaperProps={{
          style: {
            backgroundColor: '#E6E9E8',
            borderRadius: "28px",
            width: "312px",
            minWidth: "280px",
            maxWidth: "312px",
            maxHeight: '30vh'
          }}}>
          <Box style={taskTemplateStyle.cancelModalBox}>
            <Typography style={taskTemplateStyle.cancelModalTitle}>{intl.formatMessage({ id: 'TASK_CANCEL_MODAL_TITLE' })}</Typography>
            <Typography style={taskTemplateStyle.cancelModalSubTitle}>{intl.formatMessage({ id: 'TASK_CANCEL_MODAL_SUB_TITLE' })}</Typography>
          </Box>
          <Box style={taskTemplateStyle.cancelModalBoxButton}>
            <Chip
              label={intl.formatMessage({ id: 'cancel' })}
              clickable
              style={taskTemplateStyle.cancelButtonStyle}
              sx={{ backgroundColor: 'transparent',
                '&:hover, &:focus': {
                  backgroundColor: 'transparent',
                }
              }}
              disableRipple
              onClick={handleCancelButton}
            />
            <Chip
              label={intl.formatMessage({ id: 'TASK_CONTINUE_REGISTRATION' })}
              clickable
              style={taskTemplateStyle.cancelButtonStyle}
              sx={{ backgroundColor: 'transparent',
                '&:hover, &:focus': {
                  backgroundColor: 'transparent',
                }
              }}
              disableRipple
              onClick={handleContinueSave}
            />
          </Box>
        </Dialog>
      </Box>
      <AddVehicleModal
        intl={intl}
        vehicleSelectedId={'new'}
        onCloseVehicleDialog={onCloseVehicleDialog}
        showAddVehicleDialog={showAddVehicleDialog}
        assetType={'vehicle'}
        taskDetails={taskDetails}
      />
      <AddEquipmentModal
        intl={intl}
        equipmentSelectedId={'new'}
        onCloseEquipmentDialog={onCloseEquipmentDialog}
        showAddEquipmentDialog={showAddEquipmentDialog}
        assetType={'equipment'}
        taskDetails={taskDetails}
      />
    </Box>
  );
};

export default AddTaskTemplate6;