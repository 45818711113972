import { MANURE_STORAGE_TYPE_URL, FARM_V1 } from 'constants/ApiUrl';
import {
  MANURE_STORAGE_TYPE,
  MANURE_STORAGE_TYPE_ERROR,
  MANURE_STORAGE_LOADING,
  MANURE_STORAGE_LIST,
  MANURE_STORAGE_LIST_ERROR,
  MANURE_STORAGE_SUBMIT_ERROR,
  MANURE_STORAGE_SUBMIT,
  MANURE_STORAGE_LIST_LOADING,
  MANURE_STORAGE_CALCULATE,
  MANURE_STORAGE_CALCULATE_ERROR,
  MANURE_STORAGE_TYPE_SUBMIT_ERROR
} from 'constants/store/Fertilization';
import apiRequest from 'utils/ApiRequest';
import { GetLanguageCode } from 'utils/index';
import { DELETE, PUT, POST, GET, REDIRECT } from 'constants/index';

/**
 * to manure type
 *
 */
export const fetchManureType = (farmId) => async (dispatch, getState) => {
  dispatch({ type: MANURE_STORAGE_LOADING, payload: true });
  const { preferredLanguage } = getState().userDetails?.data;
  try {
    const response = await dispatch(
      apiRequest({
        url: `${MANURE_STORAGE_TYPE_URL}${farmId}/manurestorages/types?language=${GetLanguageCode(preferredLanguage)}`,
        method: GET,
      })
    );
    dispatch({ type: MANURE_STORAGE_TYPE, payload: response.data });
  } catch (error) {
    dispatch({ type: MANURE_STORAGE_TYPE_ERROR, payload: error, error: 'manuretype' });
  }
};

/**
 * to get Manure list
 * @param {String} farmId
 */
export const fetchManureList = farmId => async (dispatch, getState) => {
  dispatch({ type: MANURE_STORAGE_LIST_LOADING, payload: true });
  const { preferredLanguage } = getState().userDetails?.data;
  await dispatch(
    apiRequest({
      url: `${FARM_V1}/${farmId}/manurestorages?language=${GetLanguageCode(preferredLanguage)}`,
      method: GET
    })
  )
    .then(response => {
      dispatch({ type: MANURE_STORAGE_LIST, payload: response.data });
    })
    .catch(error => {
      dispatch({ type: MANURE_STORAGE_LIST_ERROR, payload: error, error: 'list' });
    });
};

/**
 * to create manure storage
 * @param {String} farmId
 */
export const createManure = (farmId, data) => async (dispatch, getState) => {
  dispatch({ type: MANURE_STORAGE_LOADING, payload: true });
  const { preferredLanguage } = getState().userDetails?.data;
  const dataType = data?.type;
  await dispatch(
    apiRequest({
      url: `${FARM_V1}/${farmId}/manurestorages?language=${GetLanguageCode(preferredLanguage)}`,
      method: POST,
      data
    })
  )
    .then(response => {
      dispatch({ type: MANURE_STORAGE_SUBMIT, payload: response.data });
    })
    .catch(error => {
      dispatch({ type: dataType === undefined ? MANURE_STORAGE_TYPE_SUBMIT_ERROR : MANURE_STORAGE_SUBMIT_ERROR, payload: error, error: 'create' });
    });
};

/**
 * to get Manure list
 * @param {String} farmId
 */
export const deleteManureList = (farmId, ManureId) => async (dispatch, getState) => {
  dispatch({ type: MANURE_STORAGE_LOADING, payload: true });
  const { preferredLanguage } = getState().userDetails?.data;
  await dispatch(
    apiRequest({
      url: `${FARM_V1}/${farmId}/manurestorages/${ManureId}?language=${GetLanguageCode(
        preferredLanguage
      )}`,
      method: DELETE
    })
  )
    .then(response => {
      dispatch({ type: MANURE_STORAGE_SUBMIT, payload: response.data });
    })
    .catch(error => {
      dispatch({ type: MANURE_STORAGE_SUBMIT_ERROR, payload: error, error: 'delete' });
    });
};

/**
 * to post stats activity details
 * @param {String} farmId
 */
export const UpdateManure = (farmId, data, redirect) => async (dispatch, getState) => {
  dispatch({ type: MANURE_STORAGE_LOADING, payload: true });
  const { preferredLanguage } = getState().userDetails?.data;
  await dispatch(
    apiRequest({
      url: `${FARM_V1}/${farmId}/manurestorages/${data.id}?language=${GetLanguageCode(
        preferredLanguage
      )}`,
      method: PUT,
      data
    })
  )
    .then(response => {
      dispatch({
        type: MANURE_STORAGE_CALCULATE,
        payload: response.data,
        hasRedirect: redirect && redirect === REDIRECT,
        oldId: data.id
      });
    })
    .catch(error => {
      dispatch({ type: MANURE_STORAGE_CALCULATE_ERROR, payload: error, error: 'manure' });
    });
};
