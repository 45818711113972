import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import NavigationBar from 'mui-mobile-components/layouts/NavigationBar';
import Loader from 'components/Loader';
import LoaderPage from 'components/LoaderPage';
import Header from 'containers/header';
import CommonError from 'components/CommonErrorMessage';
import { Grid, Box, CssBaseline } from '@mui/material';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import Paper from '@mui/material/Paper';
import * as appLayoutStyle from 'mui-mobile-components/layouts/AppLayoutStyle';
import * as locationActions from 'actions/Geolocation';

import { fetchRolePermistionList } from 'actions/RolesDetails';
import fetchMasterData from 'actions/MasterData';
import { fetchFarmList } from 'actions/Farms';
import { dataValidationPermission } from 'actions/GpsData';
import { fetchSupportAdminPermission } from 'actions/SupportAdminRole';
import { createSvgIcon } from '@mui/material/utils';
import { ENTER_STATS, KART_CLICKED_MAP, MOBILE } from 'constants/MixPanel';
import mixpanel from 'mixpanel-browser';
import MapIcon from '@mui/icons-material/Map';
import MapOutlinedIcon from '@mui/icons-material/MapOutlined';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';

const Icon1 = createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path d="M4 21V9L12 3L20 9V21H14V14H10V21H4Z" fill="none" stroke="#3F4949" stroke-width="2"/>
  </svg>,
  'icon1'
);

const Icon1Filled = createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path d="M4 21V9L12 3L20 9V21H14V14H10V21H4Z" fill="#041F21"/>
  </svg>,
  'icon1filled'
);

class AppLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showTermsAndCondition: false,
      fetchFarmListState: false
    };
    this.openTermsAndCondition = this.openTermsAndCondition.bind(this);
    this.closeTermsAndCondition = this.closeTermsAndCondition.bind(this);
  }
  componentDidMount = () => {
    const { getRolesDetails, userDetails, dataPermission, getMasterData, supportAdminPermission, getGeoLocation } = this.props;
    const { idToken } = userDetails.data;
    getGeoLocation();
    getRolesDetails(idToken);
    dataPermission(idToken);
    supportAdminPermission();
    getMasterData();
  };

  componentDidUpdate = () => {
    const { getFarmList, userDetails, hasSupportAdminRole, farmList } = this.props;
    const { lastaccessedFarm } = userDetails.data;
    const { fetchFarmListState } = this.state;
    let version = "v1";
    if(!fetchFarmListState && hasSupportAdminRole.data !== null) {
      if(hasSupportAdminRole.data) {
        version = "v2";
      }
      if(!farmList.data) {
        if(localStorage.getItem("selectedFarmId") === null &&  localStorage.getItem("selectedFarmName") === null) {
          getFarmList(lastaccessedFarm,version);
        } else {
          getFarmList(localStorage.getItem("selectedFarmId"),version);
        }
        this.setState({
          fetchFarmListState: true
        });
      }
    }
  }

  openTermsAndCondition = () => {
    this.setState ({
      showTermsAndCondition: true
    });
  }

  closeTermsAndCondition = () => {
    this.setState ({
      showTermsAndCondition: false
    });
  }

  render() {
    const {
      props: { children, farmList, rolesDetails, userDetails, location, masterData, intl }
    } = this;
    const error = farmList.hasError || rolesDetails.hasError || masterData.hasError;
    const profileDisplayCheck = location && location.pathname.includes('profilesettings');
    return (
      <div style={{ display: 'flex', height: '100vh', backgroundColor: '#f0f0f0' }}>
        <Box><NavigationBar /></Box>
        {userDetails.isLogoutLoading ? <LoaderPage /> : null}
        <Grid container sx={{ flexGrow: 1 }}>
          <Grid 
            item xs={12} sm={12} md={12} sx={{ 
              backgroundColor: (location.pathname).includes('task-batch') ? '#FFFFFF' : '#F2F4F4',
              height: '100vh',
              overflowY: 'auto'
            }}
          >
            <Box sx={{ backgroundColor: '#FFFFFF', minHeight: '98vh' }}>
              <div className="full-width app-content-wrapper">
                {(farmList.data && rolesDetails.data && masterData.data) || profileDisplayCheck
                  ? children
                  : null}
                {!farmList.data && !rolesDetails.data && !masterData.data && !error && (
                  <Loader styleName="layout-loader" />
                )}
                {error && !profileDisplayCheck && (
                  <div className={`full-width ${'main-content-mobile'}`}>
                    <Header
                      bcLink={{ to: '/tasks', text: intl.formatMessage({ id: 'TASKS' }) }}
                      bcTitle={intl.formatMessage({ id: 'HEADER_TASKS' })}
                    />
                    <CommonError />
                  </div>
                )}
              </div>
              <Box>
                <Paper
                  sx={{
                    position: 'fixed',
                    bottom: 0,
                    left: 0,
                    right: 0,
                    backgroundColor: '#ffffff',
                    zIndex: 5,
                    boxShadow: 3
                  }}
                >
                  <BottomNavigation
                    showLabels
                    sx={{
                      display: 'flex',
                      padding: '0px 8px 20px 8px',
                      alignItems: 'center',
                      height: '90px',
                    }}
                  >
                    <Link 
                      to="/stats" 
                      onClick={() => {mixpanel.identify(userDetails.data.id); mixpanel.track(ENTER_STATS, {'Platform': MOBILE});}}
                      style={{ textDecoration: 'none', flexGrow: 1, textAlign: 'center' }}
                    >
                      <BottomNavigationAction
                        showLabel
                        style={(location.pathname).includes('stats') ? appLayoutStyle.mobileHeadingBold : appLayoutStyle.mobileHeading}
                        label={intl.formatMessage({ id: 'MOBILE_HEADING_1' })}
                        icon={(location.pathname).includes('stats') ? (
                          <span style={appLayoutStyle.iconBackground}><Icon1Filled /></span>
                        ) : (
                          <span style={appLayoutStyle.iconPlainBackground}><Icon1 /></span>
                        )}
                      />
                    </Link>

                    <Link 
                      to="/kart/fieldmap" 
                      onClick={() => {mixpanel.identify(userDetails.data.id); mixpanel.track(KART_CLICKED_MAP);}}
                      style={{ textDecoration: 'none', flexGrow: 1, textAlign: 'center' }}
                    >
                      <BottomNavigationAction
                        showLabel
                        style={(location.pathname).includes('fieldmap') ? appLayoutStyle.mobileHeadingBold : appLayoutStyle.mobileHeading}
                        label={intl.formatMessage({ id: 'MOBILE_HEADING_2' })}
                        icon={(location.pathname).includes('fieldmap') ? (
                          <span style={appLayoutStyle.iconBackground}><MapIcon sx={{ color: '#041F21' }} /></span>
                        ) : (
                          <span style={appLayoutStyle.iconPlainBackground}><MapOutlinedIcon sx={{ color: '#3F4949' }} /></span>
                        )}
                      />
                    </Link>
                    <Link 
                      to="#"
                      onClick={() => {
                        const subject = intl.formatMessage({ id: 'MAIL_SUBJECT' }); // Add your subject translation key
                        const mailtoUrl = `mailto:hei@eana.no?subject=${encodeURIComponent(subject)}`;
                        window.location.href = mailtoUrl;
                      }}
                      style={{ textDecoration: 'none', flexGrow: 1, textAlign: 'center' }}
                    >
                      <BottomNavigationAction
                        showLabel
                        style={appLayoutStyle.mobileHeading}
                        label={intl.formatMessage({ id: 'MOBILE_HEADING_3' })}
                        icon={<span style={appLayoutStyle.iconPlainBackground}><ChatBubbleOutlineIcon /></span>}
                      />
                    </Link>
                  </BottomNavigation>
                </Paper>
              </Box>

            </Box>
          </Grid>
        </Grid>
        <CssBaseline />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  userDetails: state.userDetails,
  rolesDetails: state.RolesDetails,
  geoLocDetails: state.geoLocDetails,
  farmList: state.farmList,
  masterData: state.masterData,
  hasSupportAdminRole: state.SupportAdminRole
});

const mapDispatchToProps = dispatch => ({
  getFarmList: (lastaccessedFarm, version) => dispatch(fetchFarmList(lastaccessedFarm, version)),
  dataPermission: token => dispatch(dataValidationPermission(token)),
  getRolesDetails: token => dispatch(fetchRolePermistionList(token)),
  getMasterData: () => dispatch(fetchMasterData()),
  supportAdminPermission: () => dispatch(fetchSupportAdminPermission()),
  getGeoLocation: () => dispatch(locationActions.getGeoLocation())
});

export default injectIntl(withRouter(connect(mapStateToProps, mapDispatchToProps)(AppLayout)));
