import React, { Component } from 'react';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import * as userActions from 'actions/Users';
import Template from 'containers/tasks/batch/templates/Template';
import TemplateFertilization from './templates/TemplateFertilization';
import TemplateManure from './templates/TemplateManure';
import TemplateLiming from './templates/TemplateLiming';
import TemplateFoiler from './templates/TemplateFoiler';
import TemplatePesticide from './templates/TemplatePesticide';
import { getUsers } from 'actions/Users';
import * as ensilationTypeAction from 'actions/EnsilationList';

class TaskBatchList extends Component {
  componentDidMount() {
    const { farmUsers, userDetails, activeFarm, getUsers, assignees, fetchAssignee, fetchEnsilationType } = this.props;
    fetchEnsilationType();
    if (!farmUsers && activeFarm) {
      getUsers(activeFarm.farm.id, userDetails.idToken);
    }
    if (!assignees.users) {
      fetchAssignee(activeFarm.farm.id, userDetails.idToken);
    }
  }

  fieldOptions = () => {
    const { fieldDetails } = this.props;
    let result = [];
    if (fieldDetails && fieldDetails.data) {
      fieldDetails.data.map(data => result.push({ value: data.fieldId, label: data.displayName }));
    }
    return result;
  };

  vehicleOptions = () => {
    const { taskDetails } = this.props;
    let result = [];
    result.push({ value: '', label: '' });
    if (taskDetails && taskDetails.allVehicles && taskDetails.allVehicles.length > 0) {
      taskDetails.allVehicles.map(data => result.push({
        value: data.asset.assetId,
        label: `${data.asset.brand} ${data.asset.model}`
      }));
    }
    return result;
  };

  equipmentOptions = () => {
    const { taskDetails } = this.props;
    let result = [];
    result.push({ value: '', label: '' });
    if (taskDetails && taskDetails.allEquipments && taskDetails.allEquipments.length > 0) {
      taskDetails.allEquipments.map(data => result.push({
        value: data.asset.assetId,
        label: `${data.asset.brand} ${data.asset.model}`
      }));
    }
    return result;
  };

  weatherOptions = () => {
    let result = [];
    result.push({ value: '', label: '' });
    result.push({ value: "sunny", label: <FormattedMessage id="SUNNY" /> });
    result.push({ value: "cloudy", label: <FormattedMessage id="CLOUDY" /> });
    result.push({ value: "sun_and_rain", label: <FormattedMessage id="SUNANDRAIN" /> });
    result.push({ value: "overcast", label: <FormattedMessage id="OVERCAST" /> });
    result.push({ value: "rain", label: <FormattedMessage id="RAIN" /> });
    return result;
  };

  fetchEnsilationTypeOptions = (ensilationList) => {
    if (ensilationList && Array.isArray(ensilationList.ensilationTypeData)) {
      return [
        { label: "", value: "" }, // Empty option
        ...ensilationList.ensilationTypeData.map(option => ({
          label: option,
          value: option
        }))
      ];
    } else {
      return [{ label: "", value: "" }]; // Return only empty option if no valid data
    }
  };

  fieldDetailsOptionsArray = () => {
    let optionsArray = [];
    optionsArray.push(this.fieldOptions());
    optionsArray.push(this.vehicleOptions());
    optionsArray.push(this.equipmentOptions());
    optionsArray.push(this.weatherOptions());
    optionsArray.push(this.assigneeOptions());
    optionsArray.push(this.fetchEnsilationTypeOptions(this.props.ensilationList));
    return optionsArray;
  }

  assigneeOptions = () => {
    const { farmUsers } = this.props;
    if (farmUsers) {
      let returnValue = [];
      farmUsers.forEach(data => {
        returnValue.push({
          value: data.user.id,
          label: [
            <span className="user-name">
              {data.user.firstName} {data.user.lastName}
            </span>
          ]

        });
      });
      return returnValue.map(data => ({
        value: data.value,
        label: data.label
      }));
    }
    return [];
  };

  render() {
    const { actId, intl, activitiesCommon, taskList, pesticideSize, locations, seasons, selectedActivity,
      changeSetSuccessMessage, setSuccessMessage, manureStorage } = this.props;
    this.assigneeOptions();
    if (actId === "act7") {
      return (
        <TemplateFertilization
          intl={intl}
          actId={actId}
          activitiesCommon={activitiesCommon}
          taskList={taskList}
          fieldDetailsOptionsArray={this.fieldDetailsOptionsArray()}
          locations={locations}
          seasons={seasons}
          selectedActivity={selectedActivity}
          changeSetSuccessMessage={changeSetSuccessMessage}
          setSuccessMessage={setSuccessMessage}
        />
      );
    } if (actId === "act30") {
      return (
        <TemplateManure
          intl={intl}
          actId={actId}
          activitiesCommon={activitiesCommon}
          taskList={taskList}
          fieldDetailsOptionsArray={this.fieldDetailsOptionsArray()}
          locations={locations}
          seasons={seasons}
          selectedActivity={selectedActivity}
          changeSetSuccessMessage={changeSetSuccessMessage}
          setSuccessMessage={setSuccessMessage}
          manureStorage={manureStorage}
        />
      );
    } if (actId === "act31") {
      return (
        <TemplateLiming
          intl={intl}
          actId={actId}
          activitiesCommon={activitiesCommon}
          taskList={taskList}
          fieldDetailsOptionsArray={this.fieldDetailsOptionsArray()}
          locations={locations}
          seasons={seasons}
          selectedActivity={selectedActivity}
          changeSetSuccessMessage={changeSetSuccessMessage}
          setSuccessMessage={setSuccessMessage}
        />
      );
    } if (actId === "act32") {
      return (
        <TemplateFoiler
          intl={intl}
          actId={actId}
          activitiesCommon={activitiesCommon}
          taskList={taskList}
          fieldDetailsOptionsArray={this.fieldDetailsOptionsArray()}
          locations={locations}
          seasons={seasons}
          selectedActivity={selectedActivity}
          changeSetSuccessMessage={changeSetSuccessMessage}
          setSuccessMessage={setSuccessMessage}
        />
      );
    } else if (actId === "act19") {
      return (
        <TemplatePesticide
          intl={intl}
          fieldOptions={this.fieldOptions()}
          vehicleOptions={this.vehicleOptions()}
          equipmentOptions={this.equipmentOptions()}
          weatherOptions={this.weatherOptions()}
          assigneeOptions={this.assigneeOptions()}
          activitiesCommon={activitiesCommon}
          taskList={taskList}
          locations={locations}
          pesticideSize={pesticideSize}
          selectedActivity={selectedActivity}
          changeSetSuccessMessage={changeSetSuccessMessage}
          setSuccessMessage={setSuccessMessage}
        />
      );
    } else {
      return (
        <Template
          intl={intl}
          actId={actId}
          activitiesCommon={activitiesCommon}
          taskList={taskList}
          fieldDetailsOptionsArray={this.fieldDetailsOptionsArray()}
          locations={locations}
          selectedActivity={selectedActivity}
          changeSetSuccessMessage={changeSetSuccessMessage}
          setSuccessMessage={setSuccessMessage}
        />
      );
    }
  }
}

const mapStateToProps = state => ({
  userDetails: state.userDetails.data,
  farmUsers: state.farmUserDetails.userList.users,
  profilePictures: state.profileImage.userImage,
  assignees: state.farmUserDetails.userList,
  activeFarm: state.farmList.activeFarm,
  editTaskDetails: state.getTask,
  ensilationList: state.ensilationList
});

function mapDispatchToProps(dispatch) {
  return {
    getUsers: (farmId, idToken) => dispatch(userActions.getUsers(farmId, idToken)),
    fetchAssignee: (farmId, token) => dispatch(getUsers(farmId, token)),
    fetchEnsilationType: () => dispatch(ensilationTypeAction.fetchEnsilationType())
  };
}
export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(TaskBatchList));
